import React from 'react'
import 'animate.css';

export default function ModalOne(props) {
  return (
    <div className={`fixed inset-0 h-screen ${props.open ? '' : 'pointer-events-none'}`}>
        <div className={`fixed inset-0 bg-black  ${props.open ? 'opacity-50' : 'pointer-events-none opacity-0'}`} onClick={()=>{props.onClose()}}></div>
        <div className={`fixed right-0 h-full overflow-auto bg-white shadow-lg w-full max-w-screen-sm p-4 transition-opacity duration-300 ease-in-out  ${props.open ? 'opacity-100 animate__animated animate__fadeInRight' : props.closeClass }`}>
            <div className='flex items-center top-0 sticky bg-white border-b'>
                <div className='text-red-500'>
                    <i className="bi bi-x-circle text-2xl hover:text-3xl duration-300 cursor-pointer" onClick={()=>{props.onClose()}}></i>
                </div>
                <div className={`w-full text-center mb-2 ${props.color ? 'bg-'+props.color+'-100' : 'bg-green-100'} text-${props.color}-500 mx-2 rounded-xl py-2`}>{props.title}</div>
            </div>
            {props.children}
        </div>
    </div>
  )
}
