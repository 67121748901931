import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export default function BreadCrumb(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate()
  return (
    <div className='h-14 bg-white rounded-xl px-3 py-1 flex items-center'>
        <div className='w-full text-left right-2'>
            <button className='bg-green-500/[.2] text-green-500 rounded-full px-1 hover:bg-orange-500/[.5] hover:text-orange-500 duration-300' onClick={()=>{localStorage.clear(); navigate('/'); dispatch({type: 'other'})}}>
                <i className="bi bi-power text-3xl"></i>
            </button>
        </div>
        {props.children}
    </div>
  )
}
