import React from "react";
import {Routes, Route} from 'react-router-dom'
import PrivateRouter from "../components/private-routes/PrivateRouter";
// page
import WithSideBar from "../components/sidebar/WithSideBar";
import Login from "../pages/auth/Login";
import Projects from "../pages/Projects";
import Home from "../pages/Home";
import Transactions from "../pages/Transactions";
import Accounts from "../pages/Accounts";
import Invoices from "../pages/Invoices";
import Clients from "../pages/Clients";
import Roles from "../pages/admin/Roles";
import Permissions from "../pages/admin/Permissions";
import Users from "../pages/admin/Users";
import InvoicesList from "../pages/InvoicesList";
export default function Base() {
  return (
    <Routes>
        <Route path="/" element={<Login/>}></Route>
        <Route path="/roles" element={<WithSideBar><PrivateRouter component={Roles} role={['administrateur', 'développeur']}/></WithSideBar>}></Route>
        <Route path="/permissions" element={<WithSideBar><PrivateRouter component={Permissions} role={['administrateur', 'développeur']}/></WithSideBar>}></Route>
        <Route path="/users" element={<WithSideBar><PrivateRouter component={Users} role={['administrateur', 'développeur']}/></WithSideBar>}></Route>
        <Route path="/dashboard" element={<WithSideBar><PrivateRouter component={Home} role={['administrateur', 'développeur']}/></WithSideBar>}></Route>
        <Route path="/transactions" element={<WithSideBar><PrivateRouter component={Transactions} role={['administrateur', 'développeur', 'commercial']}/></WithSideBar>}></Route>
        <Route path="/accounts" element={<WithSideBar><PrivateRouter component={Accounts} role={['administrateur', 'développeur']}/></WithSideBar>}></Route>
        <Route path="/invoices" element={<WithSideBar><PrivateRouter component={Invoices} role={['administrateur', 'développeur', 'commercial']}/></WithSideBar>}></Route>
        <Route path="/invoices/list" element={<WithSideBar><PrivateRouter component={InvoicesList} role={['administrateur', 'commercial']}/></WithSideBar>}></Route>
        <Route path="/clients/:id" element={<WithSideBar><PrivateRouter component={Clients} role={['administrateur', 'développeur', 'commercial']}/></WithSideBar>}></Route>
        <Route path='/projects' element={<WithSideBar><PrivateRouter component={Projects} role={['administrateur', 'développeur', 'commercial']}/></WithSideBar>}></Route>
    </Routes>
  )
}
