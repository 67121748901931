import React, {useState, useEffect} from 'react'
import BreadCrumb from '../../components/breadcrumb/BreadCrumb'
import CreateButton from '../../components/buttons/CreateButton'
import ModalOne from '../../components/modals/ModalOne'
import axios from 'axios'
import URLS from '../../components/API'
import SaveButton from '../../components/buttons/SaveButton'
import ErrorBadge from '../../components/badges/ErrorBadge'
import Notify from '../../components/toast/ToastMessage';
import { Toaster } from 'react-hot-toast'
import moment from 'moment/moment'
import PopUpOne from '../../components/popup/PopUpOne'
import ConfirmModal from '../../components/modals/ConfirmModal'
import { useNavigate } from "react-router-dom";


export default function Roles() {
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(0);

    const [permissions, setPermissions] = useState();
    const [errorRolePermissions, setErrorRolePermissions] =  useState(false);
    const [roles, setRoles] = useState();
    const [roleValue, setRoleValue] = useState('');
    const [rolePermissions, setRolePermissions] = useState([]);
    
    const [errorRoleValue, setErrorRoleValue] =  useState(false);
    const [saveLoader, setSaveLoader] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [closeClass, setCloseClass] = useState('opacity-0');
    const [popUpCloseClass, setPopUpCloseClass] = useState('opacity-0');
    const [currentRoleToConsulte, setCurrentRoleToConsulte] = useState([]);
    const [openPermissionPopUp, setOpenPermissionPopUp] = useState(false);

    //delete
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [actionId, setActionId] = useState(false);
    const [confirmCloseClass, setConfirmCloseClass] = useState('opacity-0');

    //update
    const [errorUpdateRoleValue, setErrorUpdateRoleValue] =  useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateCloseClass, setUpdateCloseClass] = useState('opacity-0');
    const [errorUpdateRolePermissions, setErrorUpdateRolePermissions] =  useState(false);
    const [roleUpdatePermissions, setUpdateRolePermissions] = useState([]);
    const [updateRoleValue, setUpdateRoleValue] = useState('');
    const [updateLoader, setUpdateLoader] = useState(false);
    const [updateId, setUpdateId] = useState(false);
    

    const openUpdateModal = (index)=>{
        setUpdateModalOpen(true);
        setUpdateRoleValue(roles[index].label)
        let middleman = [];
        roles[index].permissions.forEach(element => {
            middleman.push(element.id);
        });
        setUpdateRolePermissions(middleman);
        setUpdateId(roles[index].id)
    }
    const updateModalClose = ()=>{
        setUpdateModalOpen(false);
        setUpdateCloseClass('animate__animated animate__fadeOutRight');
        setErrorUpdateRoleValue(false);
        setErrorUpdateRolePermissions(false)
    }
    const saveUpdateRoleValue = (e)=>{
        setUpdateRoleValue(e.target.value);
    }
    const addToUpdateRolePermissions = (id)=>{
        let middleman = roleUpdatePermissions;
        if(!middleman.includes(id)){
            middleman.push(id)
        }else{
            for (const key in middleman) {
                if (middleman[key] === id) {
                    middleman.splice(key, 1);
                }
            }
        }
        setUpdateRolePermissions(middleman);
        setErrorUpdateRolePermissions(false);
    }
    


    const saveRoleValue = (e)=>{
        setRoleValue(e.target.value);
        setErrorRoleValue(false);
    }

    const openConfirmModal = (id)=>{
        setDeleteModalOpen(true);
        setActionId(id);
    }
    const closeConfirmModal = ()=>{
        setDeleteModalOpen(false);
        setConfirmCloseClass('animate__animated animate__backOutDown');
    }
   
    const openPopUp = (index)=>{
        setOpenPermissionPopUp(true);
        setCurrentRoleToConsulte(roles[index]);
    }
    const closePopUp = ()=>{
        setOpenPermissionPopUp(false);
        setPopUpCloseClass('animate__animated animate__flipOutX');
    }

    const openModal = ()=>{
        setModalOpen(true);
    }
    const modalClose = ()=>{
        setModalOpen(false);
        setCloseClass('animate__animated animate__fadeOutRight');
    }

    useEffect(()=>{
        axios.get(URLS.roles, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
        .then((response)=>{
            setRoles(response.data.roles);
        })

        axios.get(URLS.permissions, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
        .then((response)=>{
            setPermissions(response.data.permissions);
        })
    }, [refresh])

    const addToRolePermissions = (id)=>{
        let middleman = rolePermissions;
        if(!middleman.includes(id)){
            middleman.push(id)
        }else{
            for (const key in middleman) {
                if (middleman[key] === id) {
                    middleman.splice(key, 1);
                }
            }
        }
        setRolePermissions(middleman);
        setErrorRolePermissions(false);
    }

    const save = ()=>{
        if(!roleValue){
            setErrorRoleValue(true);
        }
        if(rolePermissions.length===0){
            setErrorRolePermissions(true);
        }

        if(!errorRolePermissions || !errorRoleValue){
            setSaveLoader(true);
            const data = {
                'label': roleValue,
                'permissions': rolePermissions
            }
            axios.post(URLS.createRole, data, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
            .then((response)=>{
                if(response.data.success){
                    Notify('success', "Création d'un nouveau rôle", `${roleValue} créé avec succès !`);
                    setRefresh(refresh+1);
                    setRoleValue('');
                    setRolePermissions([]);
                    modalClose();
                }else if(response.data.error){
                    Notify('warning', "Création d'un nouveau rôle", response.data.error);
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
            })
            setSaveLoader(false);
        }
    }

    const update = ()=>{
        if(!updateRoleValue){
            setErrorUpdateRoleValue(true);
        }
        if(roleUpdatePermissions.length===0){
            setErrorUpdateRolePermissions(true);
        }

        if(!errorRolePermissions || !errorRoleValue){
            setUpdateLoader(true);
            const data = {
                'id': updateId,
                'label': updateRoleValue,
                'permissions': roleUpdatePermissions
            }
            axios.post(URLS.updateRole, data, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
            .then((response)=>{
                if(response.data.success){
                    Notify('success', "Modification d'un rôle", `${updateRoleValue} modifié avec succès !`);
                    updateModalClose();
                }else{
                    Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
            })
            setUpdateLoader(false);
        }
    }

    const deleteRole = (id)=>{
        axios.post(URLS.deleteRole, {'id': id}, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
        .then((response)=>{
            if(response.data.success){
                Notify('success', 'Suppression de Rôle', 'Rôle supprimé avec succès !');
                setRefresh(refresh+1);
            }else{
                Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
            }
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
        })
    }
    return (
        <>
            <BreadCrumb>
                <div className='flex items-center h-full'>
                    <div className='pr-2 border-r border-r-1 border-gray-500'>
                        <div className='text-md font-bold bg-blue-500/[.7] text-white p-1 rounded-md px-3'>
                            Gestion des Rôle
                        </div>
                    </div>
                </div>
            </BreadCrumb>
            <Toaster position='top-right'/>

            <PopUpOne color="blue" open={openPermissionPopUp} onClose={closePopUp} title={`Les permissions de ${currentRoleToConsulte.label}`} closeClass={popUpCloseClass}>
                <div className="flex-wrap space-x-2">
                {currentRoleToConsulte.permissions &&
                    currentRoleToConsulte.permissions.map((value, index)=>{
                        return (
                            <span key={index} className="text-sm inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-red-500/[.7] text-white rounded">{value.label}</span>
                        )
                    })
                }
                </div>
            </PopUpOne>

            <div className='mt-4 text-right'>
                <CreateButton text={"Nouveau Rôle"} onClick={()=>{openModal()}} />
            </div>

            <ModalOne open={modalOpen} onClose={modalClose} closeClass={closeClass} title="Création d'un nouveau rôle" color="green">
                <div className='border rounded-lg h-5/6 mt-10 border-green-300 p-10'>
                    <div className=''>
                        {errorRoleValue && (
                            <div className='mt-10'>
                                <ErrorBadge message={"Vous devez saisir le nom de la permission"}/>
                            </div>
                        )}
                        {errorRolePermissions && (
                            <div className='mt-10'>
                                <ErrorBadge message={"Vous devez sélectionner une ou plusieurs permissions"}/>
                            </div>
                        )}
                        <div className='text-center font-bold text-2xl'>Nom du rôle <i className="bi bi-key-fill text-green-500"></i></div>
                        <div className='mt-2 text-center'>
                            <input type="text" className='border w-3/4 focus:border-green-300 focus:w-full duration-300 h-10 rounded focus:outline-none border-gray-300 px-4 text-center' value={roleValue} onInput={(e)=>{saveRoleValue(e)}} />
                        </div>

                        <div className='mt-14 text-lg font-bold text-blue-500'>Les permissions associées</div>
                        <div className='mt-4 w-full bg-blue-500/[.3] grid grid-cols-3 p-4 rounded-xl'>
                            {
                               permissions && permissions.map((value, index)=>{
                                    return(
                                        <div key={index} className="my-2 text-blue-700">
                                            <input type="checkbox" id={index} value={value.id} className='focus:outline-none rounded-full accent-red-500 text-white ring-none cursor-pointer' onChange={()=>{addToRolePermissions(value.id)}}/> <label htmlFor="">{value.label}</label>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className='h-full text-center mt-10'>
                            <SaveButton text="Enregistrer" className="bg-green-500 hover:bg-green-600 duration-300 p-2 px-4 text-white font-bold text-lg hover:text-xl rounded-xl" save={save} loader={saveLoader}/>
                        </div>
                    </div>
                </div>
            </ModalOne>

            <ConfirmModal open={deleteModalOpen} onClose={closeConfirmModal} closeClass={confirmCloseClass} action={deleteRole} id={actionId}/>
            
            {/* update */}
            <ModalOne open={updateModalOpen} onClose={updateModalClose} closeClass={updateCloseClass} title="Modification d'un rôle" color="orange">
                <div className='border rounded-lg h-5/6 mt-10 border-orange-300 p-10'>
                    <div className=''>
                        {errorUpdateRoleValue && (
                            <div className='mt-10'>
                                <ErrorBadge message={"Vous devez saisir le nom de la permission"}/>
                            </div>
                        )}
                        {errorUpdateRolePermissions && (
                            <div className='mt-10'>
                                <ErrorBadge message={"Vous devez sélectionner une ou plusieurs permissions"}/>
                            </div>
                        )}
                        <div className='text-center font-bold text-2xl'>Nom du rôle <i className="bi bi-key-fill text-orange-500"></i></div>
                        <div className='mt-2 text-center'>
                            <input type="text" className='border w-3/4 focus:border-orange-300 focus:w-full duration-300 h-10 rounded focus:outline-none border-gray-300 px-4 text-center' value={updateRoleValue} onInput={(e)=>{saveUpdateRoleValue(e)}} />
                        </div>

                        <div className='mt-14 text-lg font-bold text-blue-500'>Les permissions associées</div>
                        <div className='mt-4 w-full bg-blue-500/[.3] grid grid-cols-3 p-4 rounded-xl'>
                            {
                               permissions && permissions.map((value, index)=>{
                                    return(
                                        <div key={index} className="my-2 text-blue-700">
                                            <input type="checkbox" id={index} value={value.id} {...(roleUpdatePermissions.includes(value.id) && { defaultChecked: true })} onClick={()=>{addToUpdateRolePermissions(value.id)}}/> <label htmlFor={index}>{value.label}</label>
                                        </div>
                                        
                                    )
                                })
                            }
                        </div>

                        <div className='h-full text-center mt-10'>
                            <SaveButton text="Enregistrer" className="bg-orange-500 hover:bg-orange-600 duration-300 p-2 px-4 text-white font-bold text-lg hover:text-xl rounded-xl" save={update} loader={updateLoader}/>
                        </div>
                    </div>
                </div>
            </ModalOne>
            
            <div className='mt-4'>
                <div className='bg-white w-full p-4 rounded-xl'>
                    <table className='w-full'>
                        <thead className='text-white rounded-xl'>
                            <tr className='py-10 rounded-xl'>
                                <th scope='col' className=' bg-blue-500/[.7] py-2 rounded-l-xl'>Libellé</th>
                                <th scope='col' className='bg-blue-500/[.7]'>Date de création</th>
                                <th scope='col' className='bg-blue-500/[.7]'>Permissions</th>
                                <th scope='col' className='bg-blue-500/[.7] py-2 rounded-r-xl'>Actions</th>
                            </tr>
                        </thead>
                        <tbody className=''>
                            {
                                roles && roles.map((value, index)=>{
                                    return (
                                        <tr key={index} className={`${index%2!==0 && 'bg-gray-100'}`}>
                                            <td className='text-center my-1 py-2 rounded-l-2xl'>{value.label}</td>
                                            <td className='text-center my-1 py-2'>{moment(value.created_at).format('DD MMM YYYY à hh:mm')}</td>
                                            <td className='text-center my-1 py-2'>
                                                <button className='rounded-md bg-violet-500/[.7] hover:bg-violet-700 duration-300 text-white px-2 py-1' onClick={()=>{openPopUp(index)}}>Consulter</button>
                                            </td>
                                            <td className='flex justify-center gap-6 my-1 py-2'>
                                                <i className="bi bi-pen cursor-pointer hover:bg-green-200 px-2 py-1 rounded-full text-green-600 duration-300" onClick={()=>{openUpdateModal(index)}}></i>
                                                <i className="bi bi-trash cursor-pointer hover:bg-red-200 px-2 py-1 rounded-full text-red-600 duration-300" onClick={()=>{openConfirmModal(value.id)}}></i>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
