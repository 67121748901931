const dev  = [
    {
        'name': 'Roles',
        'path': '/roles',
        'icon': 'bi bi-kanban',
        'active': 'role',
    },
    {
        'name': 'Permissions',
        'path': '/permissions',
        'icon': 'bi bi-shield-lock-fill',
        'active': 'permissions',
    },
    {
        'name': 'Dashboard',
        'path': '/dashboard',
        'icon': 'bi bi-speedometer2',
        'active': 'dashboard',
    },
    {
        'name': 'Utilisateurs',
        'path': '/users',
        'icon': 'bi bi-people',
        'active': 'users',
    },
    {
        'name': 'Projets',
        'path': '/projects',
        'icon': 'bi bi-archive',
        'active': 'project',
    },
    {
        'name': 'Transactions',
        'path': '/transactions',
        'icon': 'bi bi-cash-coin',
        'active': 'transaction',
    },
    {
        'name': 'Comptes',
        'path': '/accounts',
        'icon': 'bi bi-safe2',
        'active': 'accounts',
    },
    {
        'name': 'Facture',
        'path': '/invoices',
        'icon': 'bi bi-receipt',
        'active': 'invoices',
        'children': [
            {
            'name': 'Liste des facture',
            'path': '/invoices/list',
            'icon': 'bi bi-receipt',
            'active': 'list',
            },
        ]
    },
];
const admin = [
    {
        'name': 'Dashboard',
        'path': '/dashboard',
        'icon': 'bi bi-speedometer2',
        'active': 'dashboard',
    },
    {
        'name': 'Utilisateurs',
        'path': '/users',
        'icon': 'bi bi-people',
        'active': 'users',
    },
    {
        'name': 'Projets',
        'path': '/projects',
        'icon': 'bi bi-archive',
        'active': 'project',
    },
    {
        'name': 'Transactions',
        'path': '/transactions',
        'icon': 'bi bi-cash-coin',
        'active': 'transaction',
    },
    {
        'name': 'Comptes',
        'path': '/accounts',
        'icon': 'bi bi-safe2',
        'active': 'accounts',
    },
    {
        'name': 'Facture',
        'path': '/invoices',
        'icon': 'bi bi-receipt',
        'active': 'invoices',
        'children': [
            {
            'name': 'Liste des facture',
            'path': '/invoices/list',
            'icon': 'bi bi-receipt',
            'active': 'list',
            },
        ]
    },
];
const other = [
    {
        'name': 'Dashboard',
        'path': '/dashboard',
        'icon': 'bi bi-speedometer2',
        'active': 'dashboard',
    },
    {
        'name': 'Projets',
        'path': '/projects',
        'icon': 'bi bi-archive',
        'active': 'project',
    },
    {
        'name': 'Transactions',
        'path': '/transactions',
        'icon': 'bi bi-cash-coin',
        'active': 'transaction',
    },
    {
        'name': 'Comptes',
        'path': '/accounts',
        'icon': 'bi bi-safe2',
        'active': 'accounts',
    },
    {
        'name': 'Facture',
        'path': '/invoices',
        'icon': 'bi bi-receipt',
        'active': 'invoices',
        'children': [
            {
            'name': 'Liste des facture',
            'path': '/invoices/list',
            'icon': 'bi bi-receipt',
            'active': 'list',
            },
        ]
    },
];

const INITIAL_STATE = localStorage.getItem('role') === 'développeur' ? dev : localStorage.getItem('role') === 'administrateur' ? admin : other;

function MenuReducer(state = INITIAL_STATE, action){
    state = INITIAL_STATE
    if(localStorage.getItem('role') ===  'développeur'){

        return state = [
                {
                    'name': 'Roles',
                    'path': '/roles',
                    'icon': 'bi bi-kanban',
                    'active': 'role',
                },
                {
                    'name': 'Permissions',
                    'path': '/permissions',
                    'icon': 'bi bi-shield-lock-fill',
                    'active': 'permissions',
                },
                {
                    'name': 'Dashboard',
                    'path': '/dashboard',
                    'icon': 'bi bi-speedometer2',
                    'active': 'dashboard',
                },
                {
                    'name': 'Utilisateurs',
                    'path': '/users',
                    'icon': 'bi bi-people',
                    'active': 'users',
                },
                {
                    'name': 'Projets',
                    'path': '/projects',
                    'icon': 'bi bi-archive',
                    'active': 'project',
                },
                {
                    'name': 'Transactions',
                    'path': '/transactions',
                    'icon': 'bi bi-cash-coin',
                    'active': 'transaction',
                },
                {
                    'name': 'Comptes',
                    'path': '/accounts',
                    'icon': 'bi bi-safe2',
                    'active': 'accounts',
                },
                {
                    'name': 'Facture',
                    'path': '/invoices',
                    'icon': 'bi bi-receipt',
                    'active': 'invoices',
                    'children': [
                        {
                        'name': 'Liste des facture',
                        'path': '/invoices/list',
                        'icon': 'bi bi-receipt',
                        'active': 'list',
                        },
                    ]
                },
            ]
    }else if( localStorage.getItem('role') ===  'administrateur'){
            return  state =  [
                    {
                        'name': 'Dashboard',
                        'path': '/dashboard',
                        'icon': 'bi bi-speedometer2',
                        'active': 'dashboard',
                    },
                    {
                        'name': 'Utilisateurs',
                        'path': '/users',
                        'icon': 'bi bi-people',
                        'active': 'users',
                    },
                    {
                        'name': 'Projets',
                        'path': '/projects',
                        'icon': 'bi bi-archive',
                        'active': 'project',
                    },
                    {
                        'name': 'Transactions',
                        'path': '/transactions',
                        'icon': 'bi bi-cash-coin',
                        'active': 'transaction',
                    },
                    {
                        'name': 'Comptes',
                        'path': '/accounts',
                        'icon': 'bi bi-safe2',
                        'active': 'accounts',
                    },
                    {
                        'name': 'Facture',
                        'path': '/invoices',
                        'icon': 'bi bi-receipt',
                        'active': 'invoices',
                        'children': [
                            {
                            'name': 'Liste des facture',
                            'path': '/invoices/list',
                            'icon': 'bi bi-receipt',
                            'active': 'list',
                            },
                        ]
                    },
            ]
        }else if( localStorage.getItem('role') ===  'commercial'){
            return  state =  [
                    {
                        'name': 'Transactions',
                        'path': '/transactions',
                        'icon': 'bi bi-cash-coin',
                        'active': 'transaction',
                    },
                    {
                        'name': 'Projets',
                        'path': '/projects',
                        'icon': 'bi bi-archive',
                        'active': 'project',
                    },
                    {
                        'name': 'Facture',
                        'path': '/invoices',
                        'icon': 'bi bi-receipt',
                        'active': 'invoices',
                        'children': [
                            {
                            'name': 'Liste des facture',
                            'path': '/invoices/list',
                            'icon': 'bi bi-receipt',
                            'active': 'list',
                            },
                        ]
                    },
            ]
        }else{
            return  state =  [
                {
                    'name': 'Dashboard',
                    'path': '/dashboard',
                    'icon': 'bi bi-speedometer2',
                    'active': 'dashboard',
                },
                {
                    'name': 'Projets',
                    'path': '/projects',
                    'icon': 'bi bi-archive',
                    'active': 'project',
                },
                {
                    'name': 'Transactions',
                    'path': '/transactions',
                    'icon': 'bi bi-cash-coin',
                    'active': 'transaction',
                },
                {
                    'name': 'Comptes',
                    'path': '/accounts',
                    'icon': 'bi bi-safe2',
                    'active': 'accounts',
                },
                {
                    'name': 'Facture',
                    'path': '/invoices',
                    'icon': 'bi bi-receipt',
                    'active': 'invoices',
                    'children': [
                        {
                        'name': 'Liste des facture',
                        'path': '/invoices/list',
                        'icon': 'bi bi-receipt',
                        'active': 'list',
                        },
                    ]
                },
            ]
    }
}

export default MenuReducer;