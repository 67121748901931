import React, {useState, useEffect} from 'react'
import LargeModal from '../components/modals/LargeModal'
import card from '../card.png'
import 'animate.css'
import URLS from '../components/API';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';
import Notify from '../components/toast/ToastMessage';
import ConfirmModal from '../components/modals/ConfirmModal';
import validator from 'validator';
import moment from 'moment/moment';
import ModalOne from '../components/modals/ModalOne'
import SaveButton from '../components/buttons/SaveButton';
import {useNavigate} from 'react-router-dom';


export default function Accounts() {
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(0);
    const userRoleLabel = localStorage.getItem('role');


    //type manage block
    const [types, setTypes] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [typeChecked, setTypeChecked] = useState('');
    const [projects, setProjects] = useState([]);


    const saveTypeChecked = (e)=>{
        setTypeChecked(e.target.value);
    }
    
    useEffect(()=>{
        axios.get(URLS.accountTypes, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((response)=>{
            if(response.data.types){
                setTypes(response.data.types)
            }else{
                Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
            }
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
        })



        axios.get(URLS.accounts, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((response)=>{
            if(response.data.accounts){
                setAccounts(response.data.accounts)
            }else{
                Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
            }
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
        })

        axios.get(URLS.allProjects, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((response)=>{
            setProjects(response.data.projects);
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', "Erreur de projet", "Veuillez contacter le support développeur");
        })
    }, [refresh]);
    
    const [image, setImage] = useState();
    const [imageError, setImageError] = useState(false);
    const handleImage = (e)=>{
        setImage({
            file: e.target.files[0],
            previewLink: URL.createObjectURL(e.target.files[0])
        })
        setImageError(false);
    }
    const imageValidation = ()=>{
        if(!image){
            setImageError(true);
            return true
        }else{
            setImageError(false);
            return false
        }
    }

    const [addTypeToggle, setAddTypeToggle] = useState(false);
    const handleAddTypeToogle = ()=>{
        setAddTypeToggle(!addTypeToggle);
        setTypeValueError(false);
        setImageError(false);
    }
    const [typeValue, setTypeValue] = useState('');
    const [typeValueError, setTypeValueError] = useState(false);
    const saveTypeValue = (e)=>{
        setTypeValue(e.target.value);
        setTypeValueError(false);
    }
    const validateTypeValue = ()=>{
        if(!typeValue){
            setTypeValueError(true);
            return true;
        }else{
            setTypeValueError(false);
            return false;
        }
    }

    const saveType = ()=>{
        validateTypeValue();
        imageValidation();
        if(!validateTypeValue() && !imageValidation()){
            const data = new FormData();
            data.append('label', typeValue);
            data.append('image', image.file);
            axios.post(URLS.createAccountType, data, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            .then((response)=>{
                if(response.data.success){
                    Notify('success', 'Création d\'un type de compte', 'Type créé avec succès !');
                    setTypeValueError(false);
                    setImageError(false);
                    setTypeValue('');
                    setImage(false);
                    handleAddTypeToogle();
                    setRefresh(refresh+1);
                }else if(response.data.exist){
                    Notify('error', 'Erreur d\'enregistrmenet', response.data.exist);
                }else{
                    Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
            })
        }
    }

     //delete type
    const [typeDeleteModalOpen, setTypeDeleteModalOpen] = useState(false);
    const [typeActionId, setTypeActionId] = useState(false);
    const [typeConfirmCloseClass, setTypeConfirmCloseClass] = useState('opacity-0');
    const openTypeConfirmModal = (id)=>{
        setTypeDeleteModalOpen(true);
        setTypeActionId(id);
    }
    const closeTypeConfirmModal = ()=>{
        setTypeDeleteModalOpen(false);
        setTypeConfirmCloseClass('animate__animated animate__backOutDown');
    }
    const deleteAccountType = (id)=>{
        axios.post(URLS.deleteAccountType, {'id': id}, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
        .then((response)=>{
            if(response.data.success){
                Notify('success', 'Suppression de type de compte', 'Type supprimé avec succès !');
                setRefresh(refresh+1);
            }else{
                Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
            }
        })
        .catch((error)=>{
            console.log(error);
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
        })
    }

    //update type 

    const [updateImage, setUpdateImage] = useState();
    const [updateImageError, setUpdateImageError] = useState(false);
    const handleUpdateImage = (e)=>{
        setUpdateImage({
            file: e.target.files[0],
            previewLink: URL.createObjectURL(e.target.files[0])
        })
        setImageError(false);
    }
    const updateImageValidation = ()=>{
        if(!updateImage){
            setUpdateImageError(true);
            return true
        }else{
            setUpdateImageError(false);
            return false
        }
    }

    const [updateTypeValue, setUpdateTypeValue] = useState(false);
    const [updateTypevalueError, setUpdateTypevalueError] = useState(false)
    const saveUpdateTypeValue = (e)=>{
        setUpdateTypeValue(e.target.value);
    }

    const validateUpdateTypeValue = ()=>{
        if(!saveUpdateTypeValue){
            setUpdateTypevalueError(true);
            return true;
        }else{
            setUpdateTypevalueError(false);
            return false;
        }
    }

    const [editTypeToggle, setEditTypeToggle] = useState(false);
    const editType = (index)=>{
        setEditTypeToggle(types[index].id);
        let middleMan = {
            file: false,
            previewLink: types[index].image
        }
        setUpdateImage(middleMan);
        setUpdateTypeValue(types[index].label)
    }

    const updateType = (id)=>{
        if(updateImageError){
            Notify('error', 'Modification d\'un type de compte', 'Vous devez séléctionner une image');
        }

        if(updateTypevalueError){
            Notify('error', 'Modification d\'un type de compte', 'Vous devez saisir le libellé');
        }
        if(!validateUpdateTypeValue() && !updateImageValidation()){
            const data = new FormData();
            data.append('label', updateTypeValue);
            data.append('id', id);
            data.append('image', updateImage.file);
            axios.post(URLS.updateAccountType, data, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            .then((response)=>{
                if(response.data.success){
                    Notify('success', 'Modification d\'un type de compte', 'Type créé avec succès !');
                    setEditTypeToggle(false);
                    setRefresh(refresh+1);
                }else if(response.data.exist){
                    Notify('error', 'Erreur d\'enregistrement', response.data.exist);
                }else{
                    Notify('error', 'Erreur d\'enregistrement', 'Veuillez contacter le support développeur');
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', 'Erreur d\'enregistrement', 'Veuillez contacter le support développeur');
            })
        }
    }

    //Manage account
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [createModalCloseClass, setCreateModalCloseClass] = useState('opacity-0');
    const closeCreateModalFunction = ()=>{
        setOpenCreateModal(false);
        setCreateModalCloseClass('animate__animated  animate__bounceOutDown')
    }
    const openCreateModalFunction = ()=>{
        setOpenCreateModal(true);
    }

    const [accountLabelValue, setAccountLabelValue] = useState('');
    const saveAccountLabelValue = (e)=>{
        setAccountLabelValue(e.target.value);
        formatCardNumber(e.target.value);
    }
    const [accountLabelError, setAccountLabelError] = useState('');
    const validateAccountLabelValue = ()=>{
        if(!accountLabelValue || !validator.isNumeric(accountLabelValue)){
            setAccountLabelError(true);
            return true
        }else{
            setAccountLabelError(false);
            return false
        }
    }
    const [cardNumber, setCardNumber] = useState(['*', '*', '*', '*','*', '*', '*', '*','*', '*', '*', '*','*', '*', '*', '*']);
    const formatCardNumber = (string)=>{
        let rest = 16 - string.length;
        let number = []
        for (let index = 0 ; index < rest; index++) {
            number.push('*')
        }
        let i = 0
        for (let index = number.length-1; index < 17; index++) {
            number.push(string[i]);
            i++;
        }

        setCardNumber(number);

    }
    const saveAccount = ()=>{
        validateAccountLabelValue();
        if(!typeChecked){
            Notify('error', 'Création de compte', 'Sélectionnez un type de compte !');
        }
        if(!validateAccountLabelValue() && typeChecked){
            const data = {
                'label': accountLabelValue,
                'type': types[typeChecked].id,
            }

            axios.post(URLS.createAccount, data, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            .then((response)=>{
                if(response.data.success){
                    Notify('success', 'Création de compte', 'Compte créé avec succès !');
                    setRefresh(refresh+1);
                    setAccountLabelValue('');
                    setTypeChecked(false);
                    closeCreateModalFunction()
                    formatCardNumber('');
                }else if(response.data.exist){
                    Notify('error', 'Erreur d\'enregistrmenet', response.data.exist);
                }else{
                    Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', 'Erreur d\'enregistrement', 'Veuillez contacter le support développeur !');
            })
        }
    }

    //delete
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [actionId, setActionId] = useState(false);
    const [confirmCloseClass, setConfirmCloseClass] = useState('opacity-0');
    const openConfirmModal = (id)=>{
        setDeleteModalOpen(true);
        setActionId(id);
    }
    const closeConfirmModal = ()=>{
        setDeleteModalOpen(false);
        setConfirmCloseClass('animate__animated animate__backOutDown');
    }

    const deleteAccount = (id)=>{
        axios.post(URLS.deleteAccount, {'id': id}, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
        .then((response)=>{
            if(response.data.success){
                Notify('success', 'Suppression de compte', 'Compte supprimé avec succès !');
                setRefresh(refresh+1);
            }else{
                Notify('error', 'Erreur d\'enregistrement', 'Veuillez contacter le support développeur');
            }
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', 'Erreur d\'enregistrement', 'Veuillez contacter le support développeur');
        })
    }

    const freeze = (index)=>{
        const data = {
            'id': accounts[index].id
        }
        axios.post(URLS.freezeAccount, data, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((response)=>{
            if(response.data.success){
                Notify('success', 'Gestion de comptes', 'État de modifé avec succès !')
                setRefresh(refresh+1);
            }else{
                Notify('error', 'Erreur d\'enregistrement', 'Veuillez contacter le support développeur !');
            }
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', 'Erreur d\'enregistrement', 'Veuillez contacter le support développeur !');
        })
    }

    const [transactionAccount, setTransactionAccount] = useState();
    const [transactionOrigin, setTransactionOrigin] = useState('external');
    // deposit
    const [openDepositModal, setOpenDepositModal] = useState(false);
    const [depositModalCloseClass, setDepositModalCloseClass] = useState('opacity-0');
    const [depositLoader, setDepositLoader] = useState(false);
    const closeDepositModalFunction = ()=>{
        setOpenDepositModal(false);
        setDepositModalCloseClass('animate__animated animate__fadeOutRight');
        setReasonValue('');
        setAmountValue('');
        setTransactionOrigin('external');
        setAccountSourceValue('');
        setProjectValue('');
    }
    const openDepositModalFunction = (account)=>{
        setTransactionAccount(account);
        setOpenDepositModal(true);
    }

    const [projectValue, setProjectValue] = useState('');
    const [projectError, setProjectError] = useState(false);
    const saveProjectValue = (e)=>{
        setProjectValue(e.target.value);
    }
    const projectValueValidation = ()=>{
        if(transactionOrigin === 'external' && !projectValue){
            setProjectError(true);
            return true;
        }else{
            setProjectError(false);
            return false;
        }
    }

    const [reasonValue, setReasonValue] = useState('');
    const [reasonError, setReasonError] = useState('');
    const saveReason = (e)=>{
        setReasonValue(e.target.value)
    }
    const reasonValidation = ()=>{
        if(!reasonValue){
            setReasonError(true);
            return true;
        }else{
            setReasonError(false);
            return false;
        }
    }

    const [amountValue, setAmountValue] = useState('');
    const [amountError, setAmountError] = useState('');
    const saveAmount = (e)=>{
        setAmountValue(e.target.value)
    }
    const amountValidation = ()=>{
        if(!validator.isNumeric(amountValue)){
            setAmountError(true);
            return true;
        }else{
            setAmountError(false);
            return false;
        }
    }

    const [accountSourceValue, setAccountSourceValue] = useState('');
    const [accountSourceError, setAccountSourceError] = useState(false);
    const saveAccountSourceValue = (e)=>{
        setAccountSourceValue(e.target.value);
    }
    const accountSourceValueValidation = ()=>{
        if(transactionOrigin === 'internal' && !accountSourceValue){
            setAccountSourceError(true);
            return true;
        }else{
            setAccountSourceError(false);
            return false;
        }
    }

    const makeDeposit = ()=>{
        setDepositLoader(true);
        projectValueValidation();
        reasonValidation();
        amountValidation();
        accountSourceValueValidation();
        if(!projectValueValidation() && !reasonValidation() && !amountValidation() && !accountSourceValueValidation()){
            const data = {
                'account': transactionAccount.label,
                'amount': amountValue,
                'origin': transactionOrigin,
                'project': projectValue,
                'reason': reasonValue,
                'accountSource': accountSourceValue,
            }
            axios.post(URLS.deposit, data, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            .then((response)=>{
                if(response.data.success){
                    Notify('success', 'Dépôt', 'Dépôt effectué avec succès !')
                    closeDepositModalFunction();
                    setRefresh(refresh+1);
                }else if(response.data.balanceError){
                    Notify('warning', 'Erreur', 'Le solde du compte source est insuffisant !')
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', 'Erreur', 'Veuillez contacter le support développeur !')
            })
        }
        setDepositLoader(false);
    }

    // withdrawal
    const [openWithdrawalModal, setOpenWithdrawalModal] = useState(false);
    const [withdrawalModalCloseClass, setWithdrawalModalCloseClass] = useState('opacity-0');
    const [withdrawalLoader, setWithdrawalLoader] = useState(false);
    const closeWithdrawalModalFunction = ()=>{
        setOpenWithdrawalModal(false);
        setWithdrawalModalCloseClass('animate__animated animate__fadeOutRight');

        setWithdrawalAmountValue('');
        setWithdrawalProjectValue('');
        setWithdrawalReasonValue('');
        setWithdrawalAmountError(false);
        setWithdrawalProjectError(false);
        setWithdrawalProjectError(false);

    }
    const openWithdrawalModalFunction = (account)=>{
        setTransactionAccount(account)
        setOpenWithdrawalModal(true);
    }

    const [withdrawalReasonValue, setWithdrawalReasonValue] = useState('');
    const [withdrawalReasonError, setWithdrawalReasonError] = useState('');
    const saveWithdrawalReason = (e)=>{
        setWithdrawalReasonValue(e.target.value)
    }
    const withdrawalreasonValidation = ()=>{
        if(!withdrawalReasonValue){
            setWithdrawalReasonError(true);
            return true;
        }else{
            setWithdrawalReasonError(false);
            return false;
        }
    }

    const [withdrawalprojectValue, setWithdrawalProjectValue] = useState('');
    const [withdrawalprojectError, setWithdrawalProjectError] = useState(false);
    const saveWithdrawalProjectValue = (e)=>{
        setWithdrawalProjectValue(e.target.value);
    }
    const withdrawalprojectValueValidation = ()=>{
        if(!withdrawalprojectValue){
            setWithdrawalProjectError(true);
            return true;
        }else{
            setWithdrawalProjectError(false);
            return false;
        }
    }

    const [withdrawalAmountValue, setWithdrawalAmountValue] = useState('');
    const [withdrawalAmountError, setWithdrawalAmountError] = useState('');
    const saveWithdrawalAmount = (e)=>{
        setWithdrawalAmountValue(e.target.value)
    }
    const withdrawalAmountValidation = ()=>{
        if(!validator.isNumeric(withdrawalAmountValue)){
            setWithdrawalAmountError(true);
            return true;
        }else{
            setWithdrawalAmountError(false);
            return false;
        }
    }

    const makeWithdrawal = ()=>{
        withdrawalreasonValidation();
        withdrawalprojectValueValidation();
        withdrawalAmountValidation();

        if(!withdrawalreasonValidation() && !withdrawalprojectValueValidation() && !withdrawalAmountValidation()){
            setWithdrawalLoader(true);
            const data = {
                'project': withdrawalprojectValue,
                'account': transactionAccount.label,
                'reason': withdrawalReasonValue,
                'amount': withdrawalAmountValue,
            }
            axios.post(URLS.withdrawal, data, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            .then((response)=>{
                if(response.data.success){
                    Notify('success', 'Retrait', response.data.success);
                    closeWithdrawalModalFunction()
                    setRefresh(refresh+1);
                }else if(response.data.balanceError){
                    Notify('warning', 'Erreur', response.data.balanceError);
                }else{
                    Notify('error', 'Erreur', 'Veuillez contacter le support développeur !');
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', 'Erreur', 'Veuillez contacter le support développeur !');
            })
        }
        setWithdrawalLoader(false);
    }

    return (
        <>
            <div className='text-center my-5 text-4xl font-black'>Le Portefeuille</div>
            <div className='p-5'>
                <div className='bg-white h-full rounded-2xl p-5 grid grid-cols-8'>
                    <div className='col-span-6'>

                    </div>
                    <div className='text-right col-span-2'>
                        <button className='px-5 py-2 bg-blue-500/[.1] rounded-2xl text-blue-500 hover:bg-blue-500 hover:text-white duration-300' onClick={()=>{openCreateModalFunction()}}>
                            <i className="bi bi-wallet-fill text-lg"></i>
                            <span className='pl-3'>Nouveau compte</span>
                        </button>
                    </div>
                </div>
            </div>
            <Toaster position='top-right'/>

            <LargeModal open={openCreateModal} onClose={closeCreateModalFunction} title="Création d'un nouveau compte utilisateur" color="orange" closeClass={createModalCloseClass}>
                <div className='grid grid-cols-2'>
                    <div className='px-5'>
                        <div className='h-52 w-80 bg-gradient-to-r from-orange-300 to-maya-orange rounded-2xl relative'>
                            <div className='absolute p-6 flex items-center justify-between w-full'>
                                <img src={card} alt="" className='h-9' />
                                {typeChecked &&
                                    <img src={types[typeChecked].image} alt="" className='h-9 left-0' />
                                }
                            </div>
                            <div className='absolute grid my-auto text-left h-full w-full pl-1'>
                                <div className="flex items-center space-x-5 text-gray-600">
                                    <div className='text-lg pt-5 space-x-1'>
                                        {
                                            cardNumber.map((value, index)=>{
                                                return (
                                                    <span key={index} className={`${index%4===0 && 'pl-5'}`}>{value}</span>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='absolute bottom-0 p-6 text-gray-600 flex items-center justify-between w-full'>
                                <div className='uppercase'>{typeChecked ? types[typeChecked].label : 'nom du compte'}</div>
                                <div className='text-center'>
                                    <div className='text-xs'>Date de création</div>
                                    <div className='text-xs'>12/12</div>
                                </div>
                            </div>
                        </div>

                        <div className='mt-4'>
                            <div>
                                <small className={`${accountLabelError ? 'text-red-600' : 'hidden'}`}>Veuillez entrer un numéro de compte valide</small>
                                <div className='mb-1'>Numéro</div>
                                <div>
                                    <input type="text" value={accountLabelValue} maxLength="16" className='border border-gray-500 w-3/4 py-1 rounded-xl text-center focus:outline-none focus:border-maya-orange focus:text-maya-orange' onInput={(e)=>{saveAccountLabelValue(e)}}/>
                                </div>
                            </div>
                            <div className='my-4'>
                                <select name="" value={typeChecked} className='border border-gray-500 w-3/4 py-2 rounded-xl pl-3' onChange={(e)=>{saveTypeChecked(e)}}>
                                    <option value="" selected="selected">Type de compte</option>
                                    {types.map((type, index)=>{
                                       return <option key={index} value={index}>{type.label}</option>
                                    })}
                                </select>
                            </div>
                            <div>
                                <button className='mt-4 bg-maya-orange text-white px-4 py-2 rounded-xl hover:bg-orange-600 duration-300' onClick={()=>{saveAccount()}}>Enregistrer</button>
                            </div>
                        </div>
                    </div>

                    <div className='mt-3 mr-3'>
                        <div className='text-right'>
                            <button className='px-5 py-2  bg-cyan-500/[.1] rounded-2xl text-cyan-500 hover:bg-cyan-500 hover:text-white duration-300' onClick={()=>{handleAddTypeToogle()}}>
                                <i className="bi bi-tags-fill text-lg"></i>
                                <span className='pl-3'>
                                   {addTypeToggle ? 'Les types de comptes' : 'Ajouter un type de compte'}
                                </span>
                            </button>
                        </div>
                        {addTypeToggle ? (
                            <div className='animate__animated animate__bounceIn'>
                                <div className='mt-4 text-right'>
                                    <input type="text" value={typeValue} placeholder='Libellé du type de compte' className='border px-5 py-2 rounded-2xl border-cyan-500 focus:outline-none text-cyan-500 text-center' onInput={(e)=>{saveTypeValue(e)}}/>
                                </div>
                                <div className='text-right'>
                                    <small className={`text-red-500 ${typeValueError ? '' : 'hidden'}`}>Vous devez renseigner le libellé</small>
                                </div>
                                <div className='mt-4'>
                                    {
                                        !image ? (
                                            <label className="w-28 ml-auto h-28 flex flex-col items-center px-4 py-2 bg-cyan-400 text-white rounded-lg shadow-lg tracking-wide border border-white hover:border-kalloba cursor-pointer hover:bg-blue hover:text-kalloba duration-300">
                                                <i className="bi bi-cloud-arrow-up-fill text-3xl"></i>
                                                <span className=" text-md">Logo</span>
                                                <input type='file' className="hidden" onChange={(e)=>{handleImage(e)}}/>
                                            </label>
                                        ) :  (
                                            <div className="animate__animated animate__fadeIn text-right">
                                                <img src={image.previewLink} className="h-28 ml-auto border border-cyan-300 w-28 rounded-md " alt="logo type"/>
                                                <i className=" pl-10 bi bi-trash rounded-full px-[0.1em] font-bold mt-1 cursor-pointer hover:scale-125 hover:text-red-500" onClick={()=>{setImage(false)}}></i>
                                            </div>
                                        )
                                        
                                    }
                                    <div className={`text-right ${imageError ? '' : 'hidden'}`}>
                                        <small className={`text-red-500 leading-6`}>Veuillez sélectionner une image</small>
                                    </div>
                                </div>
                                <div className='mt-4 text-right'>
                                    <button className='bg-cyan-500 text-white px-2 py-1 rounded-xl hover:bg-cyan-600 duration-300' onClick={()=>{saveType()}}>Enregistrer</button>
                                </div>
                            </div>
                        ):(
                            <div className='mt-4 p-4 w-full border border-cyan-500 overflow-auto max-h-80 rounded-2xl animate__animated animate__zoomIn'>
                                <table className='w-full'>
                                    <thead className='text-white rounded-xl sticky top-0'>
                                        <tr className='py-10 rounded-xl'>
                                            <th scope='col' className='bg-cyan-500/[.7]'>Libellé</th>
                                            <th scope='col' className='bg-cyan-500/[.7]'>Logo Marchand</th>
                                            <th scope='col' className='bg-cyan-500/[.7] py-2 rounded-r-xl'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        types && types.map((type, index)=>{
                                            return (
                                                <tr key={index} className={`${index%2!==0 && 'bg-gray-100'}`}>
                                                    <td className='text-center my-1 py-2 rounded-l-2xl'>
                                                    {editTypeToggle && editTypeToggle === type.id ? (
                                                            <input type="text" value={updateTypeValue} className=' text-center border border-maya-orange rounded-2xl w-full' onInput={(e)=>{saveUpdateTypeValue(e)}} />
                                                        ) : (
                                                            type.label
                                                    )}
                                                    </td>
                                                    <td className='text-center my-1 py-2'>
                                                        {editTypeToggle && editTypeToggle === type.id ? (
                                                                !updateImage ? (
                                                                    <label className="w-10 mx-auto h-10 flex flex-col items-center px-4 py-2 bg-cyan-400 text-white rounded-lg shadow-lg tracking-wide border border-white hover:border-kalloba cursor-pointer hover:bg-blue hover:text-kalloba duration-300">
                                                                        <i className="bi bi-cloud-arrow-up-fill text-3xl"></i>
                                                                        <input type='file' className="hidden" onChange={(e)=>{handleUpdateImage(e)}}/>
                                                                    </label>
                                                                ) :  (
                                                                    <div className="animate__animated animate__fadeIn flex justify-center items-center">
                                                                        <img src={updateImage.previewLink} className="h-10 mx-auto border border-cyan-300 w-10 rounded-md " alt="logo type"/>
                                                                        <i className="bi bi-trash rounded-full px-[0.1em] font-bold mt-1 cursor-pointer hover:scale-125 hover:text-red-500" onClick={()=>{setUpdateImage(false)}}></i>
                                                                    </div>
                                                                )
                                                            ) : (
                                                                <img src={type.image} className="h-10 mx-auto rounded-xl" alt="" />
                                                        )}
                                                    </td>
                                                    <td className='flex justify-center gap-6 my-1 py-2'>
                                                        {editTypeToggle && editTypeToggle === type.id ? (
                                                            <div>
                                                                <button><i className="bi bi-check-circle-fill rounded-full px-2 py-1 text-2xl text-green-500" onClick={()=>{updateType(type.id)}}></i></button>
                                                                <button><i className="bi bi-x-circle-fill rounded-full px-2 py-1 text-2xl text-red-500" onClick={()=>{setEditTypeToggle(false)}}></i></button>
                                                            </div>
                                                            ) : (
                                                                <>
                                                                    <i className="bi bi-pen cursor-pointer hover:bg-green-200 px-2 py-1 rounded-full text-green-600 duration-300" onClick={()=>{editType(index)}}></i>
                                                                    <i className="bi bi-trash cursor-pointer hover:bg-red-200 px-2 py-1 rounded-full text-red-600 duration-300" onClick={()=>{openTypeConfirmModal(type.id)}} ></i>
                                                                </>
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        )}
                        
                    </div>
                </div>
                
            </LargeModal>
            {/* delete type  */}
            <ConfirmModal open={typeDeleteModalOpen} closeClass={typeConfirmCloseClass} onClose={closeTypeConfirmModal} id={typeActionId} action={deleteAccountType}>
                    <span>Cette action
                     est irréversible et supprimera tous les comptes liés!
                     </span>
            </ConfirmModal>

            <div className='p-10 m-5 bg-white rounded-2xl grid grid-cols-3 gap-8'>
                {accounts && accounts.map((account, index)=>{
                    return(
                        <div key={index} className='mb-2 relative group'>
                            <div className={`h-52 w-80 bg-gradient-to-r from-orange-300 to-maya-orange rounded-2xl relative shadow-lg ${account.state === 0 && 'blur-sm'}`}>
                                <div className='absolute p-6 flex items-center justify-between w-full'>
                                    <img src={card} alt="" className='h-9' />
                                    <img src={account.image} alt="" className='h-9 left-0' />
                                </div>
                                <div className='absolute grid my-auto text-left h-full w-full pl-1'>
                                    <div className="flex items-center space-x-5 text-gray-600">
                                        <div className='text-lg pt-5 flex justify-between w-full mr-3'>
                                            <span className='pl-5'>{account.label}</span>
                                            <span className='pl-5'>{account.balance.toLocaleString('en-US')} Fcfa</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='absolute bottom-0 p-6 text-gray-600 flex items-center justify-between w-full'>
                                    <div className='uppercase'>{account.accounttype.label}</div>
                                    <div className='text-center'>
                                        <div className='text-xs'>Date de création</div>
                                        <div className='text-xs'>1{moment(account.created_at).format('l')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-between items-center w-80 mt-4'>
                                <button className={`px-2 py-1 ${account.state === 0 ? 'bg-gray-500/[.2] text-gray-500' : 'bg-green-500/[.2] text-green-500 hover:bg-green-500 hover:text-white'} rounded-xl font-bold duration-300`} disabled={account.state === 0 } onClick={()=>{openDepositModalFunction(account)}}>Dépôt</button>
                                <button className={`px-2 py-1 ${account.state === 0 ? 'bg-gray-500/[.2] text-gray-500' : 'bg-orange-500/[.2] text-orange-500 hover:bg-orange-500 hover:text-white'} rounded-xl font-bold duration-300`} disabled={account.state === 0 } onClick={()=>{openWithdrawalModalFunction(account)}}>Retrait</button>
                                {(userRoleLabel === 'développeur' || userRoleLabel === 'administrateur') && (
                                    <>
                                        {account.state === 1 ? (
                                            <button className='px-2 py-1 bg-sky-500/[.2] text-sky-500 rounded-xl font-bold hover:bg-sky-500 hover:text-white duration-300' onClick={()=>{freeze(index)}}>Géler</button>
                                            ):(
                                            <button className='px-2 py-1 bg-teal-500/[.2] text-teal-500 rounded-xl font-bold hover:bg-teal-500 hover:text-white duration-300' onClick={()=>{freeze(index)}}>Dégeler</button>
                                        )}
                                        <button className={`px-2 py-1 ${account.state === 0 ? 'bg-gray-500/[.2] text-gray-500' : 'bg-red-500/[.2] text-red-500 hover:bg-red-500 hover:text-white'} rounded-xl font-bold duration-300`} disabled={account.state === 0 } onClick={()=>{openConfirmModal(account.id)}}>
                                            <i className="bi bi-trash3-fill"></i>
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
            
            {/* deposit  */}
            <ModalOne title="Dépôt" color="green" open={openDepositModal} onClose={closeDepositModalFunction} closeClass={depositModalCloseClass}>
                <div className='my-4 p-4 border rounded-lg border-green-200'>
                    <div className='mt-4'>
                        <div className='text-xl font-bold'>Origine des fonds</div>
                        <div className='mt-2'>
                            <select type="text" value={transactionOrigin} className='border w-full rounded-xl py-2 focus:outline-none focus:border-green-500 border-gray-200 px-2 text-lg' onChange={(e)=>{setTransactionOrigin(e.target.value); setAccountSourceValue(''); setProjectValue('')}}>
                                <option value="external">Externe</option>
                                <option value="internal">Interne</option>
                            </select>
                        </div>
                    </div>
                    {
                        transactionOrigin === 'internal' ? (
                            <div className='mt-4'>
                                <div className='text-xl font-bold'>Compte source</div>
                                <div className='mt-2'>
                                    <select type="text" value={accountSourceValue} className='border w-full rounded-xl py-2 focus:outline-none focus:border-green-500 border-gray-200 px-2 text-lg' onChange={(e)=>{saveAccountSourceValue(e)}}>
                                        <option value="">Choix du compte source</option>
                                        {
                                            accounts.map((account, index)=>{
                                                if(account.label !== transactionAccount.label){
                                                    return <option key={index} value={account.id}>{account.label}</option>
                                                }
                                            })
                                        }
                                    </select>
                                    <small className={`${accountSourceError ? 'text-red-500' : 'hidden'}`}>Vous devez renseigner le compte source</small>
                                </div>
                            </div>
                        ) : (
                            <div className='mt-4'>
                                <div className='text-xl font-bold'>Projet</div>
                                <div className='mt-2'>
                                    <select type="text" value={projectValue} className='border w-full rounded-xl py-2 focus:outline-none focus:border-green-500 border-gray-200 px-2 text-lg' onChange={(e)=>{saveProjectValue(e)}}>
                                        <option value="">Choix du projet</option>
                                        {
                                            projects && projects.map((project, index)=>{
                                                return <option key={index} value={project.id}>{project.label}</option>
                                            })
                                        }
                                    </select>
                                    <small className={`${projectError ? 'text-red-500' : 'hidden'}`}>Vous devez renseigner le projet</small>
                                </div>
                            </div>
                        )
                    }

                    <div className='mt-4'>
                        <div className='text-xl font-bold'>Numéro du compte receveur</div>
                        <div className='mt-2'>
                            <input type="text" value={transactionAccount ? transactionAccount.label : ''} disabled={true} className='bg-blue-100 text-blue-500 font-bold text-center border w-full rounded-xl py-2 focus:outline-none focus:border-blue-500 px-2 text-lg' />
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className='text-xl font-bold'>Type de compte</div>
                        <div className='mt-2 flex bg-blue-100 text-blue-500 border rounded-xl relative'>
                            <input type="text" value={transactionAccount ? transactionAccount.accounttype.label :''} disabled={true} className='bg-transparent font-bold text-center w-full py-2 focus:outline-none focus:border-green-500 px-2 text-lg' />
                            <img src={transactionAccount && transactionAccount.accounttype.media[0].original_url} className="h-10 absolute right-0" alt="" />
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className='text-xl font-bold required'>Motif du dépôt</div>
                        <div className='mt-2'>
                            <textarea value={reasonValue} id="" cols="30" rows="6" className='w-full border border-2 rounded-xl border-gray-200 focus:outline-none p-2 focus:border-green-500' onInput={(e)=>saveReason(e)}></textarea>
                            <small className={`${reasonError ? 'text-red-500' : 'hidden'}`}>Vous devez renseigner le motif du dépôt</small>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className='text-xl font-bold required'>Montant</div>
                        <div className='mt-2'>
                            <input type="text" value={amountValue} className='border w-full rounded-xl py-2 focus:outline-none focus:border-green-500 border-gray-200 px-2 text-lg' onInput={(e)=>saveAmount(e)}/>
                            <small className={`${amountError ? 'text-red-500' : 'hidden'}`}>Vous devez renseigner le montant</small>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className='text-xl font-bold'>Date</div>
                        <div className='mt-2'>
                            <input type="text" value={moment().format('DD - MM - YYYY')} disabled={true} className='font-bold text-center bg-gray-200 border w-full rounded-xl py-2 focus:outline-none focus:border-green-500 border-gray-200 px-2 text-lg' />
                        </div>
                    </div>
                    
                    <div className='mt-5 text-center'>
                        <SaveButton className="bg-green-500 px-2 py-1 text-lg rounded-lg text-white hover:text-xl duration-300" text="Enregistrer" save={makeDeposit} loader={depositLoader}/>
                    </div>
                </div>
            </ModalOne>

            {/* withdrawal  */}
            <ModalOne title="Retrait" color="orange" open={openWithdrawalModal} onClose={closeWithdrawalModalFunction} closeClass={withdrawalModalCloseClass}>
                <div className='my-4 p-4 border rounded-lg border-green-200'>
                    <div className='mt-4'>
                        <div className='text-xl font-bold'>Projet</div>
                        <div className='mt-2'>
                            <select type="text" value={withdrawalprojectValue} className='border w-full rounded-xl py-2 focus:outline-none focus:border-green-500 border-gray-200 px-2 text-lg' onChange={(e)=>{saveWithdrawalProjectValue(e)}}>
                                <option value="">Choix du projet</option>
                                {
                                    projects && projects.map((project, index)=>{
                                        return <option key={index} value={project.id}>{project.label}</option>
                                    })
                                }
                            </select>
                            <small className={`${withdrawalprojectError ? 'text-red-500' : 'hidden'}`}>Vous devez renseigner le projet</small>
                        </div>
                    </div>

                    <div className='mt-4'>
                        <div className='text-xl font-bold'>Numéro du compte à débiter</div>
                        <div className='mt-2'>
                            <input type="text" value={transactionAccount ? transactionAccount.label : ''} disabled={true} className='bg-red-100 text-red-500 font-bold text-center border w-full rounded-xl py-2 focus:outline-none focus:border-red-500 px-2 text-lg' />
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className='text-xl font-bold'>Type de compte</div>
                        <div className='mt-2 flex bg-red-100 text-red-500 border rounded-xl relative'>
                            <input type="text" value={transactionAccount ? transactionAccount.accounttype.label :''} disabled={true} className='bg-transparent font-bold text-center w-full py-2 focus:outline-none focus:border-green-500 px-2 text-lg' />
                            <img src={transactionAccount && transactionAccount.accounttype.media[0].original_url} className="h-10 absolute right-0" alt="" />
                        </div>
                    </div>
                    
                    <div className='mt-3'>
                        <div className='text-xl font-bold required'>Motif du retrait</div>
                        <div className='mt-2'>
                            <textarea value={withdrawalReasonValue} id="" cols="30" rows="6" className='w-full border border-2 rounded-xl border-gray-200 focus:outline-none p-2 focus:border-green-500' onInput={(e)=>saveWithdrawalReason(e)}></textarea>
                            <small className={`${withdrawalReasonError ? 'text-red-500' : 'hidden'}`}>Vous devez renseigner le motif du retrait</small>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className='text-xl font-bold required'>Montant</div>
                        <div className='mt-2'>
                            <input type="text" value={withdrawalAmountValue} className='border w-full rounded-xl py-2 focus:outline-none focus:border-green-500 border-gray-200 px-2 text-lg' onInput={(e)=>saveWithdrawalAmount(e)}/>
                            <small className={`${withdrawalAmountError ? 'text-red-500' : 'hidden'}`}>Vous devez renseigner le montant</small>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className='text-xl font-bold'>Date</div>
                        <div className='mt-2'>
                            <input type="text" value={moment().format('DD - MM - YYYY')} disabled={true} className='font-bold text-center bg-gray-200 border w-full rounded-xl py-2 focus:outline-none focus:border-green-500 border-gray-200 px-2 text-lg' />
                        </div>
                    </div>

                    <div className='mt-5 text-center'>
                        <SaveButton className="bg-green-500 px-2 py-1 text-lg rounded-lg text-white hover:text-xl duration-300" text="Enregistrer" save={makeWithdrawal} loader={depositLoader}/>
                    </div>
                </div>
            </ModalOne>
            <ConfirmModal open={deleteModalOpen} closeClass={confirmCloseClass} onClose={closeConfirmModal} id={actionId} action={deleteAccount}>
                    <span>Cette action
                     supprimera toutes les transactions liées!
                     </span>
            </ConfirmModal>
        </>
    )
}
