import React,  {useEffect, useState} from 'react'
import validator from 'validator';
import ModalOne from '../modals/ModalOne';
import SaveButton from '../buttons/SaveButton';
import axios from 'axios';
import URLS from '../API';
import { Toaster } from 'react-hot-toast';
import Notify from '../toast/ToastMessage';
import moment from 'moment/moment';
import { useNavigate } from "react-router-dom";

export default function Deposit(props) {
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState([]);
    const [projects, setProjects] = useState([]);
    const [loader, setLoader] = useState(false);
    const [transactionOrigin, setTransactionOrigin] = useState('external');

    useEffect(()=>{
        axios.get(URLS.accounts, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((response)=>{
            if(response.data.accounts){
                setAccounts(response.data.accounts)
            }else{
                Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
            }
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
        })

        axios.get(URLS.allProjects, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((response)=>{
            setProjects(response.data.projects);
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', "Erreur de projet", "Veuillez contacter le support développeur");
        })
    }, [])

    const [receptAccount, setReceptAccount] =  useState();
    const saveReceptAccount = (e)=>{
        setReceptAccount(e.target.value)
    }
    const [receptAccountError, setReceptAccountError] =  useState(false);
    const receptAccountValidation = ()=>{
        if(!receptAccount){
            setReceptAccountError(true);
            return true;
        }else{
            setReceptAccountError(false);
            return false;
        }
    }

    const [sourceAccount, setSourceAccount] =  useState();
    const saveSourceAccount = (e)=>{
        setSourceAccount(e.target.value)
    }
    const [sourceAccountError, setSourceAccountError] =  useState(false);
    const sourceAccountValidation = ()=>{

        if(transactionOrigin === 'internal'){
            if(!sourceAccount){
                setSourceAccountError(true);
                return true;
            }else{
                setSourceAccountError(false);
                return false;
            }
        }
    }

    const [sourceProject, setSourceProject] =  useState();
    const saveSourceProject = (e)=>{
        setSourceProject(e.target.value)
    }
    const [sourceProjectError, setSourceProjectError] =  useState(false);
    const sourceProjectValidation = ()=>{

        if(transactionOrigin === 'external'){
            if(!sourceProject){
                setSourceProjectError(true);
                return true;
            }else{
                setSourceProjectError(false);
                return false;
            }
        }
    }

    const [amount, setAmount] = useState('');
    const saveAmount = (e)=>{
        setAmount(e.target.value)
    }
    const [amountError, setAmountError] =  useState(false);
    const amountValidation = ()=>{
        if(!amount || !validator.isNumeric(amount)){
            setAmountError(true);
            return true;
        }else{
            setAmountError(false);
            return false;
        }
    }

    const [reason, setReason] = useState('');
    const saveReason = (e)=>{
        setReason(e.target.value)
    }
    const [reasonError, setReasonError] =  useState(false);
    const reasonValidation = ()=>{
        if(!reason){
            setReasonError(true);
            return true;
        }else{
            setReasonError(false);
            return false;
        }
    }

    const makeDeposit = ()=>{
        receptAccountValidation();
        sourceAccountValidation();
        sourceProjectValidation();
        amountValidation();
        reasonValidation();
        if(!receptAccountValidation() && !sourceAccountValidation() && !sourceProjectValidation() && !amountValidation() && !reasonValidation()){
            const data = {
                'account': receptAccount ? accounts[receptAccount].label : '',
                'amount': amount,
                'origin': transactionOrigin,
                'project': sourceProject ? projects[sourceProject].id : '',
                'reason': reason,
                'accountSource': accounts[sourceAccount].label,
            }
            axios.post(URLS.deposit, data, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            .then((response)=>{
                if(response.data.success){
                    Notify('success', 'Dépôt', 'Dépôt effectué avec succès !')
                    setSourceAccount('');
                    setAccounts('')
                    setAmount('');
                    setSourceProject('');
                    setTransactionOrigin('external');
                    setReason('');
                    props.setRefresh(props.refresh+1);
                    props.closeDepositModalFunction();
                }else if(response.data.balanceError){
                    Notify('warning', 'Erreur', 'Le solde du compte source est insuffisant !')
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', 'Erreur', 'Veuillez contacter le support développeur !')
            })
        }
    }

    return (
        <>
            <Toaster position='top-right'/>
            <ModalOne title="Dépôt" color="green" open={props.openDepositModal} onClose={props.closeDepositModalFunction} closeClass={props.depositModalCloseClass}>
                <div className='my-4 p-4 border rounded-lg border-green-200'>
                    <div>
                        <div className='mt-4'>
                            <div className='text-xl font-bold required'>Compte receveur</div>
                        </div>
                        <div className='mt-2'>
                            <select name="" id="" value={receptAccount} className='w-full py-3 rounded-lg px-3' onChange={(e)=>saveReceptAccount(e)}>
                                <option value="" selected="selected">Sélectionner le compte receveur</option>
                                {accounts && accounts.map((account, index)=>{
                                    return <option key={index} value={index} >{account.label}</option>
                                })}
                            </select>
                            <small className={`text-red-500 ${receptAccountError ? '' : 'hidden'}`}>Vous devez sélectionner le compte sur lequel doit être effectué le dépôt</small>
                        </div>
                    </div>
                    <div>
                        <div className='mt-4'>
                            <div className='text-xl font-bold required'>Origine des fonds</div>
                        </div>
                        <div className='mt-2'>
                            <select name="" id="" className='w-full py-3 rounded-lg px-3' onChange={(e)=>setTransactionOrigin(e.target.value)}>
                                <option value="external" selected="selected">Externe</option>
                                <option value="internal">Interne</option>
                            </select>
                        </div>
                    </div>
                    {transactionOrigin === 'external' ? (
                        <div>
                            <div className='mt-4'>
                                <div className='text-xl font-bold required'>Projet</div>
                            </div>
                            <div className='mt-2'>
                                <select name="" id="" className='w-full py-3 focus:border-green-300 focus:outline-none rounded-lg px-3' onChange={(e)=>{saveSourceProject(e)}}>
                                    <option value="" selected="selected">Sélectionner le projet</option>
                                    {
                                        projects.map((project, index)=>{
                                            return <option key={index} value={index}>{project.label}</option>
                                        })
                                    }
                                </select>
                                <small className={`text-red-500 ${sourceProjectError ? '' : 'hidden'}`}>Vous devez sélectionner le projet</small>
                            </div>
                        </div>
                    ):(
                        <div>
                            <div className='mt-4'>
                                <div className='text-xl font-bold'>Compte source</div>
                            </div>
                            <div className='mt-2'>
                                <select name="" id="" className='w-full py-3 focus:border-green-300 focus:outline-none px-3 rounded-lg px-3' onChange={(e)=>saveSourceAccount(e)}>
                                    <option value="" selected="selected">Sélectionner le compte source des fonds</option>
                                    {accounts && accounts.map((account, index)=>{
                                        if(account.label !== receptAccount.label){
                                            return <option key={index} value={account.id}>{account.label}</option>
                                        }
                                    })}
                                </select>
                                <small className={`text-red-500 ${sourceAccountError ? '' : 'hidden'}`}>Vous devez sélectionner le compte du quel doit provenir les fonds</small>
                            </div>
                        </div>
                    )}
                    <div>
                        <div className='mt-4'>
                            <div className='text-xl font-bold required'>Montant</div>
                        </div>
                        <div className='mt-2'>
                            <input type="text" value={amount} className="border py-3 focus:border-green-300 focus:outline-none px-3 w-full rounded-lg" onInput={(e)=>{saveAmount(e)}}/>
                            <small className={`text-red-500 ${amountError ? '' : 'hidden'}`}>Vous devez saisir le montant à déposer</small>
                        </div>
                    </div>
                    <div>
                        <div className='mt-4'>
                            <div className='text-xl font-bold required'>Motif</div>
                        </div>
                        <div className='mt-2'>
                            <textarea type="text" value={reason} className="border py-3 focus:border-green-300 focus:outline-none px-3 w-full rounded-lg" rows="6" onInput={(e)=>saveReason(e)}/>
                            <small className={`text-red-500 ${reasonError ? '' : 'hidden'}`}>Vous devez saisir le montif du dépôt</small>
                        </div>
                    </div>
                    <div>
                        <div className='mt-4'>
                            <div className='text-xl font-bold'>Date</div>
                        </div>
                        <div className='mt-2'>
                            <input type="text" className="border py-3 focus:border-green-300 focus:outline-none px-3 w-full rounded-lg bg-gray-200 text-center font-bold text-lg" disabled value={moment().format('DD - MM - YYYY')} />
                        </div>
                    </div>
                    <div className='mt-6 text-center'>
                        <SaveButton className='py-2 px-4 text-lg bg-green-500 text-white rounded-lg hover:text-xl duration-300 font-bold' save={makeDeposit} text='Enregistrer' loader={loader}/>
                    </div>
                </div>
            </ModalOne>
        </>
    )
}
