import React, {useState, useEffect} from 'react'
import BreadCrumb from '../components/breadcrumb/BreadCrumb'
import 'animate.css'
import validator from 'validator'
import ModalOne from '../components/modals/ModalOne'
import SaveButton from '../components/buttons/SaveButton'
import axios from 'axios'
import URLS from '../components/API'
import Notify from '../components/toast/ToastMessage';
import { Toaster } from 'react-hot-toast'
import moment from 'moment'
import ConfirmModal from '../components/modals/ConfirmModal'
import {useNavigate} from 'react-router-dom';


export default function Projects() {
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(0)
    const [projects, setProjects] = useState([]);
    const [subProjects, setSubProjects] = useState([]);
    const userRoleLabel = localStorage.getItem('role');
    useEffect(()=>{
        axios.get(URLS.projects, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((response)=>{
            setProjects(response.data.projects);
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', "Erreur de projet", "Veuillez contacter le support développeur");
        })


        axios.get(URLS.subProjects, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((response)=>{
            setSubProjects(response.data.subProjects);
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', "Erreur de projet", "Veuillez contacter le support développeur");
        })
    }, [refresh])

    // create project
    const [openCreateProjectModal, setOpenCreateProjectModal] = useState(false);
    const [createProjectModalCloseClass, setCreateProjectModalCloseClass] = useState('opacity-0');
    const [createProjectLoader, setCreateProjectLoader] = useState(false);
    const closeCreateProjectModalFunction = ()=>{
        setOpenCreateProjectModal(false);
        setCreateProjectModalCloseClass('animate__animated animate__fadeOutRight')
        setProjectNameError(false);
        setProjectDescriptionError(false);
        setProjectEndDateError(false);
        setProjectBudgetError(false);
    }
    const openCreateProjectModalFunction = (account)=>{
        setOpenCreateProjectModal(true);
    }

    const [projectNameValue, setProjectNameValue] = useState('');
    const [projectNameError, setProjectNameError] = useState(false);
    const saveProjectNameValue = (e)=>{
        setProjectNameValue(e.target.value);
    }
    const projectNameValidation = ()=>{
        if(!projectNameValue){
            setProjectNameError(true);
            return true
        }else{
            setProjectNameError(false);
            return false
        }
    }

    const [projectDescriptionValue, setProjectDescriptionValue] = useState('');
    const [projectDescriptionError, setProjectDescriptionError] = useState(false);
    const saveProjectDescriptionValue = (e)=>{
        setProjectDescriptionValue(e.target.value);
    }
    const projectDescriptionValidation = ()=>{
        if(!projectDescriptionValue){
            setProjectDescriptionError(true);
            return true
        }else{
            setProjectDescriptionError(false);
            return false
        }
    }

    const [projectEndDateValue, setProjectEndDateValue] = useState('');
    const [projectEndDateError, setProjectEndDateError] = useState(false);
    const saveProjectEndDateValue = (e)=>{
        setProjectEndDateValue(e.target.value);
    }
    const projectEndDateValidation = ()=>{
        if(!projectEndDateValue){
            setProjectEndDateError(true);
            return true
        }else{
            setProjectEndDateError(false);
            return false
        }
    }

    const [projectBudgetValue, setProjectBudgetValue] = useState('');
    const [projectBudgetError, setProjectBudgetError] = useState(false);
    const saveProjectBudgetValue = (e)=>{
        setProjectBudgetValue(e.target.value);
    }
    const projectBudgetValidation = ()=>{
        if(!projectBudgetValue || ! validator.isNumeric(projectBudgetValue)){
            setProjectBudgetError(true);
            return true
        }else{
            setProjectBudgetError(false);
            return false
        }
    }
    
    const saveProject = ()=>{
        projectNameValidation();
        projectDescriptionValidation();
        projectEndDateValidation();
        projectBudgetValidation();
        if(!projectNameValidation() && !projectDescriptionValidation() && !projectEndDateValidation() && !projectBudgetValidation()){
            setCreateProjectLoader(true);
            const data = {
                'label': projectNameValue,
                'description': projectDescriptionValue,
                'deadline': projectEndDateValue,
                'budget': projectBudgetValue,
            }

            axios.post(URLS.createProject, data, {
                headers:{
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            .then((response)=>{
                if(response.data.success){
                    Notify('success', 'Enregistrement de projet', response.data.success);
                    setRefresh(refresh+1);
                    setProjectBudgetValue('');
                    setProjectNameValue('');
                    setProjectDescriptionValue('');
                    setProjectEndDateValue('');
                    closeCreateProjectModalFunction();
                }else if(response.data.exist){
                    Notify('error', "Erreur d'enregistrement de projet", response.data.exist);
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', "Erreur d'enregistrement de projet", "Veuillez contacter le support développeur");
            })
            setCreateProjectLoader(false);

        }
    }

     // create sub project
     const [openCreateSubProjectModal, setOpenCreateSubProjectModal] = useState(false);
     const [createSubProjectModalCloseClass, setCreateSubProjectModalCloseClass] = useState('opacity-0');
     const [createSubProjectLoader, setCreateSubProjectLoader] = useState(false);
     const closeCreateSubProjectModalFunction = ()=>{
        setOpenCreateSubProjectModal(false);
        setCreateSubProjectModalCloseClass('animate__animated animate__fadeOutRight')
        setSubProjectParentError(false);
        setSubProjectNameError(false);
        setSubProjectDescriptionError(false);
        setSubProjectEndDateError(false);
        setSubProjectBudgetError(false);
    }
    const openCreateSubProjectModalFunction = (account)=>{
        setOpenCreateSubProjectModal(true);
    }

    const [subProjectParentValue, setSubProjectParentValue] = useState();
    const [subProjectParentError, setSubProjectParentError] = useState(false);
    const saveSubProjectParentValue = (e)=>{
        setSubProjectParentValue(e.target.value);
    }
    const subProjectParentValidation = ()=>{
        if(!subProjectParentValue){
            setSubProjectParentError(true);
            return true
        }else{
            setSubProjectParentError(false);
            return false
        }
    }

    const [subProjectNameValue, setSubProjectNameValue] = useState('');
    const [subProjectNameError, setSubProjectNameError] = useState(false);
    const saveSubProjectNameValue = (e)=>{
        setSubProjectNameValue(e.target.value);
    }
    const subProjectNameValidation = ()=>{
        if(!subProjectNameValue){
            setSubProjectNameError(true);
            return true
        }else{
            setSubProjectNameError(false);
            return false
        }
    }

    const [subProjectDescriptionValue, setSubProjectDescriptionValue] = useState('');
    const [subProjectDescriptionError, setSubProjectDescriptionError] = useState(false);
    const savesubProjectDescriptionValue = (e)=>{
        setSubProjectDescriptionValue(e.target.value);
    }
    const subProjectDescriptionValidation = ()=>{
        if(!subProjectDescriptionValue){
            setSubProjectDescriptionError(true);
            return true
        }else{
            setSubProjectDescriptionError(false);
            return false
        }
    }

    const [subProjectEndDateValue, setSubProjectEndDateValue] = useState('');
    const [subProjectEndDateError, setSubProjectEndDateError] = useState(false);
    const saveSubProjectEndDateValue = (e)=>{
        setSubProjectEndDateValue(e.target.value);
    }
    const subProjectEndDateValidation = ()=>{
        if(!subProjectEndDateValue){
            setSubProjectEndDateError(true);
            return true
        }else{
            setSubProjectEndDateError(false);
            return false
        }
    }

    const [subProjectBudgetValue, setSubProjectBudgetValue] = useState('');
    const [subProjectBudgetError, setSubProjectBudgetError] = useState(false);
    const saveSubProjectBudgetValue = (e)=>{
        setSubProjectBudgetValue(e.target.value);
    }
    const subProjectBudgetValidation = ()=>{
        if(!subProjectBudgetValue || ! validator.isNumeric(subProjectBudgetValue)){
            setSubProjectBudgetError(true);
            return true
        }else{
            setSubProjectBudgetError(false);
            return false
        }
    }

    const [subProjectFinancialGoalValue, setSubProjectFinancialGoalValue] = useState('');
    const [subProjectFinancialGoalError, setSubProjectFinancialGoalError] = useState(false);
    const saveSubProjectFinancialGoalValue = (e)=>{
        setSubProjectFinancialGoalValue(e.target.value);
    }
    const subProjectFinancialGoalValidation = ()=>{
        if(!subProjectFinancialGoalValue || ! validator.isNumeric(subProjectFinancialGoalValue)){
            setSubProjectFinancialGoalError(true);
            return true
        }else{
            setSubProjectFinancialGoalError(false);
            return false
        }
    }

    //deleted subproject
    const [deleteSubProjectModalOpen, setDeleteSubProjectModalOpen] = useState(false);
    const [actionId, setActionId] = useState(false);
    const [confirmSubProjectCloseClass, setConfirmSubProjectCloseClass] = useState('opacity-0');
    const openDeleteSubProjectConfirmModal = (id)=>{
        setDeleteSubProjectModalOpen(true);
        setActionId(id);
    }
    const closeDeleteSubProjecctConfirmModal = ()=>{
        setDeleteSubProjectModalOpen(false);
        setConfirmSubProjectCloseClass('animate__animated animate__backOutDown');
    }
    const deleteSubProject = (id)=>{
        const data = {'id': id}
        axios.post(URLS.deleteProject, data, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
        .then((response)=>{
            if(response.data.success){
                Notify('success', 'Suppression de sous projet', 'sous projet supprimé avec succès !');
                setRefresh(refresh+1);
            }else{
                Notify('error', 'Erreur de suppression', 'Veuillez contacter le support développeur');
            }
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', 'Erreur de suppression', 'Veuillez contacter le support développeur');
        })
    }

    const saveSubProject = ()=>{
        subProjectParentValidation();
        subProjectNameValidation();
        subProjectDescriptionValidation();
        subProjectEndDateValidation();
        subProjectBudgetValidation();
        subProjectFinancialGoalValidation();
        if(!subProjectParentValidation() && !subProjectNameValidation() && !subProjectDescriptionValidation() && !subProjectEndDateValidation() && !subProjectBudgetValidation() && !subProjectFinancialGoalValidation()){
            setCreateSubProjectLoader(true);
            const data = {
                'label': subProjectNameValue,
                'description': subProjectDescriptionValue,
                'deadline': subProjectEndDateValue,
                'budget': subProjectBudgetValue,
                'parent': subProjectParentValue,
                'financialGoal': subProjectFinancialGoalValue,
            }

            axios.post(URLS.createSubProject, data, {
                headers:{
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            .then((response)=>{
                if(response.data.success){
                    Notify('success', 'Enregistrement de sous projet', response.data.success);
                    closeCreateSubProjectModalFunction();
                    setSubProjectBudgetValue('');
                    setSubProjectDescriptionValue('');
                    setSubProjectEndDateValue('');
                    setSubProjectNameValue('');
                    setSubProjectParentValue('')
                    setRefresh(refresh+1)
                }else if(response.data.exist){
                    Notify('error', "Erreur d'enregistrement de sous projet", response.data.exist);
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', "Erreur d'enregistrement de sous projet", "Veuillez contacter le support développeur");
            })
            setCreateSubProjectLoader(false);

        }
    }

    const handleState = (id)=>{
        const data = {
            'id': id,
        }
        axios.post(URLS.projectState, data, {
            headers:{
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((response)=>{
            if(response.data.success){
                Notify('success', 'État de sous projet', 'État modifié avec succès !');
                setRefresh(refresh+1);
            }else{
                Notify('error', "Erreur de changement d'état", 'Veuillez contacter le support développeur !')
            }
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', "Erreur de changement d'état veuillez contacter le support développeur", 'Veuillez contacter le support développeur !')
        })
    }
    return (
        <>
            <Toaster position='top-right'/>
            <BreadCrumb>
                <div className='flex items-center h-full'>
                    <div className='text-md font-bold bg-yellow-500/[.7] text-white p-1 rounded-md px-3'>
                        Gestion de Projet
                    </div>
                </div>
            </BreadCrumb>
            <div className='mt-5 p-2'>
                <div className='text-4xl text-black font-extrabold'>
                    Projets
                </div>

                <div className='grid grid-cols-3 gap-4 mt-5'>
                    {(userRoleLabel === 'développeur' || userRoleLabel === 'administrateur') && (
                        <div className='h-36 bg-white drop-shadow-2xl w-full rounded-3xl right-0 p-3'>
                            <div className='h-full w-full rounded-2xl bg-orange-400/[.4] hover:bg-maya-orange/[.7] hover:text-white hover:cursor-pointer duration-300 grid place-items-center' onClick={()=>openCreateProjectModalFunction()}>
                                <div className='text-lg'>
                                    <i className="bi bi-plus-circle-dotted"></i> Projet
                                </div>
                            </div>
                        </div>
                    )}

                    {projects && projects.map((project, index)=>{
                        return (
                            <div key={index} className='h-36 bg-white drop-shadow-2xl w-full rounded-3xl right-0 p-3'>
                                <div className='flex justify-between items-center p-1'>
                                    <div>
                                        <div className='select-none font-bold text-lg'>{project.label}</div>
                                        <div className='text-xs font-bold text-red-500 select-none'>
                                            <span className='mr-2 text-gray-600 font-thin'>Deadline </span>
                                            {moment(project.deadline).format('DD - MM - YYYY')}
                                        </div>
                                    </div>
                                    <div className=''>
                                        <i className="bi bi-chevron-bar-right text-xl cursor-pointer hover:text-2xl duration-300"></i>
                                    </div>
                                </div>
                                <div className='flex justify-between items-center mt-10'>
                                    <div className='p-1 truncate w-3/4 select-none'>
                                        {project.description}
                                    </div>
                                    <button className='mr-2'onClick={()=>{openDeleteSubProjectConfirmModal(project.id)}}>
                                        <i className="bi bi-trash hover:text-red-500"></i>
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            {
                projects.length > 0 && (
                    <div className='mt-5 p-2'>
                        <div className='text-4xl text-black font-extrabold'>
                            Sous Projets
                        </div>

                        <div className='grid grid-cols-3 gap-4 mt-5'>

                            {(userRoleLabel === 'développeur' || userRoleLabel === 'administrateur') && (
                                <div className='h-36 bg-white drop-shadow-2xl w-full rounded-3xl right-0 p-3'>
                                    <div className='h-full w-full rounded-2xl bg-cyan-400/[.4] hover:bg-cyan-500/[.7] hover:text-white hover:cursor-pointer duration-300 grid place-items-center' onClick={()=>openCreateSubProjectModalFunction()}>
                                        <div className='text-lg'>
                                            <i className="bi bi-plus-circle-dotted"></i> Sous Projet
                                        </div>
                                    </div>
                                </div>
                            )}

                            {subProjects && subProjects.map((subProject, index)=>{
                                return (
                                    <div key={index} className={`h-36 ${subProject.state === 0 ? 'bg-gray-100' : 'bg-white'} drop-shadow-2xl w-full rounded-3xl right-0 p-3`}>
                                        <div className='flex justify-between items-center p-1'>
                                            <div>
                                                <div className={`font-bold text-lg truncate max-w-[10em] select-none cursor-pointer  ${subProject.state === 0 && 'pointer-events-none'}`} onClick={()=>navigate('/clients/'+subProject.id)}> <i className="bi bi-diagram-2-fill text-blue-500 pr-2"></i> {subProject.label}</div>
                                                <div className='text-xs font-bold text-red-500 mt-1 select-none'>
                                                    <span className='mr-2 text-gray-600 font-thin'>Deadline </span>
                                                    {moment(subProject.deadline).format('DD - MM - YYYY')}
                                                </div>
                                            </div>
                                            {/* <div className=''>
                                                <i className="bi bi-patch-plus text-lg cursor-pointer text-green-400 hover:text-green-600  duration-300"></i>
                                            </div> */}
                                            <div className='border-green-600 select-none font-bold text-green-600 text-sm px-2 border border-2 rounded-full'>
                                                {moment(subProject.deadline).diff(moment(), 'days')} jour ( s )
                                            </div>
                                        </div>
                                        <div className='mt-8 p-1 truncate flex items-center justify-between'>
                                            <div className=' w-3/5'>
                                                <div className='font-thin text-xs select-none'>
                                                    {(subProject.goalState*100)/subProject.financial_goal +'%'}
                                                </div>
                                                <div className='w-100 bg-gray-200 h-2 rounded'>
                                                    <div className={`text-center h-full bg-green-400 rounded`} style={{'width': (subProject.goalState*100)/subProject.financial_goal>100 ? 100+'%' : (subProject.goalState*100)/subProject.financial_goal +'%'}}>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='text-sm'>
                                                {
                                                    subProject.state === 1 ? (

                                                        <button className='hover:text-white px-2 py-1 rounded-xl hover:bg-orange-400' onClick={()=>{handleState(subProject.id)}}>Suspendre</button>
                                                    ) :(
                                                        <button className='hover:text-white px-2 py-1 rounded-xl hover:bg-green-400' onClick={()=>{handleState(subProject.id)}}>Reprendre</button>
                                                    )
                                                }
                                                <button className='ml-1' onClick={()=>{openDeleteSubProjectConfirmModal(subProject.id)}}>
                                                    <i className="bi bi-trash hover:text-red-500"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            }

            <ModalOne title="Nouveau projet" color="green" open={openCreateProjectModal} onClose={closeCreateProjectModalFunction} closeClass={createProjectModalCloseClass}>
                <div className='mt-6 border border-green-200 p-4 rounded-lg'>
                    <div>
                        <div className='text-lg font-bold required'>
                            Nom du projet
                        </div>
                        <div className='mt-2'>
                            <input type="text" value={projectNameValue} className='border w-full py-2 rounded-xl border-gray-300 px-2 focus:outline-none focus:border-green-500 text-lg' onInput={(e)=>{saveProjectNameValue(e)}}/>
                            <small className={`${projectNameError ? 'text-red-500' : 'hidden'}`}>Vous devez renseigner le nom du projet</small>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className='text-lg font-bold required'>
                            Description
                        </div>
                        <div className='mt-2'>
                            <textarea name="" value={projectDescriptionValue} id="" cols="30" rows="6" className='w-full border border-2 rounded-xl border-gray-200 focus:outline-none p-2 focus:border-green-500' onInput={(e)=>{saveProjectDescriptionValue(e)}}></textarea>
                            <small className={`${projectDescriptionError ? 'text-red-500' : 'hidden'}`}>Vous devez renseigner la description du projet</small>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className='text-lg font-bold required'>
                            Date buttoire
                        </div>
                        <div className='mt-2'>
                            <input type="date" value={projectEndDateValue} className='border w-full py-2 rounded-xl border-gray-300 px-2 focus:outline-none focus:border-green-500 text-lg' onChange={(e)=>saveProjectEndDateValue(e)}/>
                            <small className={`${projectEndDateError ? 'text-red-500' : 'hidden'}`}>Vous devez renseigner la date de fin du projet</small>
                        </div>
                    </div>

                    <div className='mt-4'>
                        <div className='text-lg font-bold required'>
                            Objectif financier
                        </div>
                        <div className='mt-2'>
                            <input type="text" value={projectBudgetValue} className='border w-full py-2 rounded-xl border-gray-300 px-2 focus:outline-none focus:border-green-500 text-lg' onInput={(e)=>{saveProjectBudgetValue(e)}}/>
                            <small className={`${projectBudgetError ? 'text-red-500' : 'hidden'}`}>Vous devez renseigner l'objectif financier à atteindre</small>
                        </div>
                    </div>

                    <div className='mt-5 text-center'>
                        <SaveButton text="Enregistrer" className="py-2 px-4 bg-green-400 text-white text-lg rounded-xl hover:bg-green-500 duration-300" save={saveProject} loader={createProjectLoader}/>
                    </div>
                </div>
            </ModalOne>

            <ModalOne title="Nouveau sous projet" color="green" open={openCreateSubProjectModal} onClose={closeCreateSubProjectModalFunction} closeClass={createSubProjectModalCloseClass}>
                <div className='mt-6 border border-green-200 p-4 rounded-lg'>
                    <div>
                        <div className='text-lg font-bold required'>
                            Projet parent
                        </div>
                        <div className='mt-2'>
                            <select name="" id="" className='border w-full py-2 rounded-xl border-gray-300 px-2' onChange={(e)=>{saveSubProjectParentValue(e)}}>
                                <option value="">Sélectionner le projet parent</option>
                                {
                                    projects.map((project, index)=>{
                                        return (
                                            <option key={index} value={project.id}>{project.label}</option>
                                        )
                                    })
                                }
                            </select>
                            <small className={`${subProjectParentError ? 'text-red-500' : 'hidden'}`}>Vous devez sélectionner le projet parent</small>
                            {/* <small className={`${subProjectNameError ? 'text-red-500' : 'hidden'}`}>Vous devez renseigner le nom du sous projet</small> */}
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className='text-lg font-bold required'>
                            Nom du sous projet
                        </div>
                        <div className='mt-2'>
                            <input type="text" value={subProjectNameValue} className='border w-full py-2 rounded-xl border-gray-300 px-2 focus:outline-none focus:border-green-500 text-lg' onInput={(e)=>{saveSubProjectNameValue(e)}}/>
                            <small className={`${subProjectNameError ? 'text-red-500' : 'hidden'}`}>Vous devez renseigner le nom du sous projet</small>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className='text-lg font-bold required'>
                            Description
                        </div>
                        <div className='mt-2'>
                            <textarea name="" value={subProjectDescriptionValue} id="" cols="30" rows="6" className='w-full border border-2 rounded-xl border-gray-200 focus:outline-none p-2 focus:border-green-500' onInput={(e)=>{savesubProjectDescriptionValue(e)}}></textarea>
                            <small className={`${subProjectDescriptionError ? 'text-red-500' : 'hidden'}`}>Vous devez renseigner la description du sous projet</small>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className='text-lg font-bold required'>
                            Date buttoire
                        </div>
                        <div className='mt-2'>
                            <input type="date" value={subProjectEndDateValue} className='border w-full py-2 rounded-xl border-gray-300 px-2 focus:outline-none focus:border-green-500 text-lg' onChange={(e)=>saveSubProjectEndDateValue(e)}/>
                            <small className={`${subProjectEndDateError ? 'text-red-500' : 'hidden'}`}>Vous devez renseigner la date de fin du sous projet</small>
                        </div>
                    </div>

                    <div className='mt-4'>
                        <div className='text-lg font-bold required'>
                            Prix unitaire
                        </div>
                        <div className='mt-2'>
                            <input type="text" value={subProjectBudgetValue} className='border w-full py-2 rounded-xl border-gray-300 px-2 focus:outline-none focus:border-green-500 text-lg' onInput={(e)=>{saveSubProjectBudgetValue(e)}}/>
                            <small className={`${subProjectBudgetError ? 'text-red-500' : 'hidden'}`}>Vous devez renseigner le prix unitaire</small>
                        </div>
                    </div>

                    <div className='mt-4'>
                        <div className='text-lg font-bold required'>
                            Objectif financier
                        </div>
                        <div className='mt-2'>
                            <input type="text" value={subProjectFinancialGoalValue} className='border w-full py-2 rounded-xl border-gray-300 px-2 focus:outline-none focus:border-green-500 text-lg' onInput={(e)=>{saveSubProjectFinancialGoalValue(e)}}/>
                            <small className={`${subProjectFinancialGoalError ? 'text-red-500' : 'hidden'}`}>Vous devez renseigner l'objectif fincancier à atteindre</small>
                        </div>
                    </div>

                    <div className='mt-5 text-center'>
                        <SaveButton text="Enregistrer" className="py-2 px-4 bg-green-400 text-white text-lg rounded-xl hover:bg-green-500 duration-300" save={saveSubProject} loader={createSubProjectLoader}/>
                    </div>
                </div>
            </ModalOne>
            <ConfirmModal open={deleteSubProjectModalOpen} onClose={closeDeleteSubProjecctConfirmModal} closeClass={confirmSubProjectCloseClass} action={deleteSubProject} id={actionId}></ConfirmModal>
        </>
    )
}
