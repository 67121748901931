import React, {useState, useEffect} from 'react'
import BreadCrumb from '../../components/breadcrumb/BreadCrumb'
import CreateButton from '../../components/buttons/CreateButton'
import ModalOne from '../../components/modals/ModalOne'
import SaveButton from '../../components/buttons/SaveButton'
import ErrorBadge from '../../components/badges/ErrorBadge'
import  { Toaster } from 'react-hot-toast';
import Notify from '../../components/toast/ToastMessage';
import URLS from '../../components/API'
import axios from 'axios'
import moment from 'moment/moment'
import ConfirmModal from '../../components/modals/ConfirmModal'
import { useNavigate } from "react-router-dom";


export default function Permissions() {
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [closeClass, setCloseClass] = useState('opacity-0');
    //delete
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [actionId, setActionId] = useState(false);
    const [confirmCloseClass, setConfirmCloseClass] = useState('opacity-0');
    //delete
    //update
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateId, setUpdateId] = useState(false);


    const [refresh, setRefresh] = useState(0);
    const openModal = ()=>{
        setModalOpen(true);
    }
    const modalClose = ()=>{
        setModalOpen(false);
        setCloseClass('animate__animated animate__fadeOutRight');
    }

    const openConfirmModal = (id)=>{
        setDeleteModalOpen(true);
        setActionId(id);
    }
    const closeConfirmModal = ()=>{
        setDeleteModalOpen(false);
        setConfirmCloseClass('animate__animated animate__backOutDown');
    }

    const openUpdateModal = (id)=>{
        setUpdateModalOpen(true);
        setUpdateId(id);
        setUpdatePermissionValue(permissions.filter((value)=>value.id===id)[0].label);
    }

    const updateModalClose = ()=>{
        setUpdateModalOpen(false);
        setCloseClass('animate__animated animate__fadeOutRight');
    }


    const [saveLoader, setSaveLoader] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [permissionValue, setPermissionValue] = useState('');
    const [updatePermissionValue, setUpdatePermissionValue] = useState('');
    const [errorPermissionValue, setErrorPermissionValue] =  useState(false);
    const [errorUpdatePermissionValue, setErrorUpdatePermissionValue] =  useState(false);
    const [permissions, setPermissions] = useState([]);
    const savePermissionValue = (e)=>{
        setPermissionValue(e.target.value);
        setErrorPermissionValue(false);
    }
    const saveUpdatePemissionValue = (e)=>{
        setUpdatePermissionValue(e.target.value);
        setErrorUpdatePermissionValue(false);
    }
    useEffect(()=>{
        axios.get(URLS.permissions, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
        .then((response)=>{
            setPermissions(response.data.permissions);

        })
    }, [refresh])
    const save = ()=>{
        if(!permissionValue){
            setErrorPermissionValue(true);
        }else{
            setSaveLoader(true)
            const data = {
                'label': permissionValue,
            }
            axios.post(URLS.createPermission, data, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }, 
            })
            .then((response)=>{
                if(response.data.success){
                    modalClose();
                    Notify('success', 'Création de permissions', `${permissionValue} créé avec succès !`);
                    setPermissionValue('');
                    setRefresh(refresh+1);
                }else if(response.data.error){
                    Notify('warning', 'Erreur d\'enregistrmenet', response.data.error);
                }else{
                    Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
            })
            setSaveLoader(false);
        }
    }

    const update = ()=>{
        if(!updatePermissionValue){
            setErrorUpdatePermissionValue(true);
        }else{
            setUpdateLoader(true)
            const data = {
                'id': updateId,
                'label': updatePermissionValue,
            }
            axios.post(URLS.updatePermission, data, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }, 
            })
            .then((response)=>{
                if(response.data.success){
                    updateModalClose();
                    Notify('success', 'Modification de permissions', 'Permission modifiée avec succès !');
                    setUpdatePermissionValue('');
                    setRefresh(refresh+1);
                }else if(response.data.error){
                    Notify('warning', 'Erreur de modification', response.data.error);
                }else{
                    Notify('error', 'Erreur de modification', 'Veuillez contacter le support développeur');
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', 'Erreur de modification', 'Veuillez contacter le support développeur');
            })
            setUpdateLoader(false);
        }
    }

    const deletePermission = (id)=>{
        axios.post(URLS.deletePermission, {'id': id}, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
        .then((response)=>{
            if(response.data.success){
                Notify('success', 'Suppression de Permission', 'Permission supprimée avec succès !');
                setRefresh(refresh+1);
            }else{
                Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
            }
            
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
        })
    }
    return (
        <div>
            <BreadCrumb>
                <div className='flex items-center h-full'>
                    <div className='pr-2 border-r border-r-1 border-gray-500'>
                        <div className='text-md font-bold bg-red-500/[.7] text-white p-1 rounded-md px-3'>
                            Gestion des Permissions
                        </div>
                    </div>
                </div>
            </BreadCrumb>
            <Toaster position='top-right'/>

            <div className='mt-4 text-right'>
                <CreateButton text={"Nouvelle Permission"} onClick={openModal}/>
            </div>

            <ModalOne open={modalOpen} onClose={modalClose} closeClass={closeClass} title="Création d'une nouvelle permission" color="green">
                <div className='border rounded-lg h-5/6 mt-10 border-green-300 p-10'>
                    <div className=''>
                        <div className='text-center font-bold text-2xl'>Nom de la permission <i className="bi bi-key-fill text-green-500"></i></div>
                        <div className='mt-2 text-center'>
                            <input type="text" className='border w-3/4 focus:border-green-300 focus:w-full duration-300 h-10 rounded focus:outline-none border-gray-300 px-4 text-center' value={permissionValue} onInput={(e)=>{savePermissionValue(e)}} />
                        </div>
                        <div className='h-full text-center mt-10'>
                            <SaveButton text="Enregistrer" className="bg-green-500 hover:bg-green-600 duration-300 p-2 px-4 text-white font-bold text-lg hover:text-xl rounded-xl" save={save} loader={saveLoader}/>
                        </div>
                        {errorPermissionValue && (
                            <div className='mt-10'>
                                <ErrorBadge message={"Vous devez saisir le nom de la permission"}/>
                            </div>
                        )}
                    </div>
                </div>
            </ModalOne>
            <ConfirmModal open={deleteModalOpen} onClose={closeConfirmModal} closeClass={confirmCloseClass} action={deletePermission} id={actionId}/>
            {/* update */}
            <ModalOne open={updateModalOpen} onClose={updateModalClose} closeClass={closeClass} title="Modification d'une permission" color="orange">
                <div className='border rounded-lg h-5/6 mt-10 border-orange-300 p-10'>
                    <div className=''>
                        <div className='text-center font-bold text-2xl'>Nom de la permission <i className="bi bi-key-fill text-orange-500"></i></div>
                        <div className='mt-2 text-center'>
                            <input type="text" className='border w-3/4 focus:border-orange-300 focus:w-full duration-300 h-10 rounded focus:outline-none border-gray-300 px-4 text-center' value={updatePermissionValue} onInput={(e)=>{saveUpdatePemissionValue(e)}} />
                        </div>
                        <div className='h-full text-center mt-10'>
                            <SaveButton text="Enregistrer" className="bg-orange-500 hover:bg-orange-600 duration-300 p-2 px-4 text-white font-bold text-lg hover:text-xl rounded-xl" save={update} loader={updateLoader}/>
                        </div>
                        {errorUpdatePermissionValue && (
                            <div className='mt-10'>
                                <ErrorBadge message={"Vous devez saisir le nom de la permission"}/>
                            </div>
                        )}
                    </div>
                </div>
            </ModalOne>
            <div className='mt-4'>
                <div className='bg-white w-full p-4 rounded-xl'>
                    <table className='w-full'>
                        <thead className='text-white rounded-xl'>
                            <tr className='py-10 rounded-xl'>
                                <th scope='col' className=' bg-red-500/[.7] py-2 rounded-l-xl'>Libellé</th>
                                <th scope='col' className='bg-red-500/[.7]'>Date de création</th>
                                <th scope='col' className='bg-red-500/[.7] py-2 rounded-r-xl'>Actions</th>
                            </tr>
                        </thead>
                        <tbody className=''>
                            {
                               permissions && permissions.map((value, index)=>{
                                    return (
                                        <tr key={index} className={`${index%2!==0 && 'bg-gray-100'}`}>
                                            <td className='text-center my-1 py-2 rounded-l-2xl'>{value.label}</td>
                                            <td className='text-center my-1 py-2'>{moment(value.created_at).format('DD MMM YYYY à hh:mm')}</td>
                                            <td className='flex justify-center gap-6 my-1 py-2'>
                                                <i className="bi bi-pen cursor-pointer hover:bg-green-200 px-2 py-1 rounded-full text-green-600 duration-300" onClick={()=>{openUpdateModal(value.id)}}></i>
                                                <i className="bi bi-trash cursor-pointer hover:bg-red-200 px-2 py-1 rounded-full text-red-600 duration-300" onClick={()=>{openConfirmModal(value.id)}}></i>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
