import React from 'react'
import 'animate.css';

export default function ConfirmModal(props) {
    const action = ()=>{
        if(props.id){
            props.action(props.id)
        }else{
            props.action()
        }
        props.onClose();
    } 
  return (
    <div className={`z-10 fixed inset-0 ${props.open ? '' : 'pointer-events-none'}`}>
        <div className={`fixed bg-black/[.5] inset-0  grid place-items-center ${props.open ? 'opacity-100' : 'pointer-events-none animate__animated animate__fadeOut opacity-0'}`}>
            <div className={`fixed bg-white h-1/3 w-1/3 shadow-lg rounded-xl p-3 border-red-600 border border-2 ${props.open ? '' : props.closeClass}`}>
                <div>
                    <div className='text-right text-red-500'>
                        <i className="bi bi-x-circle text-xl hover:text-2xl duration-300 cursor-pointer" onClick={()=>{props.onClose()}}></i>
                    </div>
                    <div className='text-center text-2xl font-bold'>
                        {props.children ? (
                            props.children
                        ) : (
                            <span>Êtes-vous sûr de vouloir <br /> exécuter cette action ?</span>
                        )}
                    </div>
                    <div className='text-center mt-4 border-b pb-4'>
                        <i className="bi bi-exclamation-diamond-fill text-5xl text-red-500"></i>
                    </div>
                    <div className='text-right space-x-6 mr-2 mt-2'>
                        <button className='bg-red-400 hover:bg-red-500 duration-300 text-white p-2 rounded-xl px-3' onClick={()=>{props.onClose()}}>Annuler</button>
                        <button className='bg-green-500 hover:bg-green-600 duration-300 text-white p-2 rounded-xl px-3' onClick={()=>{action()}}>Confirmer</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
