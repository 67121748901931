import React, { useRef } from 'react'
import { jsPDF } from 'jspdf';
import etudesci from '../../etudesci.png'
import URLS from '../API';
import axios from 'axios';
import Notify from '../toast/ToastMessage';
import { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export default function InvoicePDF(props) {
    const navigate = useNavigate();
    const pdfRef = useRef(null);
    const handleDownload = () => {
        const content = pdfRef.current;

        const doc = new jsPDF("portrait", 'mm', 'a0');
        save();
        doc.html(content, {
            callback: function (doc) {
                doc.save(props.references+'_'+props.client.last_name+'_'+props.client.first_name+'.pdf');
            }
        });
    };

    const save = ()=>{
        const data  = {
            'references': props.references,
            'discount': props.discount,
            'total': props.ttc,
            'client': props.client.id,
            'remaining': props.rest,
            'project': props.projectId,
            'subProjects': props.products.map((value)=>props.subProjects[value.project]),
            'products': props.products,
            'account': props.account.id,
            'amount': props.amountPaid,
            'city': props.city
        }

        axios.post(URLS.createInvoices, data, {
            headers:{
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((response)=>{
            if(response.data.success){
                Notify('success', 'Enregistrement de facture', response.data.success);
                props.setSaved(true);
            }else if(response.data.exist){
                Notify('warning', 'Enregistrement de facture', response.data.exist);
            }else{
                Notify('error', 'Une erreur est survenue veuillez contacter le support développeur');
            }
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', "Erreur d'enregistrement de projet", "Veuillez contacter le support développeur");
        })

    }
    return (
        <>
            <Toaster position='top-right'/>
            <div className='mt-4 p-5 grid grid-cols-4 gap-4 items-start'>
                <div className='col-span-3 grid grid-cols-2 bg-white p-5 rounded-x align-items-center' ref={pdfRef}>
                    <div className='flex items-start'>
                        <img src={props.logo ? props.logo.previewLink : etudesci} alt="" className='h-28'/>
                    </div>
                    <div className='text-right'>

                        <div className='text-2xl font-bold text-sky-700'>

                            Reçu de paiement

                        </div>

                        <div className='mt-10 text-xl'>
                            <div className=''>

                                Date: <span className='font-bold pl-2'>{props.date}</span>
                            </div>
                            <div>
                                Reçu Nº  <span className='font-bold uppercase pl-2'>{props.references}</span>
                            </div>

                        </div>

                        <div className='mt-10'>

                            <div className='text-md font-bold'>

                                Contact Facturation:

                            </div>

                            <div className='border uppercase p-1 text-left mt-2'>

                                <span className='font-bold'>Client: </span><span className='pl-2'>{props.client.last_name} {props.client.first_name} <br /> {props.city}</span>

                            </div>

                        </div>

                    </div>

                    <div className='mt-20 col-span-3'>

                        <table className='border w-full'>

                            <thead className='uppercase bg-sky-700 text-white'>

                                <tr>

                                    <td className='p-2 border w-1/2'>Désignation</td>

                                    <td className='p-2 border text-center'>Quantité</td>

                                    <td className='p-2 border text-center'>Prix Unitaire</td>

                                    <td className='p-2 border text-center w-1/4'>Total</td>

                                </tr>

                            </thead>

                            <tbody className=''>

                                <tr>

                                    <td className='py-4 p-2 font-bold text-lg border'>{props.project}</td>

                                    <td className="border"></td>

                                    <td className="border"></td>

                                    <td className="border"></td>

                                </tr>

                                {props.subProjects && props.products && props.products.map((product, index)=>{
                                    return (
                                        <tr>
                                            <td className='py-4 p-2 font-bold text-lg border'>{props.subProjects[product.project] && props.subProjects[product.project].label}</td>

                                            <td className='text-center border'>{product.quantity}</td>

                                            <td className='text-center border'>{product.budget.toLocaleString('en-US')} F</td>

                                            <td className='text-center border'>{(product.quantity * product.budget).toLocaleString('en-US')} F</td>

                                        </tr>
                                    )
                                })}

                                

                                <tr>

                                    <td colspan="3" className='border py-2 p-2 '>Remise</td>

                                    <td className='border text-center '>{props.discount.toLocaleString('en-US')} F</td>

                                </tr>

                                <tr>

                                    <td colspan="3" className='border py-2 p-2 '>Total TTC</td>

                                    <td className='border text-center '>{props.ttc.toLocaleString('en-US')} F</td>

                                </tr>

                                <tr>

                                    <td colspan="3" className='border py-2 p-2 '>Montant versé</td>

                                    <td className='border text-center '>{props.amountPaid.toLocaleString('en-US')} F</td>

                                </tr>

                                <tr>

                                    <td colspan="3" className='border py-2 p-2 '>Reste à payer</td>

                                    <td className='border text-center '>{props.rest.toLocaleString('en-US')} F</td>

                                </tr>



                            </tbody>

                        </table>

                        <div className='mt-5'>

                            Mode de paiement : <span className='font-bold'>{props.account.accounttype.label} -  {props.account.label}</span>

                        </div>

                        <div className='mt-5'>

                            NB : Le montant versé n’est pas remboursable

                        </div>

                        <hr  className='mt-20 mb-5 mt-64'/>

                        <div className='text-xs text-center font-bold bottom-0'>

                        Cocody, Angré CNPS, Cité Star 12 - 19 BP 1100 ABIDJAN 19 * Contact : 225 05 96 67 49 67 / 07 00 77 33 04 / 22 24 30 97 80 

                            *RCCM : CI-ABJ-03-2022-B12-02297 – etudes@etudes.ci – www.etudes.ci

                        </div>

                    </div>

                </div>
                <div className='p-5 bg-white rounded-xl text-center space-y-5 top-5 sticky'>
                        {!props.saved &&
                            <div>
                            <button className='w-full py-2 rounded-xl text-sky-700 bg-sky-700/[.1] hover:bg-sky-700 hover:text-white text-lg font-bold duration-300' onClick={()=>save()}>
                                Enregistrer
                            </button>
                         </div>
                        }
                        <div>
                            <button className='w-full py-2 rounded-xl text-violet-600 bg-violet-600/[.1] hover:bg-violet-600 hover:text-white text-lg font-bold duration-300' onClick={()=>props.setPreviewToggle(false)}>
                                Modifier
                            </button>
                        </div>
                        <div>
                            <button className='w-full py-2 rounded-xl text-green-600 bg-green-600/[.1] hover:bg-green-600 hover:text-white text-lg font-bold duration-300' onClick={()=>handleDownload()}>
                                Exporter
                            </button>
                        </div>
                    </div>
            </div>
        </>
    )
}
