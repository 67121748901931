import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import URLS from '../components/API';
import BreadCrumb from '../components/breadcrumb/BreadCrumb';
import Notify from '../components/toast/ToastMessage';
import { Toaster } from 'react-hot-toast';
import validator from 'validator';
import moment from 'moment/moment';
import ConfirmModal from '../components/modals/ConfirmModal';

export default function Clients() {
    const navigate = useNavigate('/');
    const {id} = useParams();
    const [refresh, setRefresh] = useState(0);
    const [subProject, setSubProject] = useState();
    const [addToggle, setAddToggle] = useState(false);
    const [clients, setClients] = useState([]);
    const [saveClients, setSaveClients] = useState([]);
    useEffect(() => {
        if(id){
            axios.post(URLS.getProject, {id: id}, {
                headers:{
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            .then((response)=>{
                if(response.data.project){
                    setSubProject(response.data.project);
                }else{
                    Notify('error', 'Une erreur est survenue vueillez contacter le support développeur');
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', "Erreur d'enregistrement de projet", "Veuillez contacter le support développeur");
            })

            axios.post(URLS.clients, { 'project': id}, {
                headers:{
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            .then((response)=>{
                if(response.data.clients){
                    setClients(response.data.clients);
                    setSaveClients(response.data.clients);
                }else{
                    Notify('error', 'Une erreur est survenue vueillez contacter le support développeur');
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', "Erreur d'enregistrement de projet", "Veuillez contacter le support développeur");
            })
        }
    }, [id, refresh])

    // add client 
    const [firstName, setfirstName] = useState('')
    const savefirstName = (e)=>{
        setfirstName(e.target.value);
    }
    const [firstNameError, setfirstNameError] = useState(false)
    const firstNameValidation = ()=>{
        if (!firstName) {
            setfirstNameError(true);
            return true;
        }else{
            setfirstNameError(false);
            return false;
        }
    }

    const [lastName, setLastName] = useState('')
    const saveLastName = (e)=>{
        setLastName(e.target.value);
    }
    const [lastNameError, setlastNameError] = useState(false)
    const lastNameValidation = ()=>{
        if (!lastName) {
            setlastNameError(true);
            return true;
        }else{
            setlastNameError(false);
            return false;
        }
    }

    const [email, setEmail] = useState('')
    const saveEmail = (e)=>{
        setEmail(e.target.value);
    }
    const [emailError, setEmailError] = useState(false)
    const emailValidation = ()=>{
        if (!email || !validator.isEmail(email)) {
            setEmailError(true);
            return true;
        }else{
            setEmailError(false);
            return false;
        }
    }

    const [contact, setContact] = useState('')
    const saveContact = (e)=>{
        setContact(e.target.value);
    }
    const [contactError, setContactError] = useState(false)
    const contactValidation = ()=>{
        if (!contact || !validator.isNumeric(contact)) {
            setContactError(true);
            return true;
        }else{
            setContactError(false);
            return false;
        }
    }
    
    const save = ()=>{
        firstNameValidation();
        lastNameValidation();
        emailValidation();
        contactValidation();

        if(!firstNameValidation() && !lastNameValidation() && !emailValidation() && !contactValidation()){
            const data = {
                'firstName': firstName,
                'lastName': lastName,
                'email': email,
                'contact': contact,
                'project': id,
            }

            axios.post(URLS.createClient, data, {
                headers:{
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            .then((response)=>{
                if(response.data.success){
                    Notify('success', 'Création d\'un client', response.data.success);
                    handleAddToggle();
                    setLastName('');
                    setfirstName('');
                    setEmail('');
                    setContact('');
                    setRefresh(refresh+1)
                }else if(response.data.roleNotExist){
                    Notify('error', 'Erreur  d\'enregistrement', response.data.roleNotExist);
                }else if(response.data.exist){
                    Notify('error', 'Erreur  d\'enregistrement', response.data.exist);
                }else{
                    Notify('error', 'Une erreur est survenue veuillez contacter le support développeur');
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', "Erreur de modification de client", "Veuillez contacter le support développeur");
            })

        }
    }

    const handleAddToggle = ()=>{
        setAddToggle(!addToggle);
        setfirstNameError(false);
        setlastNameError(false);
        setContactError(false);
        setEmailError(false);
    }

    //update
    const [updateFirstName, setUpdateFirstName] = useState();
    const [updateFirstNameError, setUpdateFirstNameError] = useState(false)
    const saveUpdateFirstName = (e)=>{
        setUpdateFirstName(e.target.value);
    }
    const updateFirstNameValidation = ()=>{
        if (!updateFirstName) {
            setUpdateFirstNameError(true);
            return true;
        }else{
            setUpdateFirstNameError(false);
            return false;
        }
    }
    const [updateLastName, setUpdateLastName] = useState();
    const [updateLastNameError, setUpdateLastNameError] = useState(false)
    const saveUpdateLastName = (e)=>{
        setUpdateLastName(e.target.value);
    }
    const updateLastNameValidation = ()=>{
        if (!updateLastName) {
            setUpdateLastNameError(true);
            return true;
        }else{
            setUpdateLastNameError(false);
            return false;
        }
    }
    const [updateContact, setUpdateContact] = useState();
    const [updateContactError, setUpdateContactError] = useState(false)
    const saveUpdateContact = (e)=>{
        setUpdateContact(e.target.value);
    }
    const updateContactValidation = ()=>{
        if (!updateContact || !validator.isNumeric(updateContact)) {
            setUpdateContactError(true);
            return true;
        }else{
            setUpdateContactError(false);
            return false;
        }
    }
    const [updateEmail, setUpdateEmail] = useState();
    const [updateEmailError, setUpdateEmailError] = useState(false)
    const saveUpdateEmail = (e)=>{
        setUpdateEmail(e.target.value);
    }
    const updateEmailValidation = ()=>{
        if (!updateEmail || !validator.isEmail(updateEmail)) {
            setUpdateEmailError(true);
            return true;
        }else{
            setUpdateEmailError(false);
            return false;
        }
    }

    const [updateIndex, setUpdateIndex] = useState();
    const edit = (index)=>{
        setUpdateIndex(index);
        setUpdateFirstName(clients[index].first_name);
        setUpdateLastName(clients[index].last_name);
        setUpdateContact(clients[index].contact);
        setUpdateEmail(clients[index].email);
    }
    const update = ()=>{
        updateFirstNameValidation();
        updateLastNameValidation();
        updateContactValidation();
        updateEmailValidation();
        if(!updateFirstNameValidation() && !updateLastNameValidation() && !updateContactValidation() && !updateEmailValidation()){
            const data = {
                'firstName': updateFirstName,
                'lastName': updateLastName,
                'email': updateEmail,
                'contact': updateContact,
                'project': id,
                'id': clients[updateIndex].id,
            }
            axios.post(URLS.updateClient, data, {
                headers:{
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            .then((response)=>{
                if(response.data.success){
                    Notify('success', 'Modification d\'un client', response.data.success);
                    setUpdateIndex(-1);
                    setRefresh(refresh+1)
                }else if(response.data.emailExist){
                    Notify('error', 'Modification d\'un client', response.data.emailExist);
                }else{
                    Notify('error', 'Une erreur est survenue veuillez contacter le support développeur');
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', "Erreur de modification de client", "Veuillez contacter le support développeur");
            })
        }
    }

    //delete
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [actionId, setActionId] = useState(false);
    const [confirmCloseClass, setConfirmCloseClass] = useState('opacity-0');
    const openConfirmModal = (id)=>{
        setDeleteModalOpen(true);
        setActionId(id);
    }
    const closeConfirmModal = ()=>{
        setDeleteModalOpen(false);
        setConfirmCloseClass('animate__animated animate__backOutDown');
    }
    const deleteClient = (client_id)=>{
        axios.post(URLS.deleteClient, {'id': client_id, 'project': id}, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
        .then((response)=>{
            if(response.data.success){
                Notify('success', 'Suppression de Client', 'Client supprimé avec succès !');
                setRefresh(refresh+1);
            }else{
                Notify('error', 'Erreur d\'enregistrement', 'Veuillez contacter le support développeur');
            }
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', 'Erreur d\'enregistrement', 'Veuillez contacter le support développeur');
        })
    }

    const search = (e)=>{
        if(e.target.value){
            axios.post(URLS.searchClient, {'subject': e.target.value, 'id': id}, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            .then((response)=>{
                if(response.data.results){
                    setClients(response.data.results);
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', 'Une erreur s\'est produite', 'Veuillez contacter le support développeur');
            })
        }else{
            setClients(saveClients);
        }
    }
    return (
        <>
            {subProject && (
                <div>
                    <Toaster position='top-right' />
                    <BreadCrumb>
                        <div className='flex items-center h-full'>
                            <div className='text-md font-bold bg-cyan-500/[.7] text-white p-1 rounded-md px-3'>
                                Clients
                            </div>
                        </div>
                    </BreadCrumb>
                    <div className='mt-4'>
                        <div className='flex items-center justify-between bg-white shadow-lg shadow-cyan-500/50 h-14 rounded-xl p-2'>
                            <div className='text-md font-bold bg-yellow-500/[.7] hover:bg-yellow-500 duration-300 text-white p-1 rounded-lg px-3 cursor-pointer' onClick={()=>navigate('/projects')}>
                                <i className="bi bi-arrow-left-circle-fill pr-4"></i>Projets
                            </div>
                            <div className=''>
                                <span className='font-bold text-lg ml-3 text-cyan-500'>{subProject.label}</span>
                            </div>
                            <div>
                                {!addToggle ? (
                                    <button className='px-3 py-2 text-green-400 font-bold bg-green-100 border border-green-400 rounded-lg hover:bg-green-400 hover:text-white hover:px-4 hover:py-3 duration-300' onClick={()=>handleAddToggle()}>Ajouter client</button>
                                ):(
                                    <div className='flex items-center gap-4'>
                                        <button className='px-3 py-2 text-green-400 font-bold bg-green-100 border border-green-400 rounded-lg hover:bg-green-400 hover:text-white hover:px-4 hover:py-3 duration-300' onClick={()=>save()}>Enregistrer</button>
                                        <button className='px-3 py-2 text-red-400 font-bold bg-red-100 border border-red-400 rounded-lg hover:bg-red-400 hover:text-white hover:px-4 hover:py-3 duration-300' onClick={()=>handleAddToggle()}>Annuler</button>
                                    </div>
                                )}
                            </div>
                        </div>
                        {addToggle &&
                            (
                                <div className={`mt-4 bg-white h-16 rounded-xl border border-cyan-500 shadow-lg shadow-cyan-500/50 flex justify-between-items-center p-3 gap-4`}>
                                    <input value={firstName} type="text" className={`border-2  ${firstNameError ? 'border-red-500' : 'border-gray-400 focus:border-cyan-500'} py-1 rounded-lg px-2  text-center focus:outline-none focus:text-cyan-500`} placeholder='Nom' onInput={(e)=>savefirstName(e)}/>
                                    <input value={lastName} type="text" className={`border-2  ${lastNameError ? 'border-red-500' : 'border-gray-400 focus:border-cyan-500'} py-1 rounded-lg px-2  text-center focus:outline-none focus:text-cyan-500`} placeholder='Prénom' onInput={(e)=>saveLastName(e)}/>
                                    <input value={email} type="email" className={`border-2  ${emailError ? 'border-red-500' : 'border-gray-400 focus:border-cyan-500'} py-1 rounded-lg px-2  text-center focus:outline-none focus:text-cyan-500`} placeholder='Email' onInput={(e)=>saveEmail(e)}/>
                                    <input value={contact} type="text" className={`border-2  ${contactError ? 'border-red-500' : 'border-gray-400 focus:border-cyan-500'} py-1 rounded-lg px-2  text-center focus:outline-none focus:text-cyan-500`} placeholder='Contact' onInput={(e)=>saveContact(e)}/>
                                </div>
                            ) 
                        }
                        <div className='h-14 bg-white rounded-xl my-4 w-1/2 p-2 shadow-lg shadow-cyan-500/50'>
                            <div className='border pl-1 h-full rounded-lg flex justify-between items-center'>
                                <input type="text" className='w-full h-full focus:outline-none px-2 text-cyan-500' onInput={(e)=>search(e)}/>
                                <i className="bi bi-search bg-cyan-500 text-white px-3 py-2 rounded-lg"></i>
                            </div>
                        </div>
                    </div>

                    <div className='mt-4'>
                        <div className="table w-full overflow-auto p-4 bg-white rounded-xl">
                            <div className="table-header-group rounded text-center">
                                <div className="table-row text-cyan-600">
                                    <div className="table-cell py-4 rounded-l-xl bg-cyan-500/[.2] px-4">#</div>
                                    <div className="table-cell py-4 bg-cyan-500/[.2]">Nom</div>
                                    <div className="table-cell py-4 bg-cyan-500/[.2]">Prénom</div>
                                    <div className="table-cell py-4 bg-cyan-500/[.2]">Email</div>
                                    <div className="table-cell py-4 bg-cyan-500/[.2]">Contact</div>
                                    <div className="table-cell py-4 bg-cyan-500/[.2]">Date de création</div>
                                    <div className="table-cell py-4 rounded-r-xl bg-cyan-500/[.2] px-4">Action</div>
                                </div>
                            </div>
                            <div className="table-row-group w-full text-center">
                                {
                                    clients && clients.map((client, index)=>{
                                        return (
                                            <div key={index} className={`table-row`}>
                                                <div className={`table-cell py-4 ${index%2 !==0 && 'bg-gray-100 rounded-l-xl'}`}>
                                                    {index+1}
                                                </div>
                                                <div className={`table-cell py-4 ${index%2 !==0 && 'bg-gray-100'}`}>
                                                    {updateIndex>-1 && clients[updateIndex].id === client.id ? (
                                                        <input value={updateLastName} type="text" className={`border-2 w-5/6  ${updateLastNameError ? 'border-red-500' : 'border-gray-400 focus:border-cyan-500'} py-1 rounded-lg px-2  text-center focus:outline-none focus:text-cyan-500`} placeholder='Nom' onInput={(e)=>saveUpdateLastName(e)}/>
                                                        ): (
                                                            client.last_name
                                                    )}
                                                </div>
                                                <div className={`table-cell py-4 ${index%2 !==0 && 'bg-gray-100'}`}>
                                                    {updateIndex>-1 && clients[updateIndex].id === client.id ? (
                                                        <input value={updateFirstName} type="text" className={`border-2 w-5/6  ${updateFirstNameError ? 'border-red-500' : 'border-gray-400 focus:border-cyan-500'} py-1 rounded-lg px-2  text-center focus:outline-none focus:text-cyan-500`} placeholder='Nom' onInput={(e)=>saveUpdateFirstName(e)}/>
                                                        ): (
                                                            client.first_name
                                                    )}
                                                </div>
                                                <div className={`table-cell py-4 ${index%2 !==0 && 'bg-gray-100'}`}>
                                                    {updateIndex>-1 && clients[updateIndex].id === client.id ? (
                                                        <input value={updateEmail} type="text" className={`border-2 w-5/6  ${updateEmailError ? 'border-red-500' : 'border-gray-400 focus:border-cyan-500'} py-1 rounded-lg px-2  text-center focus:outline-none focus:text-cyan-500`} placeholder='Nom' onInput={(e)=>saveUpdateEmail(e)}/>
                                                        ): (
                                                            client.email
                                                    )}
                                                </div>
                                                <div className={`table-cell py-4 ${index%2 !==0 && 'bg-gray-100'}`}>
                                                    {updateIndex>-1 && clients[updateIndex].id === client.id ? (
                                                        <input value={updateContact} type="text" className={`border-2 w-5/6  ${updateContactError ? 'border-red-500' : 'border-gray-400 focus:border-cyan-500'} py-1 rounded-lg px-2  text-center focus:outline-none focus:text-cyan-500`} placeholder='Nom' onInput={(e)=>saveUpdateContact(e)}/>
                                                        ): (
                                                            client.contact
                                                    )}
                                                </div>
                                                <div className={`table-cell py-4 ${index%2 !==0 && 'bg-gray-100'}`}>
                                                    {moment(client.created_at).format('DD MMM YYYY')}
                                                </div>
                                                <div className={`table-cell py-4 ${index%2 !==0 && 'bg-gray-100 rounded-r-xl'}`}>
                                                    {updateIndex>-1 && clients[updateIndex].id === client.id ? (
                                                        <div className='flex gap-2 items-center justify-center'>
                                                            <button className='px-2 bg-green-300 hover:bg-green-500 py-1 rounded-xl text-white duration-300' onClick={()=>update()}><i className="bi bi-check-circle-fill"></i></button>
                                                            <button className='px-2 bg-red-300 hover:bg-red-500 py-1 rounded-xl text-white duration-300' onClick={()=>setUpdateIndex(-1)}><i className="bi bi-x-circle-fill"></i></button>
                                                        </div>
                                                    ): (
                                                        <div className='flex gap-2 items-center justify-center'>
                                                            <button className='px-2 bg-orange-300 hover:bg-orange-500 py-1 rounded-xl text-white duration-300' onClick={()=>edit(index)}><i className="bi bi-pen-fill"></i></button>
                                                            <button className='px-2 bg-red-300 hover:bg-red-500 py-1 rounded-xl text-white duration-300' onClick={()=>openConfirmModal(client.id)}><i className="bi bi-trash-fill"></i></button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                )
            }
            <ConfirmModal open={deleteModalOpen} onClose={closeConfirmModal} closeClass={confirmCloseClass} action={deleteClient} id={actionId}/>
        </>
    )
}
