import React, {useState, useEffect} from 'react'
import URLS from '../components/API';
import BreadCrumb from '../components/breadcrumb/BreadCrumb'
import { useNavigate } from 'react-router-dom';
import Notify from '../components/toast/ToastMessage';
import axios from 'axios';
import moment from 'moment/moment';
import InvoiceModal from '../components/modals/InvoiceModal';
export default function InvoicesList() {
    const navigate = useNavigate();
    const [invoices, setInvoices] = useState([]);
    const [saveInvoices, setSaveInvoices] = useState([]);
    useEffect(()=>{
        axios.get(URLS.invoices, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((response)=>{
            if(response.data.invoices){
                setInvoices(response.data.invoices);
                setSaveInvoices(response.data.invoices);
            }
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', 'Une erreur s\'est produite', 'Veuillez contacter le support développeur');
        })
    }, []);

    const [openPreviewInvoiceModal, setOpenPreviewInvoiceModal] = useState(false);
    const [PreviewInvoiceModalCloseClass, setPreviewInvoiceModalCloseClass] = useState('opacity-0');
    const [invoiceToPreview, setInvoiceToPreview] = useState();
    const closePreviewInvoiceModalFunction = ()=>{
        setOpenPreviewInvoiceModal(false);
        setPreviewInvoiceModalCloseClass('animate__animated  animate__bounceOutDown')
    }
    const openPreviewInvoiceModalFunction = (invoice)=>{
        setOpenPreviewInvoiceModal(true);
        setInvoiceToPreview(invoice);
    }

    const search = (e)=>{
        if(e.target.value){
            axios.post(URLS.searchInvoices, {'subject': e.target.value}, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            .then((response)=>{
                if(response.data.results){
                    setInvoices(response.data.results);
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', 'Une erreur s\'est produite', 'Veuillez contacter le support développeur');
            })
        }else{
            setInvoices(saveInvoices);
        }
    }
    return (
        <>
            <div>
                <BreadCrumb>
                    <div className='flex items-center h-full'>
                        <div className='text-md font-bold bg-orange-700/[.7] text-white p-1 rounded-md px-3'>
                            Liste des factures
                        </div>
                    </div>
                </BreadCrumb>
            </div>

            <div className="sticky top-10">
                <div className='my-4 h-14 bg-white shadow-lg shadow-sky-500/50 rounded-xl p-2'>
                    <div className='mx-3 h-full flex items-center justify-between'>
                        <div className='border border-2 rounded-xl border-sky-200 flex items-center justify-between'>
                            <input type="text" className='bg-transparent h-full w-full focus:outline-none px-2 py-1' placeholder='Références ...' onInput={(e)=>search(e)}/>
                            <button className='px-2 bg-sky-500 py-1 rounded-xl pointer-events-none text-white'>
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='bg-white rounded-xl mt-4 p-5 py-7'>
                <div className='table w-full overflow-auto'>
                    <div className='table-header-group   rounded'>
                        <div className="table-row ">
                            <div className="text-sky-700 font-bold table-cell text-center py-4 rounded-l-xl bg-sky-100">Références</div>
                            <div className="text-sky-700 font-bold table-cell text-center py-4 bg-sky-100">Projet</div>
                            <div className="text-sky-700 font-bold table-cell text-center py-4 bg-sky-100">Montant Payé</div>
                            <div className="text-sky-700 font-bold table-cell text-center py-4 bg-sky-100">Total</div>
                            <div className="text-sky-700 font-bold table-cell text-center py-4 bg-sky-100">Montant restant</div>
                            <div className="text-sky-700 font-bold table-cell text-center py-4 bg-sky-100">Date de création</div>
                            <div className="text-sky-700 font-bold table-cell text-center py-4 bg-sky-100">Réduction</div>
                            <div className="text-sky-700 font-bold table-cell text-center py-4 bg-sky-100">Client</div>
                            <div className="text-sky-700 font-bold table-cell text-center py-4 bg-sky-100">Compte</div>
                            <div className="text-sky-700 font-bold table-cell text-center py-4 bg-sky-100 rounded-r-xl px-3">Actions</div>
                        </div>
                    </div>
                    <div className="table-row-group w-full">
                        {
                            invoices && invoices.map((invoice, index)=>{
                                return (
                                    <div key={index} className={`table-row`}>
                                        <div className={`${index%2 !== 0 && 'bg-sky-100 rounded-l-xl'} table-cell text-center my-1 py-6 uppercase`}>{invoice.references}</div>
                                        <div className={`${index%2 !== 0 && 'bg-sky-100'} table-cell text-center my-1 py-6`}>
                                            {invoice.projects[0].label}
                                        </div>
                                        <div className={`${index%2 !== 0 && 'bg-sky-100'} table-cell text-center my-1 py-6 truncate max-w-[7em] text-green-600`}>{(invoice.total - invoice.remaining).toLocaleString('en-US')} Fcfa</div>
                                        <div className={`${index%2 !== 0 && 'bg-sky-100'} table-cell text-center my-1 py-6`}>{invoice.total.toLocaleString('en-US')} Fcfa</div>
                                        <div className={`${index%2 !== 0 && 'bg-sky-100'} table-cell text-center my-1 py-6 text-red-600`}>{invoice.remaining.toLocaleString('en-US')} Fcfa</div>
                                        <div className={`${index%2 !== 0 && 'bg-sky-100'} table-cell text-center my-1 py-6`}>
                                            {moment(invoice.created_at).format('DD MMM YYYY à hh:mm')}
                                        </div>
                                        <div className={`${index%2 !== 0 && 'bg-sky-100'} table-cell text-center my-1 py-6`}>{invoice.discount.toLocaleString('en-US')} F</div>
                                        <div className={`${index%2 !== 0 && 'bg-sky-100'} table-cell text-center my-1 py-6 font-bold truncate max-w-[7em]`}>{invoice.client.first_name} {invoice.client.last_name}</div>
                                        <div className={`${index%2 !== 0 && 'bg-sky-100'} table-cell text-center my-1 py-6`}>{invoice.account && invoice.account.label}</div>
                                        <div className={`${index%2 !== 0 && 'bg-sky-100'} table-cell text-center my-1 py-6 rounded-r-xl space-x-2`}>
                                            <button className='px-2 bg-sky-300 hover:bg-sky-500 py-1 rounded-xl text-white duration-300' onClick={()=>{openPreviewInvoiceModalFunction(invoice)}}><i className="bi bi-eye-fill text-lg"></i></button>
                                            {/* <button className='px-2 bg-orange-300 hover:bg-orange-500 py-1 rounded-xl text-white duration-300'><i className="bi bi-pen-fill text-lg"></i></button>
                                            <button className='px-2 bg-red-300 hover:bg-red-500 py-1 rounded-xl text-white duration-300'><i className="bi bi-trash3-fill text-lg"></i></button> */}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <InvoiceModal open={openPreviewInvoiceModal} onClose={closePreviewInvoiceModalFunction} title="Création d'un nouveau compte utilisateur" color="orange" closeClass={PreviewInvoiceModalCloseClass} invoice={invoiceToPreview}/>
        </>
    )
}
