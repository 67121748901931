import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function DropDown(props) {
    // const [dropdownActive, setDropDownActive] = useState(false);
    const navigate = useNavigate();
    return (
        <>
            <div className='my-2'>
                <div className={`flex justify-between items-center p-3 rounded-xl hover:bg-maya-orange hover:cursor-pointer hover:text-white duration-300 ${window.location.href.indexOf(props.element.active)>-1 ? 'bg-maya-orange text-white' : ''}`} onClick={()=>{props.setDropDownActive(props.index, props.element.path); }}>
                    <div className={`font-bold`}> <i className={`${props.element.icon} text-xl mr-2`}></i> {props.element.name}</div>
                    {props.element.children && (
                        <div className={`duration-500 ${window.location.href.indexOf(props.element.active)>-1 ? ' rotate-90' : ''}`}>
                            <i className={`bi bi-chevron-right`}></i>
                        </div>
                    )}
                </div>
                {/* children block */}
                {props.element.children && (
                    props.element.children.map((child, childIndex)=>{
                        return (
                            <div key={childIndex} className={`ml-5 p-2 mt-3 duration-700 rounded-xl hover:bg-maya-orange/[.3] hover:cursor-pointer hover:text-white duration-30 ${window.location.href.indexOf(props.element.active)>-1 ? `block ${window.location.href.indexOf(child.active)>-1 ? 'bg-orange-400 text-white' : ''}` : 'hidden'}`} onClick={()=>{navigate(child.path)}}>
                                <div className='font-bold'> <i className={`${child.icon} text-xl mr-2`}></i> {child.name}</div>
                            </div>)
                        })
                )}
                {/* end children block */}
            </div>

        </>
    )
}
