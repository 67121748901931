import React from 'react'
import toast from 'react-hot-toast';

const date = new Date();
const time = `${date.getHours()}h:${date.getMinutes()}`;
const Notify = (state, subject, text) => toast.custom(
    <div className={`${state==='success' && 'bg-green-600'} ${state==='error' && 'bg-red-600'} ${'bg-'+state+'-600'} ${state==='warning' && 'bg-maya-orange'} shadow-lg w-96 max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block mb-3`}>
        <div className={`${state==='success' && 'bg-green-600'} ${state==='error' && 'bg-red-600'} ${'bg-'+state+'-600'} ${state==='warning' && 'bg-maya-orange'} flex justify-between items-center py-2 px-3 bg-clip-padding border-b  rounded-t-lg`}>
            <p className="font-bold text-white flex items-center">
                {state==='success' && <i className="bi bi-check-circle-fill w-4 h-4 mr-2 text-white"></i> }
                {state==='error' && <i className="bi bi-x-circle-fill w-4 h-4 mr-2 text-white"></i> }
                {state==='warning' && <i className="bi bi-exclamation-triangle-fill w-4 h-4 mr-2 text-white"></i> }
                {subject}</p>
            <div className="flex items-center">
                <p className="text-white opacity-90 text-xs">{time}</p>
                <button type="button" className="btn-close btn-close-white box-content w-4 h-4 ml-2 text-white border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-white hover:opacity-75 hover:no-underline" data-mdb-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
        <div className={`${state==='success' && 'bg-green-600'} ${state==='error' && 'bg-red-600'} ${'bg-'+state+'-600'} ${state==='warning' && 'bg-maya-orange'} p-3  rounded-b-lg break-words text-white`}>
            {text}
        </div>
    </div>
    );
export default Notify
