import React, {useState, useEffect}from 'react'
import MayaButton from '../../components/buttons/MayaButton'
import ModalOne from '../../components/modals/ModalOne'
import axios from 'axios';
import URLS from '../../components/API';
import 'animate.css';
import SaveButton from '../../components/buttons/SaveButton';
import validator from 'validator';
import Notify from '../../components/toast/ToastMessage';
import { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../../components/modals/ConfirmModal'
import Logout from '../auth/Logout';

export default function Users() {

    const [refresh, setRefresh] = useState(0);
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [saveUsers, setSaveUsers] = useState([]);
    const [usersTrashed, setUsersTrashed] = useState([]);
    const [roles, setRoles] = useState();
    useEffect(() => {
        axios.get(URLS.roles, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
        .then((response)=>{
            setRoles(response.data.roles);
            setRoleValue(response.data.roles[0].id)
        })

        axios.get(URLS.users, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
        .then((response)=>{
            setUsers(response.data.users);
            setSaveUsers((response.data.users));
        })

        axios.get(URLS.usersTrashed, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
        .then((response)=>{
            setUsersTrashed(response.data.usersTrashed);
        })
    }, [refresh])
    //create
    const [firstNameValue, setFirstNameValue] = useState('');
    const [firstNameError, setFirstNameError] = useState(false);
    const saveFirstNameValue = (e)=>{
        setFirstNameValue(e.target.value);
        setFirstNameError(false)
    }
    const firstNameValidation = ()=>{
        if(!firstNameValue){
            setFirstNameError(true)
            return true
        }else{
            setFirstNameError(false)
            return false
        }
    }

    const [lastNameValue, setLastNameValue] = useState('');
    const [lastNameError, setLastNameError] = useState(false);
    const saveLastNameValue = (e)=>{
        setLastNameValue(e.target.value);
        setLastNameError(false)
    }
    const LastNameValidation = ()=>{
        if(!lastNameValue){
            setLastNameError(true)
            return true
        }else{
            setLastNameError(false)
            return false
        }
    }

    const [emailValue, setEmailValue] = useState('');
    const [emailError, setEmailError] = useState(false);
    const saveEmailValue = (e)=>{
        setEmailValue(e.target.value);
        setEmailError(false)
    }
    const emailValidation = ()=>{
        if(!validator.isEmail(emailValue)){
            setEmailError(true)
            return true
        }else{
            setEmailError(false)
            return false
        }
    }

    const [contactValue, setContactValue] = useState('');
    const [contactError, setContactError] = useState(false);
    const saveContactValue = (e)=>{
        setContactValue(e.target.value);
        setContactError(false)
    }
    const contactValidation = ()=>{
        if(!validator.isNumeric(contactValue)){
            setContactError(true)
            return true
        }else{
            setContactError(false)
            return false
        }
    }

    const [contactWhatsappValue, setContactWhatsappValue] = useState('');
    const [contactWhatsappError, setContactWhatsappError] = useState(false);
    const saveContactWhatsappValue = (e)=>{
        setContactWhatsappValue(e.target.value);
        setContactWhatsappError(false)
    }
    const contactWhatsappValidation = ()=>{
        if(contactWhatsappValue){
            if(!validator.isNumeric(contactWhatsappValue)){
                setContactWhatsappError(true)
                return true
            }else{
                setContactWhatsappError(false)
                return false
            }
        }
    }

    const [passwordValue, setPasswordValue] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const savePasswordValue = (e)=>{
        setPasswordValue(e.target.value);
        setPasswordError(false)
    }
    const passwordValidation = ()=>{
        if(!validator.isStrongPassword(passwordValue, {minLength: 8, minLowercase:1, minSymbols:1, minUppercase:2})){
            setPasswordError(true)
            return true
        }else{
            setPasswordError(false)
            return false
        }
    }

    const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const saveConfirmPasswordValue = (e)=>{
        setConfirmPasswordValue(e.target.value);
        setConfirmPasswordError(false)
    }
    const confirmPasswordValidation = ()=>{
        if(passwordValue){
            if(confirmPasswordValue !== passwordValue){
                setConfirmPasswordError(true)
                return true
            }else{
                setConfirmPasswordError(false)
                return false
            }
        }
    }

    const [cityValue, setCityValue] = useState('');
    const saveCityValue = (e)=>{
        setCityValue(e.target.value);
    }

    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [createModalCloseClass, setCreateModalCloseClass] = useState('opacity-0');
    const [saveLoader, setSaveLoader] = useState(false);
    const closeCreateModalFunction = ()=>{
        setOpenCreateModal(false);
        setCreateModalCloseClass('animate__animated animate__fadeOutRight')
    }
    const openCreateModalFunction = ()=>{
        setOpenCreateModal(true);
    }

    const [image, setImage] = useState();
    const [imageError, setImageError] = useState(false);
    const handleImage = (e)=>{
        setImage({
            file: e.target.files[0],
            previewLink: URL.createObjectURL(e.target.files[0])
        })
        setImageError(false);
    }
    const ImageValidation = ()=>{
        if(!image){
            setImageError(true);
            return true
        }else{
            setImageError(false);
            return false
        }
    }

    const [roleValue, setRoleValue] = useState();
    const saveRoleValue = (e)=>{
        setRoleValue(e.target.value);
    }
    
    const save = ()=>{
        setSaveLoader(true);
        firstNameValidation();
        LastNameValidation();
        emailValidation()
        contactValidation();
        contactWhatsappValidation();
        passwordValidation();
        confirmPasswordValidation();
        ImageValidation();
        
        if(!firstNameValidation() && !LastNameValidation() && !emailValidation() && !contactValidation() && !contactWhatsappValidation() && !passwordValidation() && !confirmPasswordValidation() && !ImageValidation()){
            let data = new FormData();
            data.append('firstName', firstNameValue);
            data.append('lastName', lastNameValue);
            data.append('email', emailValue);
            data.append('contact', contactValue);
            data.append('contactWhatsapp', contactWhatsappValue);
            data.append('password', passwordValue);
            data.append('city', cityValue);
            data.append('role', roleValue);
            data.append('image', image.file);
            // console.log(image.file);
            axios.post(URLS.register, data, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            .then((response)=>{
                if(response.data.success){
                    Notify('success', 'Création d\'un utilisateur', response.data.success);
                    setFirstNameValue('');
                    setLastNameValue('');
                    setEmailValue('');
                    setContactValue('');
                    setPasswordValue('');
                    setConfirmPasswordValue('');
                    setCityValue('');
                    setRoleValue(roles[0].id);
                    setImage(false);
                    closeCreateModalFunction();
                    setRefresh(refresh+1)
                }
                setSaveLoader(false);
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                if(error.response.data){
                    Notify('error', 'Création de compte', 'Ce compte existe déjà');
                }
            setSaveLoader(false);
        })
        }
        setSaveLoader(false);
    }

    //update
    const [updateFirstNameValue, setUpdateFirstNameValue] = useState('');
    const [updateFirstNameError, setUpdateFirstNameError] = useState(false);
    const saveUpdateFirstNameValue = (e)=>{
        setUpdateFirstNameValue(e.target.value);
        setUpdateFirstNameError(false)
    }
    const updateFirstNameValidation = ()=>{
        if(!updateFirstNameValue){
            setUpdateFirstNameError(true)
            return true
        }else{
            setUpdateFirstNameError(false)
            return false
        }
    }

    const [updateLastNameValue, setUpdateLastNameValue] = useState('');
    const [updateLastNameError, setUpdateLastNameError] = useState(false);
    const saveUpdateLastNameValue = (e)=>{
        setUpdateLastNameValue(e.target.value);
        setUpdateLastNameError(false)
    }
    const updateLastNameValidation = ()=>{
        if(!updateLastNameValue){
            setUpdateLastNameError(true)
            return true
        }else{
            setUpdateLastNameError(false)
            return false
        }
    }

    const [updateEmailValue, setUpdateEmailValue] = useState('');
    const [updateEmailError, setUpdateEmailError] = useState(false);
    const saveUpdateEmailValue = (e)=>{
        setUpdateEmailValue(e.target.value);
        setUpdateEmailError(false)
    }
    const updateEmailValidation = ()=>{
        if(!validator.isEmail(updateEmailValue)){
            setUpdateEmailError(true)
            return true
        }else{
            setUpdateEmailError(false)
            return false
        }
    }

    const [updateContactValue, setUpdateContactValue] = useState('');
    const [updateContactError, setUpdateContactError] = useState(false);
    const saveUpdateContactValue = (e)=>{
        setUpdateContactValue(e.target.value);
        setUpdateContactError(false)
    }
    const updateContactValidation = ()=>{
        if(!validator.isNumeric(updateContactValue)){
            setUpdateContactError(true)
            return true
        }else{
            setUpdateContactError(false)
            return false
        }
    }

    const [updateContactWhatsappValue, setUpdateContactWhatsappValue] = useState('');
    const [updateContactWhatsappError, setUpdateContactWhatsappError] = useState(false);
    const saveUpdateContactWhatsappValue = (e)=>{
        setUpdateContactWhatsappValue(e.target.value);
        setUpdateContactWhatsappError(false)
    }
    const updateContactWhatsappValidation = ()=>{
        if(updateContactWhatsappValue){
            if(!validator.isNumeric(updateContactWhatsappValue)){
                setUpdateContactWhatsappError(true)
                return true
            }else{
                setUpdateContactWhatsappError(false)
                return false
            }
        }
    }

    const [updateCityValue, setUpdateCityValue] = useState('');
    const saveUpdateCityValue = (e)=>{
        setUpdateCityValue(e.target.value);
    }

    const [updateRoleValue, setUpdateRoleValue] = useState();
    const saveUpdateRoleValue = (e)=>{
        setUpdateRoleValue(e.target.value);
    }

    const [updateImage, setUpdateImage] = useState();
    const [updateImageError, setUpdateImageError] = useState(false);
    const handleUpdateImage = (e)=>{
        setUpdateImage({
            file: e.target.files[0],
            previewLink: URL.createObjectURL(e.target.files[0])
        })
        setUpdateImageError(false);
    }

    const updateImageValidation = ()=>{
        if(!updateImage){
            setUpdateImageError(true);
            return true
        }else{
            setUpdateImageError(false);
            return false
        }
    }
    const [updateId, setUpdateId] = useState()
    const edit = (index)=>{
        setUpdateId(users[index].id);
        setUpdateFirstNameValue(users[index].first_name);
        setUpdateLastNameValue(users[index].last_name);
        setUpdateEmailValue(users[index].email);
        setUpdateContactValue(users[index].contact);
        setUpdateContactWhatsappValue(users[index].whatsapp);
        setUpdateCityValue(users[index].city);
        setUpdateRoleValue(users[index].role.id)
        setUpdateImage({
            file: false,
            previewLink: users[index].image
        })
        openUpdateModalFunction();
    }
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [updateModalCloseClass, setUpdateModalCloseClass] = useState('opacity-0');
    const [updateLoader, setUpdateLoader] = useState(false);
    const closeUpdateModalFunction = ()=>{
        setOpenUpdateModal(false);
        setUpdateModalCloseClass('animate__animated animate__fadeOutRight')
    }
    const openUpdateModalFunction = ()=>{
        setOpenUpdateModal(true);
    }

    const update = ()=>{
        setUpdateLoader(true);
        updateFirstNameValidation();
        updateLastNameValidation();
        updateEmailValidation()
        updateContactValidation();
        updateContactWhatsappValidation();
        updateImageValidation();

        if(!updateFirstNameValidation() && !updateLastNameValidation() && !updateEmailValidation() && !updateContactValidation() && !updateContactWhatsappValidation() && !updateImageValidation()){
            let data = new FormData();
            data.append('id', updateId);
            data.append('firstName', updateFirstNameValue);
            data.append('lastName', updateLastNameValue);
            data.append('email', updateEmailValue);
            data.append('contact', updateContactValue);
            data.append('contactWhatsapp', updateContactWhatsappValue);
            data.append('city', updateCityValue);
            data.append('role', updateRoleValue);
            data.append('image', updateImage.file);
            // console.log(image.file);
            axios.post(URLS.updateUser, data, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            .then((response)=>{
                if(response.data.success){
                    Notify('success', 'Modification d\'un utilisateur', response.data.success);
                    setUpdateFirstNameValue('');
                    setUpdateLastNameValue('');
                    setUpdateEmailValue('');
                    setUpdateContactValue('');
                    setUpdateCityValue('');
                    setUpdateRoleValue(roles[0].id);
                    setUpdateImage(false);
                    closeUpdateModalFunction();
                    setRefresh(refresh+1)
                }
                setUpdateLoader(false);
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
            setUpdateLoader(false);
        })
        }
        setUpdateLoader(false);
    }
    const updateUserState = (index)=>{
        const data = {
            'id' : users[index].id,
            'state' : users[index].state === 1 ? 0 : 1,
        }
        axios.post(URLS.updateUserState, data, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        })
        Notify(`${data.state === 0 ? 'warning' : 'success'}`, 'Statut utilisateur', `${users[index].first_name} ${data.state === 0 ? 'suspendu !' : 'Actif'}`)
        setRefresh(refresh+1);
    }

    //delete
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [actionId, setActionId] = useState(false);
    const [confirmCloseClass, setConfirmCloseClass] = useState('opacity-0');

    const openConfirmModal = (id)=>{
        setDeleteModalOpen(true);
        setActionId(id);
    }
    const closeConfirmModal = ()=>{
        setDeleteModalOpen(false);
        setConfirmCloseClass('animate__animated animate__backOutDown');
    }

    const deleteUser = (id)=>{
        axios.post(URLS.deleteUser, {'id': id}, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
        .then((response)=>{
            if(response.data.success){
                Notify('success', 'Suppression de Compte', 'Compte supprimé avec succès !');
                setRefresh(refresh+1);
            }else{
                Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
            }
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
        })
    }
    
    const [displayTrashed, setDisplayTrashed] = useState(false);
    const restoreUser = (id)=>{
        axios.post(URLS.restoreUser, {'id': id}, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
        .then((response)=>{
            if(response.data.success){
                Notify('success', 'Restauration de Compte', 'Compte restauré avec succès !');
                setRefresh(refresh+1);
            }else{
                Notify('error', 'Erreur de restauration', 'Veuillez contacter le support développeur');
            }
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', 'Erreur de restauration', 'Veuillez contacter le support développeur');
        })
    }

    //delete
    const [forceDeleteModalOpen, setForceDeleteModalOpen] = useState(false);
    const [forceDeletedActionId, setForceDeletedActionId] = useState(false);
    const [forceDeletedCloseClass, setForceDeletedCloseClass] = useState('opacity-0');
    const openForceConfirmModal = (id)=>{
        setForceDeleteModalOpen(true);
        setForceDeletedActionId(id);
    }
    const closeForceConfirmModal = ()=>{
        setForceDeleteModalOpen(false);
        setForceDeletedCloseClass('animate__animated animate__backOutDown');
    }

    const forceDeleteUser = (id)=>{
        axios.post(URLS.forceDeleteUser, {'id': id}, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
        .then((response)=>{
            if(response.data.success){
                Notify('success', 'Suppression de Compte', 'Compte supprimé avec succès !');
                setRefresh(refresh+1);
            }else{
                Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
            }
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
        })
    }

    const search = (e)=>{
        if(e.target.value){
            const data = {
                subject: e.target.value,
            }
            axios.post(URLS.searchUser, data, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }, 
            })
            .then((response)=>{
                setUsers(response.data.result);
            })
        }else{
            setUsers(saveUsers);
        }
    }
    
    return (
        <>
            <Toaster position='top-right'/>
            <div className='text-center my-5 text-4xl font-black'>Les comptes utilisateurs</div>
            <div className='h-20 max-w-lg w-full bg-maya-orange/[.2] mx-auto mt-10 rounded-2xl flex justify-between items-center p-4 gap-2'>
                <div>
                    <select className='px-20 py-2 rounded-xl bg-white' onChange={(e)=>{search(e)}} id="">
                        <option value="">Trier par role</option>
                        {
                            roles && roles.map((role, index)=>{
                                if(role.label !== 'développeur' && role.label !== 'developpeur' && role.label !== 'client'){
                                    return (
                                        <option key={index} value={role.label} className="capitalize">{role.label}</option>
                                    )
                                }
                            })
                        }
                    </select>
                </div>
                <div className='bg-white rounded-xl flex'>
                    <input type="text" className='w-full bg-transparent py-1  text-center focus:outline-none focus:text-maya-orange' placeholder='rechercher...' onChange={(e)=>{search(e)}}/>
                    <div className='bg-maya-orange px-2 py-0.5 rounded-xl'>
                        <i className="bi bi-search text-lg text-white"></i>
                    </div>
                </div>
                
            </div>
            <div className='my-5 text-center'>
                {displayTrashed ? (
                    <button className='mx-4 px-3 bg-green-500 text-white py-1 text-lg rounded-xl duration-300 hover:bg-green-600' onClick={()=>{setDisplayTrashed(false)}}>Liste</button>
                    ) : (
                    <div>
                        <MayaButton onClick={openCreateModalFunction}>Nouveau compte</MayaButton>
                    <button className='mx-4 px-3 bg-red-500 text-white py-1 text-lg rounded-xl duration-300 hover:bg-red-600' onClick={()=>{setDisplayTrashed(true)}}>Corbeille</button>
                    </div>
                )}
            </div>
            { !displayTrashed ? (
                <div>
                    <div className="grid grid-cols-3 gap-4 mx-3">
                        {
                            users && users.map((user, index)=>{
                                return (
                                    <div key={index} className='bg-white p-4 rounded-2xl shadow-lg'>
                                        <div className='grid grid-cols-3'>
                                            <div className='col-span-2 my-auto'>
                                                <div className='text-xl font-bold'>{user.first_name} {user.last_name}</div>
                                                <div className='mt-2 text-gray-500'>{user.role.label}</div>
                                            </div>
                                            <div className='ml-auto rounded'>
                                                <img src={user.image} className="h-20 rounded-xl" alt="" />
                                            </div>
                                        </div>
                                        <div className='mt-5'>
                                            {
                                                <div className='space-x-4'>
                                                    <i className="bi bi-pencil bg-yellow-500/[.2] text-yellow-600 hover:text-white hover:bg-yellow-500 duration-300 cursor-pointer px-3 py-1 rounded-full text-xl" onClick={()=>{edit(index)}}></i>
                                                    <i className="bi bi-trash bg-red-500/[.2] text-red-600 hover:text-white hover:bg-red-500 duration-300 cursor-pointer px-3 py-1 rounded-full text-xl" onClick={()=>{openConfirmModal(user.id)}}></i>
                                                    {user.state === 1 ? (
                                                        <i className="bi bi-play bg-green-500/[.2] text-green-600 hover:text-white hover:bg-green-500 duration-300 cursor-pointer px-3 py-1 rounded-full text-xl" onClick={()=>{updateUserState(index)}}></i>
                                                    ) : (
                                                        <i className="bi bi bi-pause-fill bg-orange-500/[.2] text-orange-600 hover:text-white hover:bg-orange-500 duration-300 cursor-pointer px-3 py-1 rounded-full text-xl" onClick={()=>{updateUserState(index)}}></i>
                                                    )}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/* create */}
                    <ModalOne open={openCreateModal} onClose={closeCreateModalFunction} title="Création d'un nouveau compte utilisateur" color="green" closeClass={createModalCloseClass}>
                        <div className='border rounded-lg mt-5 border-green-300 p-10'>
                            <div className='grid grid-cols-2 gap-4 mb-6'>
                                <div>
                                    <div className='mb-1'>
                                        <label htmlFor="" className='text-lg'>Nom</label>
                                    </div>
                                    <div className=''>
                                        <input value={lastNameValue} type="text" className='border border-2 w-full py-1 rounded-lg border-gary-100 focus:outline-none text-center focus:border-green-500 duration-300' onInput={(e)=>{saveLastNameValue(e)}}/>
                                        <small className={`text-red-500 ${lastNameError ? '' : 'hidden'}`}>Vous devez renseigner le nom</small>
                                    </div>
                                </div>

                                <div>
                                    <div className='mb-1'>
                                        <label htmlFor="" className='text-lg'>Prénom</label>
                                    </div>
                                    <div className=''>
                                        <input type="text" value={firstNameValue} className='border border-2 w-full py-1 rounded-lg border-gary-100 focus:outline-none text-center focus:border-green-500 duration-300' onInput={(e)=>{saveFirstNameValue(e)}}/>
                                        <small className={`text-red-500 ${firstNameError ? '' : 'hidden'}`}>Vous devez renseigner le prénom</small>
                                    </div>
                                </div>
                            </div>

                            <div className='grid grid-cols-2 gap-4 mb-6'>
                                <div>
                                    <div className='mb-1'>
                                        <label htmlFor="" className='text-lg'>Email</label>
                                    </div>
                                    <div className=''>
                                        <input type="text" value={emailValue} className='border border-2 w-full py-1 rounded-lg border-gary-100 focus:outline-none text-center focus:border-green-500 duration-300' onInput={(e)=>{saveEmailValue(e)}}/>
                                        <small className={`text-red-500 ${emailError ? '' : 'hidden'}`}>Vous devez renseigner l'email</small>
                                    </div>
                                </div>
                                
                                <div>
                                    <div className='mb-1'>
                                        <label htmlFor="" className='text-lg'>Contact</label>
                                    </div>
                                    <div className=''>
                                        <input type="text" value={contactValue} className='border border-2 w-full py-1 rounded-lg border-gary-100 focus:outline-none text-center focus:border-green-500 duration-300' onInput={(e)=>{saveContactValue(e)}}/>
                                        <small className={`text-red-500 ${contactError ? '' : 'hidden'}`}>Vous devez renseigner le contact</small>
                                    </div>
                                </div>
                            </div>

                            <div className='grid grid-cols-2 gap-4 mb-6'>
                                <div>
                                    <div className='mb-1'>
                                        <label htmlFor="" className='text-lg'>Contact Whatsapp</label>
                                    </div>
                                    <div className=''>
                                        <input type="text" value={contactWhatsappValue} className='border border-2 w-full py-1 rounded-lg border-gary-100 focus:outline-none text-center focus:border-green-500 duration-300' onInput={(e)=>{saveContactWhatsappValue(e)}}/>
                                        <small className={`text-red-500 ${contactWhatsappError ? '' : 'hidden'}`}>Le contact est invalide</small>
                                    </div>
                                </div>
                                
                                <div>
                                    <div className='mb-1'>
                                        <label htmlFor="" className='text-lg'>Ville</label>
                                    </div>
                                    <div className=''>
                                        <input type="text" value={cityValue} className='border border-2 w-full py-1 rounded-lg border-gary-100 focus:outline-none text-center focus:border-green-500 duration-300' onInput={(e)=>{saveCityValue(e)}}/>
                                    </div>
                                </div>
                            </div>

                            <div className='grid grid-cols-2 gap-4 mb-6'>
                                <div>
                                    <div className='mb-1'>
                                        <label htmlFor="" className='text-lg'>Mot de passe</label>
                                    </div>
                                    <div className=''>
                                        <input type="password" value={passwordValue} className='border border-2 w-full py-1 rounded-lg border-gary-100 focus:outline-none text-center focus:border-green-500 duration-300' onInput={(e)=>{savePasswordValue(e)}}/>
                                        <small className={`text-red-500 leading-6 ${passwordError ? '' : 'hidden'}`}>Il faut une lettre majuscule, une minuscule, un symbole et faire au moins 8 caractères)</small>
                                    </div>
                                </div>
                                
                                <div>
                                    <div className='mb-1'>
                                        <label htmlFor="" className='text-lg'>Confirmer le mot de passe</label>
                                    </div>
                                    <div className=''>
                                        <input type="password" value={confirmPasswordValue} className='border border-2 w-full py-1 rounded-lg border-gary-100 focus:outline-none text-center focus:border-green-500 duration-300' onInput={(e)=>{saveConfirmPasswordValue(e)}}/>
                                    </div>
                                </div>
                            </div>
                            <div className={`text-center ${confirmPasswordError ? 'mb-2 font-bold' : 'hidden'}`}>
                                <small className={`text-red-500 leading-6`}>Les deux mots de passes ne correspondent pas !</small>
                            </div>
                            <div className='grid grid-cols-2'>
                                <div>
                                    <div className='mb-1'>
                                        <label htmlFor="" className='text-lg'>Rôle</label>
                                    </div>
                                    <div className=''>
                                        <select name="" className='w-full py-2 rounded-md px-3 capitalize' onChange={(e)=>{saveRoleValue(e)}}>
                                            {
                                                roles && roles.map((role, index)=>{
                                                    return (
                                                        <option key={index} value={role.id} className="capitalize">{role.label}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                {
                                    !image ? (
                                        <div className='text-center'>
                                            <label className=" mx-auto w-28 h-28 flex flex-col items-center px-4 py-2 bg-maya-orange text-white rounded-lg shadow-lg tracking-wide border border-white hover:border-kalloba cursor-pointer hover:bg-blue hover:text-kalloba duration-300">
                                                <i className="bi bi-cloud-arrow-up-fill text-3xl"></i>
                                                <span className=" text-md">Photo</span>
                                                <input type='file' className="hidden" onChange={(e)=>{handleImage(e)}}/>
                                            </label>
                                            <small className={`text-red-500 leading-6 ${imageError ? '' : 'hidden'}`}>Veuillez sélectionner une image</small>
                                        </div>
                                ) :  (
                                        <div className="animate__animated animate__fadeIn text-center">
                                            <img src={image.previewLink} alt="" className="h-28 border border-green-300 w-28 rounded-md mx-auto" />
                                            <i className="bi bi-trash rounded-full px-[0.1em] font-bold mt-1 cursor-pointer hover:scale-125 hover:text-red-500" onClick={()=>{setImage(false)}}></i>
                                        </div>
                                    )
                                }
                            </div>
                            <div className='text-center mt-4'>
                                <SaveButton text="Enregistrer" className="bg-green-500 px-3 py-1 rounded-lg text-lg text-white" save={save} loader={saveLoader}/>
                            </div>
                        </div>
                        
                    </ModalOne>

                    {/* delete  */}
                    <ConfirmModal open={deleteModalOpen} onClose={closeConfirmModal} closeClass={confirmCloseClass} action={deleteUser} id={actionId}/>

                    {/* update */}
                    <ModalOne open={openUpdateModal} onClose={closeUpdateModalFunction} title={`Modification de ${updateFirstNameValue} ${updateLastNameValue}`} color="orange" closeClass={updateModalCloseClass}>
                        <div className='border rounded-lg mt-5 border-orange-300 p-10'>
                            <div className='grid grid-cols-2 gap-4 mb-6'>
                                <div>
                                    <div className='mb-1'>
                                        <label htmlFor="" className='text-lg'>Nom</label>
                                    </div>
                                    <div className=''>
                                        <input value={updateLastNameValue} type="text" className='border border-2 w-full py-1 rounded-lg border-gary-100 focus:outline-none text-center focus:border-green-500 duration-300' onInput={(e)=>{saveUpdateLastNameValue(e)}}/>
                                        <small className={`text-red-500 ${updateLastNameError ? '' : 'hidden'}`}>Vous devez renseigner le nom</small>
                                    </div>
                                </div>

                                <div>
                                    <div className='mb-1'>
                                        <label htmlFor="" className='text-lg'>Prénom</label>
                                    </div>
                                    <div className=''>
                                        <input type="text" value={updateFirstNameValue} className='border border-2 w-full py-1 rounded-lg border-gary-100 focus:outline-none text-center focus:border-green-500 duration-300' onInput={(e)=>{saveUpdateFirstNameValue(e)}}/>
                                        <small className={`text-red-500 ${updateFirstNameError ? '' : 'hidden'}`}>Vous devez renseigner le prénom</small>
                                    </div>
                                </div>
                            </div>

                            <div className='grid grid-cols-2 gap-4 mb-6'>
                                <div>
                                    <div className='mb-1'>
                                        <label htmlFor="" className='text-lg'>Email</label>
                                    </div>
                                    <div className=''>
                                        <input type="text" value={updateEmailValue} className='border border-2 w-full py-1 rounded-lg border-gary-100 focus:outline-none text-center focus:border-green-500 duration-300' onInput={(e)=>{saveUpdateEmailValue(e)}}/>
                                        <small className={`text-red-500 ${updateEmailError ? '' : 'hidden'}`}>Vous devez renseigner l'email</small>
                                    </div>
                                </div>
                                
                                <div>
                                    <div className='mb-1'>
                                        <label htmlFor="" className='text-lg'>Contact</label>
                                    </div>
                                    <div className=''>
                                        <input type="text" value={updateContactValue} className='border border-2 w-full py-1 rounded-lg border-gary-100 focus:outline-none text-center focus:border-green-500 duration-300' onInput={(e)=>{saveUpdateContactValue(e)}}/>
                                        <small className={`text-red-500 ${updateContactError ? '' : 'hidden'}`}>Vous devez renseigner le contact</small>
                                    </div>
                                </div>
                            </div>

                            <div className='grid grid-cols-2 gap-4 mb-6'>
                                <div>
                                    <div className='mb-1'>
                                        <label htmlFor="" className='text-lg'>Contact Whatsapp</label>
                                    </div>
                                    <div className=''>
                                        <input type="text" value={updateContactWhatsappValue} className='border border-2 w-full py-1 rounded-lg border-gary-100 focus:outline-none text-center focus:border-green-500 duration-300' onInput={(e)=>{saveUpdateContactWhatsappValue(e)}}/>
                                        <small className={`text-red-500 ${updateContactWhatsappError ? '' : 'hidden'}`}>Le contact est invalide</small>
                                    </div>
                                </div>
                                
                                <div>
                                    <div className='mb-1'>
                                        <label htmlFor="" className='text-lg'>Ville</label>
                                    </div>
                                    <div className=''>
                                        <input type="text" value={updateCityValue} className='border border-2 w-full py-1 rounded-lg border-gary-100 focus:outline-none text-center focus:border-green-500 duration-300' onInput={(e)=>{saveUpdateCityValue(e)}}/>
                                    </div>
                                </div>
                            </div>

                            <div className='grid grid-cols-2'>
                                <div>
                                    <div className='mb-1'>
                                        <label htmlFor="" className='text-lg'>Rôle</label>
                                    </div>
                                    <div className=''>
                                        <select name="" className='w-full py-2 rounded-md px-3 capitalize' onChange={(e)=>{saveUpdateRoleValue(e)}}>
                                            {
                                                roles && roles.map((role, index)=>{
                                                    return (
                                                        <option key={index} value={role.id} selected={updateRoleValue===role.id} className="capitalize">{role.label}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                {
                                    !updateImage ? (
                                        <div className='text-center'>
                                            <label className=" mx-auto w-28 h-28 flex flex-col items-center px-4 py-2 bg-maya-orange text-white rounded-lg shadow-lg tracking-wide border border-white hover:border-kalloba cursor-pointer hover:bg-blue hover:text-kalloba duration-300">
                                                <i className="bi bi-cloud-arrow-up-fill text-3xl"></i>
                                                <span className=" text-md">Photo</span>
                                                <input type='file' className="hidden" onChange={(e)=>{handleUpdateImage(e)}}/>
                                            </label>
                                            <small className={`text-red-500 leading-6 ${updateImageError ? '' : 'hidden'}`}>Veuillez sélectionner une image</small>
                                        </div>
                                ) :  (
                                        <div className="animate__animated animate__fadeIn text-center">
                                            <img src={updateImage.previewLink} alt="" className="h-28 border border-green-300 w-28 rounded-md mx-auto" />
                                            <i className="bi bi-trash rounded-full px-[0.1em] font-bold mt-1 cursor-pointer hover:scale-125 hover:text-red-500" onClick={()=>{setUpdateImage(false)}}></i>
                                        </div>
                                    )
                                }
                            </div>
                            <div className='text-center mt-4'>
                                <SaveButton text="Enregistrer" className="bg-green-500 px-3 py-1 rounded-lg text-lg text-white hover:text-xl duration-300 hover:bg-green-600" save={update} loader={updateLoader}/>
                            </div>
                        </div>
                        
                    </ModalOne>
                </div>
            ) : (
                <div className="grid grid-cols-3 gap-4 mx-3">
                        {
                            usersTrashed && usersTrashed.length > 0 ? (
                                usersTrashed.map((user, index)=>{
                                    return (
                                        <div key={index} className='bg-white p-4  rounded-2xl shadow-lg relative'>
                                            <div className='inset-0 absolute h-full bg-white/[.3] w-full rounded-2xl pointer-events-none'></div>
                                            <div className='grid grid-cols-3'>
                                                <div className='col-span-2 my-auto'>
                                                    <div className='text-xl font-bold'>{user.first_name} {user.last_name}</div>
                                                    <div className='mt-2 text-gray-500'>{user.role.label}</div>
                                                </div>
                                                <div className='ml-auto rounded'>
                                                    <img src={user.image} className="h-20 rounded-xl" alt="" />
                                                </div>
                                            </div>
                                            <div className='mt-5'>
                                                {
                                                    <div className='space-x-4 text-right'>
                                                        <i className="bi bi-arrow-clockwise bg-green-500/[.2] text-green-600 hover:text-white hover:bg-green-500 duration-300 cursor-pointer px-3 py-1 rounded-full text-xl" onClick={()=>{restoreUser(user.id)}}></i>
                                                        <i className="bi bi-trash bg-red-500/[.2] text-red-600 hover:text-white hover:bg-red-500 duration-300 cursor-pointer px-3 py-1 rounded-full text-xl" onClick={()=>{openForceConfirmModal(user.id)}}></i>
                                                    </div>
                                                }
                                            </div>
                                            {/* force delete  */}
                                            <ConfirmModal open={forceDeleteModalOpen} onClose={closeForceConfirmModal} closeClass={forceDeletedCloseClass} action={forceDeleteUser} id={forceDeletedActionId}/>
                                        </div>
                                    )
                                })
                            ) : (
                                <div className='text-center col-span-full mt-4 text-4xl font-bold text-gray-500'>
                                    Corbeille vide
                                </div>
                            )
                        }
                    </div>
            )}
        </>
    )
}
