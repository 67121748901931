import React, {useState, useEffect} from 'react'
import etudesci from '../etudesci.png'
import moment from 'moment/moment';
import 'moment/locale/fr';
import axios from 'axios';
import URLS from '../components/API';
import Notify from '../components/toast/ToastMessage';
import { useNavigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useRef } from 'react';
import { jsPDF } from 'jspdf';
import InvoicePDF from '../components/pdf/InvoicePDF';

export default function Invoices() {
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState([]);
    const [logo, setLogo] = useState({file: false, previewLink: etudesci});
    const [saved, setSaved] = useState(false);
    const handleImage = (e)=>{
        setLogo({
            file: e.target.files[0],
            previewLink: URL.createObjectURL(e.target.files[0])
        })
    }
    const logoValidation = ()=>{
        if(!logo){
            Notify('error', 'Erreur sur lae logo', 'Vous devez choisir le logo')
            return true;
        }else{
            return false;
        }
    }

    const [clients, setClients] = useState([]);    
    const [saveClients, setSaveClients] = useState([]);    
    const [projects, setProjects] = useState([]);
    const [lastId, setLastId] = useState('');
    useEffect(() => {
        axios.get(URLS.allClients, {
            headers:{
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((response)=>{
            if(response.data.clients){
                setClients(response.data.clients);
                setSaveClients(response.data.clients);
            }else{
                Notify('error', 'Une erreur est survenue vueillez contacter le support développeur');
            }
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', "Erreur d'enregistrement de projet", "Veuillez contacter le support développeur");
        })

        axios.get(URLS.accounts, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((response)=>{
            if(response.data.accounts){
                setAccounts(response.data.accounts)
            }else{
                Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
            }
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', 'Erreur d\'enregistrmenet', 'Veuillez contacter le support développeur');
        })

        axios.get(URLS.projects, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((response)=>{
            setProjects(response.data.projects);
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', "Erreur de projet", "Veuillez contacter le support développeur");
        })

        axios.get(URLS.lastInvoiceId, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((response)=>{
            setLastId(response.data.id+1);
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', "Erreur de projet", "Veuillez contacter le support développeur");
        })
        
    }, [])

    const [subProjects, setSubProjects] = useState([]);
    const getSubProject = (projectId)=>{
        axios.post(URLS.projectSubProjects, {'project': projectId}, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((response)=>{
            setSubProjects(response.data.subProjects);
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', "Erreur de projet", "Veuillez contacter le support développeur");
        })
    }
    
    const [client, setClient] = useState('');
    const searchClient = (e)=>{
        if(e.target.value){
            axios.post(URLS.globalSearchClient, {'subject': e.target.value}, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            .then((response)=>{
                if(response.data.results){
                    setClients(response.data.results);
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', 'Une erreur s\'est produite', 'Veuillez contacter le support développeur');
            })
        }else{
            setClients(saveClients);
        }
    }
    const saveClient = (index)=>{
        setClient(clients[index]);
        handleClientSelectBoxDisplaying();
    }
    const [clientSelectBox, setClientSelectBox] = useState(false)
    const handleClientSelectBoxDisplaying = ()=>{
        setClientSelectBox(!clientSelectBox);
    }

    const [city, setCity] = useState('Abidjan');
    const saveCity = (e)=>{
        setCity(e.target.value)
    }

    const [account, setAccount] = useState('');
    const saveAccount = (e)=>{
        setAccount(e.target.value);
    }

    const cityValidation = ()=>{
        if(!city){
            Notify('error', 'Erreur sur la ville', 'Vous devez renseigner la ville du client')
            return true;
        }else{
            return false;
        }
    }

    const [project, setProject] = useState('');
    const saveProject = (e)=>{
        setProject(e.target.value)
        if(e.target.value){
            getSubProject(projects[e.target.value].id);
        }
    }

    const newRow = ()=>{
        setProducts([...products, {
            project: 0,
            quantity: 1,
            budget: 0,
        }]);
    }
    const delRow = (row)=>{
        let middle = products.filter((value, index)=> index!==row);
        setProducts(middle);
        if(middle.length === 0){
            setProject('');
            setProducts([
                {
                    project: 0,
                    quantity: 1,
                    budget: 0,
                    discount: 0,
                }
            ]);
        }
    }
    const [products, setProducts] = useState([
        {
            project: 0,
            quantity: 1,
            budget: 0,
            discount: 0,
        }
    ])
    const saveProductSubProject = (row, e)=>{
        let middle = products.map((value, index)=>{
            if(index===row){
                return {...value, project: e.target.value, budget: subProjects[e.target.value].budget};
            }
            return value;
        })
        setTotal(middle.reduce((a,v)=> a = a + v.budget * v.quantity, 0));
        // setDiscount(middle.reduce((a,v)=> a = a + v.budget * v.quantity, 0)*0.1);
        setProducts(middle);
    }
    const saveProductQuantity = (row, e)=>{
        let middle = products.map((value, index)=>{
            if(index===row){
                return {...value, quantity: e.target.value};
            }
            return value;
        })
        // setDiscount(middle.reduce((a,v)=> a = a + v.budget * v.quantity, 0)*0.1);
        setTotal(middle.reduce((a,v)=> a = a + v.budget * v.quantity, 0));
        setProducts(middle);
    }

    const saveProductDiscount = (row, e)=>{
        let middle = products.map((value, index)=>{
            if(index===row){
                return {...value, discount: e.target.value};
            }
            return value;
        })
        // setDiscount(middle.reduce((a,v)=> a = a + v.discount, 0));
        setDiscount(middle.reduce(function(previousValue, currentValue) {
            return {
              discount: previousValue.discount + currentValue.discount,
            }
          }).discount
        );
        setTotal(middle.reduce((a,v)=> a = a + v.budget * v.quantity, 0));
        setProducts(middle);
    }

    const [discount, setDiscount] = useState(0);
    // const saveDiscount = (e)=>{
    //     setDiscount(e.target.value);
    // }
    const [total, setTotal] = useState(0);
    const projectValidation = ()=>{
        if(!project){
            Notify('error', 'Erreur sur le project', 'Vous devez renseigner la projet')
            return true;
        }else{
            return false;
        }
    }
    const clientValidation = ()=>{
        if(!client){
            Notify('error', 'Erreur sur le client', 'Vous devez renseigner le client')
            return true;
        }else{
            return false;
        }
    }

    const productsValidation = ()=>{
        if(!products){
            Notify('error', 'Erreur sur les produits', 'Vous devez renseigner les produits')
            return true;
        }else{
            return false;
        }
    }

    const amountPaidValidation = ()=>{
        if(!amountPaid){
            Notify('error', 'Erreur sur le montant payé', 'Vous devez renseigner le montant payé')
            return true;
        }else{
            return false;
        }
    }

    const accountValidation = ()=>{
        if(!account){
            Notify('error', 'Erreur sur le compte receveur', 'Vous devez renseigner le compte receveur')
            return true;
        }else{
            return false;
        }
    }

    const [amountPaid, setAmountPaid] = useState(0);
    const saveAmountPaid = (e)=>{
        setAmountPaid(e.target.value);
    }
    

    const invoiceValidation = () =>{
        clientValidation();
        amountPaidValidation();
        productsValidation();
        logoValidation();
        cityValidation();
        projectValidation();
        accountValidation();

        if(!accountValidation() && !clientValidation() && !amountPaidValidation() && !productsValidation() && !logoValidation() && !cityValidation() && !projectValidation()){
            return false;
        }else{
            return true;
        }
    }

    const [previewToggle, setPreviewToggle] = useState(false);
    const [references, setReferences] = useState(false);
    const preview = ()=>{
        if(!invoiceValidation()){
            setReferences('M2022A0'+client.last_name[0]+client.last_name[1]+client.first_name[0]+client.first_name[1]+lastId)
            setPreviewToggle(true)
        }
    }
    return (
        <>
        <Toaster position='top-right'></Toaster>
            {!previewToggle ?(

                <div className='mt-4 p-5 grid grid-cols-4 gap-4 items-start'>
                    <div className='col-span-3 grid grid-cols-2 bg-white p-5 rounded-xl'>
                        {logo ? (
                            <div className='flex items-start'>
                                <img src={logo.previewLink} alt="" className='h-28'/>
                                <i className="bi bi-x text-xl bg-gray-300 rounded-full px-1 cursor-pointer ml-3" onClick={()=>setLogo(false)}></i>
                            </div>
                        ):(
                            <label className="w-32 h-32 flex flex-col items-center px-4 py-2 bg-sky-600 text-white rounded-lg shadow-lg tracking-wide border border-white hover:border-kalloba cursor-pointer hover:bg-sky hover:text-kalloba duration-300">
                                <i className="bi bi-cloud-arrow-up-fill text-3xl"></i>
                                <span className=" text-md">Logo</span>
                                <input type='file' className="hidden" onChange={(e)=>handleImage(e)}/>
                            </label>
                        )}
                        <div className='text-right'>
                            <div className='text-2xl font-bold text-sky-600'>
                                Reçu de paiement
                            </div>
                            <div className='mt-10 text-xl'>
                                <div className=''>
                                    Date: <span className='font-bold pl-2'>{moment().format('l')}</span>
                                </div>
                                <div>
                                    Reçu Nº  <span className='font-bold uppercase pl-2'>___ ___ ___ ___</span>
                                </div>
                            </div>
                            <div className='mt-10'>
                                <div className='text-md font-bold'>
                                    Contact Facturation:
                                </div>
                                <div className='border uppercase p-1 text-left mt-2'>
                                    {client ? (
                                        <div>
                                            <div className='flex justify-between'>
                                                <span className='font-bold'>Client : <span className='pl-2'>{client.last_name} {client.first_name}</span></span>
                                                <i className="bi bi-x text-xl bg-gray-300 rounded-full px-1 cursor-pointer ml-3" onClick={()=>setClient('')}></i>
                                            </div>
                                            <div>
                                                <input type="text" value={city} className='border w-1/2 rounded-xl m-2 px-2 py-1 focus:outline-none' placeholder='ville' onInput={(e)=>saveCity(e)}/>
                                            </div>
                                        </div>
                                    ): (
                                        <div className='w-full'>
                                            <div className='w-full py-2 rounded-xl px-3 bg-sky-600 text-white cursor-pointer select-none' onClick={()=>handleClientSelectBoxDisplaying()}>Sélectionner un client</div>
                                            {
                                                clientSelectBox && (
                                                    <div className='w-full'>
                                                        <div className='bg-white rounded-xl flex border mt-2'>
                                                            <input type="text" className='w-full bg-transparent py-1  text-center focus:outline-none focus:text-sky-600' placeholder='rechercher...' onChange={(e)=>searchClient(e)}/>
                                                            <div className='bg-sky-600 px-2 py-0.5 rounded-xl'>
                                                                <i className="bi bi-search text-lg text-white"></i>
                                                            </div>
                                                        </div>
                                                        <div className='px-2 py-1 overflow-auto h-max-sm'>
                                                            <ul className=''>
                                                                {clients.map((client, index)=>{
                                                                    return <li key={index} className='select-none py-2 border-b cursor-pointer hover:bg-sky-600/[.2] hover:text-sky-600 px-3 duration-300' onClick={()=>saveClient(index)}>{client.first_name} {client.last_name}</li>
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='mt-20 col-span-3'>
                            <table className='border w-full'>
                                <thead className='uppercase bg-sky-600 text-white'>
                                    <tr>
                                        <td className='p-2 border w-1/2'>Désignation</td>
                                        <td className='p-2 border text-center'>Quantité</td>
                                        <td className='p-2 border text-center'>Prix Unitaire</td>
                                        <td className='p-2 border text-center w-1/4'>Total</td>
                                        <td className='p-2 border text-center w-1/4'>Remise</td>
                                        <td className='p-2 border text-center w-1/4'></td>
                                    </tr>
                                </thead>
                                <tbody className=''>
                                    <tr>
                                        <td className='py-4 p-2 font-bold text-lg border'>
                                            {project ? (
                                                <div>
                                                    <span>{projects[project].label}</span>
                                                    <i className="bi bi-x text-xl bg-gray-300 rounded-full px-1 cursor-pointer ml-3" onClick={()=>setProject('')}></i>
                                                </div>
                                            ):(
                                                <select value={project} id="" className='w-full py-2 rounded-lg font-thin pl-3' onChange={(e)=>saveProject(e)}>
                                                    <option value="">Sélectionner le projet</option>
                                                    {
                                                        projects.map((project, index)=>{
                                                            return <option key={index} value={index}>{project.label}</option>
                                                        })
                                                    }
                                                </select>
                                            )}
                                        </td>
                                        <td className="border"></td>
                                        <td className="border"></td>
                                        <td className="border"></td>
                                    </tr>
                                    {
                                        project && (
                                            <>
                                                {products.map((product, row)=>{
                                                    return(
                                                        <tr key={row}>
                                                            <td className='py-4 p-2 font-bold text-lg border flex justify-between gap-2 items-center'>
                                                                <select id="" className='w-full py-2 rounded-lg font-thin pl-3' onChange={(e)=>saveProductSubProject(row, e)}>
                                                                    <option value="">Sélectionner un sous projet</option>
                                                                {
                                                                    subProjects && subProjects.map((project, index)=>{
                                                                        return <option key={index} value={index}>{project.label}</option>
                                                                    })
                                                                }
                                                                </select>
                                                            </td>
                                                            <td className='text-center border'>
                                                                <input type="number" min={1} value={product.quantity} className='w-16 border rounded-xl py-2 px-1 focus:border-sky-500 focus:outline-none' onInput={(e)=>saveProductQuantity(row, e)}/>
                                                            </td>
                                                            <td className='text-center border'>
                                                                {product.budget.toLocaleString('en-US')}
                                                            </td>
                                                            <td className='text-center border'>{(product.quantity * product.budget).toLocaleString('en-US')} F</td>
                                                            <td className='text-center border'>
                                                                <input type="number" min={0} value={product.discount} className='w-28 border rounded-xl py-1 px-2 text-center focus:border-sky-500 focus:outline-none' onInput={(e)=>saveProductDiscount(row, e)}/>
                                                            </td>
                                                            <td className='text-center border px-8'>
                                                                <i className="bi bi-x-circle-fill text-red-500 cursor-pointer text-lg" onClick={()=>{delRow(row)}}></i>
                                                            </td>
                                                        </tr> 
                                                    )
                                                })}
                                                <tr>
                                                    <td colSpan="4" className='border py-2 p-2 '>Total</td>
                                                    <td className='border text-center '>{total.toLocaleString('en-US')} F</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4" className='border py-2 p-2 '>Remise</td>
                                                    <td className='border text-center py-2'>
                                                        {discount && discount.toLocaleString('en-US')} f
                                                        {/* <input type="number" disabled min={0} value={} className='w-28 border rounded-xl py-1 px-2 text-center focus:border-sky-500 focus:outline-none'/> */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4" className='border py-2 p-2 '>Total TTC</td>
                                                    <td className='border text-center '>{(products.reduce((a,v)=> a = a + v.budget * v.quantity, 0) - discount).toLocaleString('en-US')} F</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4" className='border py-2 p-2 '>Montant versé</td>
                                                    <td className='border text-center py-2'>
                                                        <input type="number" min={0} value={amountPaid} className='w-28 border rounded-xl py-1 px-2 text-center focus:border-sky-500 focus:outline-none' onInput={(e)=>saveAmountPaid(e)}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4" className='border py-2 p-2 '>Reste à payer</td>
                                                    <td className='border text-center '>
                                                        {(products.reduce((a,v)=> a = a + v.budget * v.quantity, 0) - discount - amountPaid).toLocaleString('en-US')} F
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }

                                </tbody>
                            </table>
                            {
                                project && (
                                    <>
                                        <div className='text-right my-4'>
                                            <button className='font-thin text-lg px-5 py-2 bg-sky-500/[.1] text-sky-500 rounded-lg hover:bg-sky-500 hover:text-white duration-300' onClick={()=>newRow()}>Ajouter un sous projet</button>
                                        </div>
                                        <div className='mt-2'>
                                            <select value={account} className='py-3 rounded-lg px-3' id="" onChange={(e)=>saveAccount(e)}>
                                                <option value="">Sélectionner le compte receveur</option>
                                                {
                                                    accounts.map((account, index)=>{
                                                        return <option value={index}>{account.label} ({account.accounttype.label})</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </>
                                )
                            }

                            
                            <div className='mt-5'>
                                NB : Le montant versé n’est pas remboursable
                            </div>
                            <hr  className='mt-20 mb-5'/>
                            <div className='text-xs text-center font-bold'>
                            Cocody, Angré CNPS, Cité Star 12 - 19 BP 1100 ABIDJAN 19 * Contact : +225 05 96 67 49 67 / 07 00 77 33 04 / 22 24 30 97 80 
                                *RCCM : CI-ABJ-03-2022-B12-02297 – etudes@etudes.ci – www.etudes.ci
                            </div>
                        </div>
                    </div>
                    <div className='p-5 bg-white rounded-xl text-center space-y-5 top-5 sticky'>
                        <div>
                            <button className='w-full py-2 rounded-xl text-orange-600 bg-orange-600/[.1] hover:bg-orange-600 hover:text-white text-lg font-bold duration-300' onClick={()=>{preview()}}>
                                Prévisualiser
                            </button>
                        </div>
                    </div>
                </div>
            ):(

                <InvoicePDF logo={logo} date={moment().format('l')} client={client} city={city} project={project && projects[project].label} products={products && products} projectId={projects.filter((value)=>value.label===projects[project].label)} subProjects={subProjects && subProjects} discount={discount} ttc={(products.reduce((a,v)=> a = a + v.budget * v.quantity, 0) - discount)} amountPaid={amountPaid} rest={(products.reduce((a,v)=> a = a + v.budget * v.quantity, 0) - discount - amountPaid)} references={references} setPreviewToggle={setPreviewToggle} saved={saved} setSaved={setSaved} account={accounts[account]}/>
            )}
        </>
    )
}
