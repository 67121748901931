import React from 'react'
import 'animate.css';

export default function PopUpOne(props) {
  return (
    <div className={`inset-0 w-full fixed ${props.open ? 'bg-black/[.1]' : 'pointer-events-none'}`}>
        <div className={`fixed inset-0 grid place-items-center w-full`} onClick={()=>{props.onClose()}}>
            <div className={`bg-white w-1/3 p-3 rounded-xl ${props.open ? 'animate__animated animate__flipInX' : props.closeClass+' pointer-events-none'}`}>
                <div className='text-right relative'>
                    <button className='absolute' onClick={()=>{props.onClose()}}><i className="bi bi-x-square-fill text-red-600 text-2xl hover:text-4xl duration-300" onClick={()=>{props.onClose()}}></i></button>
                </div>
                <div className={`mx-4 border rounded-2xl text-xl py-4 text-center h-8 flex justify-center items-center`}>
                    {props.title}
                </div>
                <div className={`mt-3 border  rounded-lg mx-4 p-2 text-center`}>
                    {props.children}
                </div>
            </div>
        </div>
    </div>
  )
}
