import React, {useState, useEffect} from 'react'
import BreadCrumb from '../components/breadcrumb/BreadCrumb'
import axios from 'axios';
import URLS from '../components/API';
import { Toaster } from 'react-hot-toast';
import Notify from '../components/toast/ToastMessage';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
export default function Home() {
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState([]);
    const [disponibility, setDisponibility] = useState('');
    const [projects, setProjects] = useState([]);
    const [currentMonth, setCurrentMonth] = useState({});
    const [lastMonth, setLastMonth] = useState({});

    useEffect(() => {
        axios.get(URLS.dashboard, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            },
        })
        .then((response)=>{
            if(response.data){
                setAccounts(response.data.accounts);
                setDisponibility(response.data.disponibility);
                setProjects(response.data.projects);
                setCurrentMonth(response.data.currentMonth);
                setLastMonth(response.data.lastMonth);
            }else{
                Notify('error', 'Une erreur s\'est produite', 'Veuillez contacter le support développeur');
            }
        })
        .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', 'Une erreur s\'est produite', 'Veuillez contacter le support développeur');
        })
    }, [])
    
    return (
        <>
            <Toaster position='top-right'/>
            <BreadCrumb>
            </BreadCrumb>
            <div className='my-3 text-xl font-bold text-gray-700'>
                Les comptes
            </div>
            <div className=' grid grid-cols-4 gap-4'>
                {accounts && accounts.map((account, index)=>{
                    return (
                        <div key={index} className='p-3 bg-white rounded-2xl shadow-lg shadow-orange-500/50'>
                            <div className="flex justify-between items-center">
                                <div className='text-lg font-bold'>{account.label}</div>
                                <div>
                                    {account.accounttype.label}
                                    <img src={account.accounttype.media[0].original_url} className="h-5 ml-auto" alt="" />
                                </div>
                            </div>
                            <div>
                                <span>Solde: <span className='font-bold text-orange-500 pl-2'>{account.balance.toLocaleString('en-US')} Fcfa</span></span>
                            </div>
                        </div>
                    )
                })}
            </div>
            <hr className='my-5 border-orange-500/[.8] border-dashed border-2'/>
            <div className=''>
                <div className='text-center font-bold text-xl mb-2 text-gray-700'>Disponibilité</div>
                <div className='bg-white mx-auto w-48 text-center p-4 text-xl font-bold rounded-2xl text-green-600 shadow-lg shadow-green-500/50'>
                    {disponibility.toLocaleString('en-US')} Fcfa
                </div>
            </div>
            
            <hr className='my-5 mt-6 border-orange-500/[.8] border-dashed border-2'/>

            <div className='my-3 text-xl font-bold text-gray-700'>
                Les projets
            </div>
            <div className=' grid grid-cols-3 gap-4'>
                {projects && projects.map((project, index)=>{
                    return (
                        <div key={index} className='p-3 bg-white rounded-2xl shadow-lg shadow-cyan-500/50'>
                            <div className="flex justify-between items-center">
                                <div className='text-xl font-bold'>{project.label}</div>
                                <div>
                                    Solde: <span className='font-bold text-orange-500'>{(project.goalState - project.expenses).toLocaleString('en-US')} Fcfa</span>
                                </div>
                            </div>
                            <div className='mt-2'>
                                <span>Date effective: <span className='font-bold text-cyan-500 pl-2'>{moment(project.deadline).format('l')}</span></span>
                            </div>
                            <div className='flex justify-between items-center my-2'>
                                <span>Sorties:<span className='font-bold text-red-500 pl-2'>{project.expenses.toLocaleString('en-US')} Fcfa</span></span>
                                <span>Entrées:<span className='font-bold text-green-500 pl-2'>{project.goalState.toLocaleString('en-US')} Fcfa</span></span>
                            </div>
                        </div>
                    )
                })}
            </div>
            <hr className='my-5 mt-6 border-orange-500/[.8] border-dashed border-2'/>
            {lastMonth.label && currentMonth.label &&
                <div className='grid grid-cols-2 mb-4' >
                    <div className='w-3/4 mx-auto text-center'>
                        <div className='text-xl text-gray-700 font-bold'>Dépenses</div>
                        <div className='bg-white mt-2 flex justify-between items-center p-3 rounded-2xl shadow-lg shadow-red-500/50'>
                            <span className='text-lg font-bold'>{currentMonth.label}</span>
                            <span>{currentMonth.withdrawal.toLocaleString('en-US')} Fcfa</span>
                        </div>
                        <div className=' mt-4 bg-white flex justify-between items-center p-3 rounded-2xl shadow-lg shadow-red-500/50'>
                            <span className='text-lg font-bold'>{lastMonth.label}</span>
                            <span className='font-bold text-gray-700'>{lastMonth.withdrawal.toLocaleString('en-US')} Fcfa</span>
                        </div>
                    </div>
                    <div className='w-3/4 mx-auto text-center'>
                        <div className='text-xl text-gray-700 font-bold'>Entrées</div>
                        <div className='bg-white mt-2 flex justify-between items-center p-3 rounded-2xl shadow-lg shadow-green-500/50'>
                            <span className='text-lg font-bold'>{currentMonth.label}</span>
                            <span>{currentMonth.deposit.toLocaleString('en-US')} Fcfa</span>
                        </div>
                        <div className=' mt-4 bg-white flex justify-between items-center p-3 rounded-2xl shadow-lg shadow-green-500/50'>
                            <span className='text-lg font-bold'>{lastMonth.label}</span>
                            <span className='font-bold text-gray-700'>{lastMonth.deposit.toLocaleString('en-US')} Fcfa</span>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
