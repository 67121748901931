import React, {useState, useEffect} from 'react'
import logo from '../../logo.png'
import 'bootstrap-icons/font/bootstrap-icons.css';
import validator from 'validator';
import URLS from '../../components/API';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ErrorBadge from '../../components/badges/ErrorBadge';

export default function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (localStorage.getItem('token')){
            navigate('/dashboard');
        }
    }, [navigate])


    const [displayPassword, setDisplayPassword] = useState(false);

    //email
    const [emailValue, setEmailValue] =  useState('');
    const [emailError, setEmailError] = useState(false);
    const saveEmailValue = (e)=>{
        setEmailValue(e.target.value);
        validateEmail(e.target.value);
        setLoginError(false);
    }
    const validateEmail = (email)=>{
        !validator.isEmail(email) ? setEmailError(true) : setEmailError(false);
    }
    //End of email

    
    //password
    const [passwordValue, setPasswordValue] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const savePasswordValue = (e)=>{
        setPasswordValue(e.target.value);
        validatePassword(e.target.value);
        setLoginError(false);
    }
    const validatePassword = (password)=>{
        !password ? setPasswordError(true) : setPasswordError(false);
    }
    //end of password

    // submitted
    // const doi = ()=>{
    //     dispatch({
    //         type: 'développeur',
    //     })
    // }
    const [loginError, setLoginError] = useState(false);
    const [submittedLoader, setSubmittedLoader] = useState(false);
    const formSubmitted = (e)=>{
        if(!passwordError && ! emailError){
            setSubmittedLoader(true)
            const data = {
                'email' :  emailValue,
                'password' :  passwordValue,
            }

            axios.post(URLS.login, data)
            .then((response)=>{
                setLoginError(false);
                localStorage.setItem('token', response.data.authorisation.token);
                localStorage.setItem('role', response.data.user.role.label);
                dispatch({
                    type: response.data.user.role.label,
                })
                if(localStorage.getItem('role') === 'commercial'){
                        navigate('/transactions');
                }else{
                    navigate('/dashboard');
                }
            })
            .catch((error)=>{
                setLoginError(true)
            })
            setSubmittedLoader(false)
        }
    }

    return (
        <div className='grid h-screen w-screen place-items-center bg-white bg-maya-orange'>
            <div className='md:p-3 md:w-1/4 h-4/5 rounded-2xl shadow-xl border border-maya bg-white'>
                <div className='mt-2'>
                    <img src={logo} className='h-14 text-center mx-auto' alt="" />
                </div>
                <div className='mt-4 text-center'>
                    <div className='bg-gray-300/[.4] mx-5 p-2 rounded-xl text-2xl uppercase'>
                        Connexion
                        {/* <button onClick={()=>doi()}>ddd</button> */}
                    </div>
                    <div className='mt-2'>
                        <span className='text-2xl md:text-xl font-black'>Bienvenu,</span> <br />
                        <span className='text-gray-500'>
                            Connectez-vous pour continuer !
                        </span>
                    </div>
                   
                    {loginError &&
                        <ErrorBadge message="Email ou mot de passe incorrecte"/>
                    }
                    <div className={`${loginError ? 'mt-4' : 'mt-'} space-y-5 p-4`}>
                        <div className='text-left'>
                            <div className='mb-1 text-gray-600'>
                                <label htmlFor="email">Email</label>
                            </div>
                            <div className='flex items-center border border-maya-orange w-full px-2 rounded-lg'>
                                <i className="bi bi-envelope text-2xl text-maya-orange"></i>
                                <input type="email" className='w-full py-2 px-2 bg-transparent focus:outline-none' value={emailValue} onInput={(e)=>{saveEmailValue(e)}} />
                            </div>
                            {emailError &&
                                <div className='text-left'>
                                    <small className='text-red-500'>Email invalide</small>
                                </div>
                            }
                        </div>
                        <div className='text-left'>
                            <div className='mb-1 text-gray-600'>
                                <label htmlFor="email">Mot de passe</label>
                            </div>
                            <div className='flex items-center border border-maya-orange w-full px-2 rounded-lg'>
                                <i className="bi bi-shield-lock text-2xl text-maya-orange"></i>
                                <input type={displayPassword ? 'text' : 'password'} className='w-full py-2 px-2 bg-transparent focus:outline-none' value={passwordValue} onInput={(e)=>{savePasswordValue(e)}}/>
                                <i className={`${!displayPassword ? 'bi bi-eye-slash text-maya-orange' : 'bi bi-eye text-black'} cursor-pointer text-2xl`} onClick={()=>{setDisplayPassword(!displayPassword)}}></i>
                            </div>
                            {passwordError &&
                                <div className='text-left'>
                                    <small className='text-red-500'>Veuillez renseigner votre mot de passe</small>
                                </div>
                            }
                        </div>
                    </div>

                    <div className='mt-10'>
                        { !submittedLoader ?
                            (<button className='btn bg-black px-4 py-2 text-xl rounded-xl text-white font-bold border border-black hover:bg-maya-orange hover:border-white duration-700 hover:px-5 hover:py-3' onClick={()=>{formSubmitted()}}>
                                Se connecter
                            </button>)
                            :(
                            <button className='bg-maya-orange rounded-md text-xl animate-ping'>
                                <span className='invisible'>Se connecter</span>
                            </button>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
