import React, {useEffect, useState} from 'react'
import axios from 'axios'
import URLS from '../API';
import {useNavigate} from 'react-router-dom';

export default function PrivateRouter({component: RouteComponent, role: Role}) {
    const navigate = useNavigate();
    const [res, setRes] = useState(false);
    useEffect(()=>{
        const token = localStorage.getItem('token');
        if(token){
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            };
            axios.get(URLS.userData, config)
            .then((response)=>{
                if(response.data.user){
                    setRes(response.data.user);
                }else{
                    localStorage.clear();
                }
            })
            .catch((error)=>{
                localStorage.clear();
                navigate('/');
            })
        }else{
            localStorage.clear();
            navigate('/');
        }
    }, []);

    if(res !== false){
        if(Role && Role.indexOf(res.role.label)>-1){
            return <RouteComponent/>
        }else{
            navigate('/invoices');
        }
    }
}
