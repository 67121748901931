import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createStore } from 'redux';
import {Provider} from 'react-redux';
import {HashRouter} from "react-router-dom"
import MenuReducer from './reducers/menuReducer/MenuReducer';

const root = ReactDOM.createRoot(document.getElementById('root'));
const Store = createStore(MenuReducer);
root.render(
    <Provider store={Store}>
        
        <React.StrictMode>
                <HashRouter>
                    <App />
                </HashRouter>
        </React.StrictMode>
    </Provider>
);
