import React from 'react'
import SideBar from './SideBar'

export default function WithSideBar(props) {
  return (
    <>
        <div className='h-full w-screen bg-gray-200/[.5] p-3 grid grid-cols-10 gap-2'>
            <div className='col-span-2'>
                <SideBar/>
            </div>
            <div className='col-span-8 '>
                {props.children}
            </div>
        </div>
    </>
  )
}
