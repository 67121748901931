import React from 'react'


export default function SaveButton(props) {
  return (
    <>
        {
            !props.loader ? (
                (
                    <button className={props.className} onClick={()=>{props.save()}}>
                        {props.text}
                    </button>
                )
            ) : (
                <button className={`animate-ping ${props.className}`}>
                    <span className='invisible'>{props.text}</span>
                </button>
            )
        }
        
    </>
  )
}
