// const base = 'http://127.0.0.1:8000/api/';
const base = 'https://backcount.mayacom.agency/api/';


const URLS = {
    'fileConfig': {
        headers: {
            "Content-type": "text/html; charset=UTF-8",
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
        },
    },
    'login' : `${base}login`,
    'register' : `${base}register`,
    'users' : `${base}users`,
    'usersTrashed' : `${base}users/trashed`,
    'updateUser' : `${base}users/update`,
    'deleteUser' : `${base}users/delete`,
    'restoreUser' : `${base}users/restore`,
    'forceDeleteUser' : `${base}users/force-delete`,
    'updateUserState' : `${base}users/update-state`,
    'userData' : `${base}get-user-data`,
    'searchUser': `${base}users/search`,

    'permissions': `${base}permissions`,
    'createPermission': `${base}permissions/create`,
    'updatePermission': `${base}permissions/update`,
    'deletePermission': `${base}permissions/delete`,

    'roles': `${base}roles`,
    'createRole': `${base}roles/create`,
    'updateRole': `${base}roles/update`,
    'deleteRole': `${base}roles/delete`,

    'accountTypes': `${base}account-type`,
    'createAccountType': `${base}account-type/create`,
    'updateAccountType': `${base}account-type/update`,
    'deleteAccountType': `${base}account-type/delete`,

    'accounts': `${base}accounts`,
    'deleteAccount': `${base}accounts/delete`,
    'createAccount': `${base}accounts/create`,
    'freezeAccount': `${base}accounts/freeze`,

    'projects': `${base}projects`,
    'projectSubProjects': `${base}projects/sub-projects`,
    'allProjects': `${base}projects/all`,
    'subProjects': `${base}sub-projects`,
    'createProject': `${base}projects/create`,
    'createSubProject': `${base}sub-projects/create`,
    'projectState': `${base}projects/state`,
    'deleteProject': `${base}projects/delete`,
    'getProject': `${base}projects/get`,
    
    'transactions': `${base}transactions`,
    'deposit': `${base}transactions/deposit`,
    'withdrawal': `${base}transactions/withdrawal`,
    'searchTransactions': `${base}transactions/search`,
    'cancelTransactions': `${base}transactions/cancel`,
    'filteringByDate': `${base}transactions/filtering-by-date`,

    'clients': `${base}clients`,
    'allClients': `${base}clients/all`,
    'createClient': `${base}clients/create`,
    'updateClient': `${base}clients/update`,
    'deleteClient': `${base}clients/delete`,
    'searchClient': `${base}clients/search`,
    'globalSearchClient': `${base}clients/global-search`,

    'invoices': `${base}invoices`,
    'lastInvoiceId': `${base}invoices/last-id`,
    'createInvoices': `${base}invoices/create`,
    'searchInvoices': `${base}invoices/search`,

    'dashboard': `${base}dashboard`,

}
export default URLS;