import './App.css';
import Base from './routes/Router';
function App() {
  return (
    <div className="font-ubuntu">
        <Base/>
    </div>
  );
}

export default App;
