import React, {useState} from 'react'
import logo from '../../logo.png'
// import menu from '../menu/Menu'
import DropDown from '../dropdown/DropDown'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
export default function SideBar() {

    const navigate = useNavigate();
    const menu = useSelector(state => state)
    const setDropDownActive = (index, path)=>{
        // for (const iterator of menu) {
        //     iterator.active = false;
        // }
        // menu[index].active = true;
        navigate(path);
    }
    return (
        <div className='sticky top-2 h-screen'>
            <div className='h-full abolute bg-maya-orange/[.3] rounded-xl p-4 relative'>
                <div>
                    
                    <div className='relative'>
                        <img src={logo} className='h-11 mt-2' alt="" />
                    </div>
                </div>
                <div className='mt-10'>
                    {
                        menu.map((value, index)=>{
                        return <DropDown key={index} element={value} setDropDownActive={setDropDownActive} index={index}/>
                        })
                    }
                </div>
            </div>
        </div>
    )
}
