import React, {useRef} from 'react'
import 'animate.css'
import moment from 'moment/moment'
import etudesci from '../../etudesci.png'
import jsPDF from 'jspdf'
export default function InvoiceModal(props) {
    const pdfRef = useRef(null);
    const handleDownload = () => {
        const content = pdfRef.current;

        const doc = new jsPDF("portrait", 'mm', 'a0');
        doc.html(content, {
            callback: function (doc) {
                doc.save(props.invoice.references+'_'+props.invoice.client.last_name+'_'+props.invoice.client.first_name+'.pdf');
            }
        });
    };
  return (
    <div className={`z-10 fixed inset-0 ${props.open ? '' : 'pointer-events-none'}`}>
        <div className={`fixed bg-black/[.3] backdrop-blur-sm inset-0  grid place-items-center ${props.open ? 'opacity-100' : 'pointer-events-none animate__animated animate__fadeOut opacity-0'}`}>
            <div className={`fixed overflow-auto bg-white h-full w-4/5 shadow-lg rounded-xl p-3  border border-2 ${props.open ? 'animate__animated animate__bounceInDown' : props.closeClass}`}>
                <div className='mb-3 '>
                    <div className='text-right text-red-500'>
                        <i className="bi bi-x-circle text-xl hover:text-2xl duration-300 cursor-pointer" onClick={()=>{props.onClose()}}></i>
                    </div>
                </div>
                <div className='overflow-auto'>
                    {props.invoice &&
                        <div className=' p-5 grid grid-cols-4 gap-4 items-start'>
                            <div className='col-span-3 grid grid-cols-2 bg-white p-5 rounded-x align-items-center border border-sky-700' ref={pdfRef}>
                                <div className='flex items-start'>
                                    <img src={props.logo ? props.logo.previewLink : etudesci} alt="" className='h-28'/>
                                </div>
                                <div className='text-right'>
            
                                    <div className='text-2xl font-bold text-sky-700'>
            
                                        Reçu de paiement
            
                                    </div>
            
                                    <div className='mt-10 text-xl'>
                                        <div className=''>
            
                                            Date: <span className='font-bold pl-2'>{moment(props.invoice.created_at).format('l')}</span>
                                        </div>
                                        <div>
                                            Reçu Nº  <span className='font-bold uppercase pl-2'>{props.invoice.references}</span>
                                        </div>
            
                                    </div>
            
                                    <div className='mt-10'>
            
                                        <div className='text-md font-bold'>
            
                                            Contact Facturation:
            
                                        </div>
            
                                        <div className='border uppercase p-1 text-left mt-2'>
            
                                            <span className='font-bold'>Client: </span><span className='pl-2'>{props.invoice.client.last_name} {props.invoice.client.first_name}<br />{props.invoice.city}</span>
            
                                        </div>
            
                                    </div>
            
                                </div>
            
                                <div className='mt-20 col-span-3'>
            
                                    <table className='border w-full'>
            
                                        <thead className='uppercase bg-sky-700 text-white'>
            
                                            <tr>
            
                                                <td className='p-2 border w-1/2'>Désignation</td>
            
                                                <td className='p-2 border text-center'>Quantité</td>
            
                                                <td className='p-2 border text-center'>Prix Unitaire</td>
            
                                                <td className='p-2 border text-center w-1/4'>Total</td>
            
                                            </tr>
            
                                        </thead>
            
                                        <tbody className=''>
            
                                            <tr>
            
                                                <td className='py-4 p-2 font-bold text-lg border'>{props.invoice.mainProject.label}</td>
            
                                                <td className="border"></td>
            
                                                <td className="border"></td>
            
                                                <td className="border"></td>
            
                                            </tr>
            
                                            {props.invoice.subProjects&& props.invoice.subProjects.map((product, index)=>{
                                                return (
                                                    <tr>
                                                        <td className='py-4 p-2 font-bold text-lg border'>{product.label}</td>
            
                                                        <td className='text-center border'>{product.pivot.quantity}</td>
            
                                                        <td className='text-center border'>{product.budget.toLocaleString('en-US')} F</td>
            
                                                        <td className='text-center border'>{(product.pivot.quantity * product.budget).toLocaleString('en-US')} F</td>
            
                                                    </tr>
                                                )
                                            })}
            
                                            
            
                                            <tr>
            
                                                <td colspan="3" className='border py-2 p-2 '>Remise</td>
            
                                                <td className='border text-center '>{props.invoice.discount.toLocaleString('en-US')} F</td>
            
                                            </tr>
            
                                            <tr>
            
                                                <td colspan="3" className='border py-2 p-2 '>Total TTC</td>
            
                                                <td className='border text-center '>{props.invoice.total.toLocaleString('en-US')} F</td>
            
                                            </tr>
            
                                            <tr>
            
                                                <td colspan="3" className='border py-2 p-2 '>Montant versé</td>
            
                                                <td className='border text-center '>{(props.invoice.total - props.invoice.remaining).toLocaleString('en-US')} F</td>
            
                                            </tr>
            
                                            <tr>
            
                                                <td colspan="3" className='border py-2 p-2 '>Reste à payer</td>
            
                                                <td className='border text-center '>{props.invoice.remaining.toLocaleString('en-US')} F</td>
            
                                            </tr>
            
            
            
                                        </tbody>
            
                                    </table>
            
                                    <div className='mt-5'>
            
                                        Mode de paiement : <span className='font-bold'>{props.invoice.account && props.invoice.account.accounttype.label} -  {props.invoice.account && props.invoice.account.label}</span>
            
                                    </div>
            
                                    <div className='mt-5'>
            
                                        NB : Le montant versé n’est pas remboursable
            
                                    </div>
            
                                    <hr  className='mt-20 mb-5 mt-64'/>
            
                                    <div className='text-xs text-center font-bold bottom-0'>
            
                                    Cocody, Angré CNPS, Cité Star 12 - 19 BP 1100 ABIDJAN 19 * Contact : 225 05 96 67 49 67 / 07 00 77 33 04 / 22 24 30 97 80 
            
                                        *RCCM : CI-ABJ-03-2022-B12-02297 – etudes@etudes.ci – www.etudes.ci
            
                                    </div>
            
                                </div>
            
                            </div>
                            <div className='p-5 bg-white rounded-xl text-center space-y-5 top-0 sticky'>
                                <div>
                                    <button className='w-full py-2 rounded-xl text-green-600 bg-green-600/[.1] hover:bg-green-600 hover:text-white text-lg font-bold duration-300' onClick={()=>handleDownload()}>
                                        Télécharger
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}
