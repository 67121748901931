import React from 'react'
import 'animate.css'
export default function LargeModal(props) {
  return (
    <div className={`z-10 fixed inset-0 ${props.open ? '' : 'pointer-events-none'}`}>
        <div className={`fixed bg-black/[.3] backdrop-blur-sm inset-0  grid place-items-center ${props.open ? 'opacity-100' : 'pointer-events-none animate__animated animate__fadeOut opacity-0'}`}>
            <div className={`fixed bg-white h-2/3 w-4/5 shadow-lg rounded-xl p-3  border border-2 ${props.open ? 'animate__animated animate__bounceInDown' : props.closeClass}`}>
                <div className='flex justify-between mb-3 '>
                    <div className={`text-lg font-bold  text-center w-full text-${props.color}-500`}>
                        {props.title}
                    </div>
                    <div className='text-right text-red-500'>
                        <i className="bi bi-x-circle text-xl hover:text-2xl duration-300 cursor-pointer" onClick={()=>{props.onClose()}}></i>
                    </div>
                </div>
                <div>
                    {props.children}
                </div>
            </div>
        </div>
    </div>
  )
}
