import React, {useEffect, useState} from 'react';
import BreadCrumb from '../components/breadcrumb/BreadCrumb';
import axios from 'axios';
import URLS from '../components/API';
import Notify from '../components/toast/ToastMessage';
import { Toaster } from 'react-hot-toast';
import moment from 'moment/moment';
import { CSVLink } from "react-csv";
import ConfirmModal from '../components/modals/ConfirmModal';
import Withdrawal from '../components/transactions/Withdrawal';
import Deposit from '../components/transactions/Deposit';
import {useNavigate} from 'react-router-dom';

export default function Transactions() {
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [saveTransactions, setSaveTransactions] = useState([]);
    const [csvTransactions, setCsvTransactions] = useState([]);
    const userRoleLabel = localStorage.getItem('role');


    const [paginationData, setPaginationData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);


    const   csvHeaders = [
        {
            "label": 'ID',
            "key": "id"
        },
        {
            "label": 'Références',
            "key": "references"
        },
        {
            "label": 'Motif',
            "key": "reason"
        },
        {
            "label": 'Montant',
            "key": "amount"
        },
        {
            "label": 'Type',
            "key": "type.label"
        },
        {
            "label": 'Projet',
            "key": "project.label"
        },
        {
            "label": 'Date',
            "key": "created_at"
        },
        {
            "label": 'État',
            "key": "state"
        },
    ]
    const csvLink = {
        filename: 'Transactions.csv',
        headers: csvHeaders,
        data: csvTransactions
    }
    useEffect(() => {
        axios.get(URLS.transactions, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
        .then((response)=>{
            if(response.data.transactions){
                setTransactions(response.data.transactions);
                setSaveTransactions(response.data.transactions);
                let middle = [];
                response.data.transactions.forEach(transaction => {
                    if(transaction.type.label === 'deposit'){
                        transaction.type.label = 'Entrée';
                    }else{
                        transaction.type.label = 'Dépense';
                    }
                    middle.push({
                        'id': transaction.id,
                        'references': transaction.references,
                        'reason': transaction.reason,
                        'amount': transaction.amount,
                        'type': transaction.type,
                        'project': transaction.project,
                        'state': transaction.state === 1 ? 'Effectué' : 'Annulé',
                        'created_at': moment(transaction.type).format('DD MMM YYYY à hh:mm'),
                    })
                })
                setCsvTransactions(middle);
                setPaginationData(new Array(Math.ceil(response.data.transactions.length / 6)).fill().map(_ => response.data.transactions.splice(0, 6)))

            }else{
                Notify('error', 'Une erreur s\'est produite', 'Veuillez contacter le support développeur');
            }
        })
        .catch((error)=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate('/');
            }
            Notify('error', 'Une erreur s\'est produite', 'Veuillez contacter le support développeur');
        })
    }, [refresh])

    // search
    const search = (e)=>{
        if(e.target.value){
            axios.post(URLS.searchTransactions, {'subject': e.target.value}, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            .then((response)=>{
                if(response.data.results){
                    setTransactions(response.data.results);
                    setPaginationData(new Array(Math.ceil(response.data.results.length / 6)).fill().map(_ => response.data.results.splice(0, 6)))
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', 'Une erreur s\'est produite', 'Veuillez contacter le support développeur');
            })
        }else{
            setTransactions(saveTransactions);
            axios.get(URLS.transactions, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }, 
            })
            .then((response)=>{
                if(response.data.transactions){
                    let middle = [];
                    response.data.transactions.forEach(transaction => {
                        if(transaction.type.label === 'deposit'){
                            transaction.type.label = 'Entrée';
                        }else{
                            transaction.type.label = 'Dépense';
                        }
                        middle.push({
                            'id': transaction.id,
                            'references': transaction.references,
                            'reason': transaction.reason,
                            'amount': transaction.amount,
                            'type': transaction.type,
                            'project': transaction.project,
                            'state': transaction.state === 1 ? 'Effectué' : 'Annulé',
                            'created_at': moment(transaction.type).format('DD MMM YYYY à hh:mm'),
                        })
                    })
                    setCsvTransactions(middle);
                    setPaginationData(new Array(Math.ceil(response.data.transactions.length / 6)).fill().map(_ => response.data.transactions.splice(0, 6)))
    
                }else{
                    Notify('error', 'Une erreur s\'est produite', 'Veuillez contacter le support développeur');
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', 'Une erreur s\'est produite', 'Veuillez contacter le support développeur');
            })
        }
    }
    
    // filtering by date
    const filteringByDate = (begin, end)=>{
        if(begin || end){
            const data = {
                'begin': begin,
                'end': end
            }
            axios.post(URLS.filteringByDate, data, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((response)=>{
                if(response.data.results){
                    setTransactions(response.data.results);
                }
            })
            .catch((error)=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
                Notify('error', 'Une erreur s\'est produite', 'Veuillez contacter le support développeur');
            })
        }else{
            setTransactions(saveTransactions);
        }
    }

    //delete
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [actionId, setActionId] = useState(false);
    const [confirmCloseClass, setConfirmCloseClass] = useState('opacity-0');
    const openConfirmModal = (id)=>{
        setDeleteModalOpen(true);
        setActionId(id);
    }
    const closeConfirmModal = ()=>{
        setDeleteModalOpen(false);
        setConfirmCloseClass('animate__animated animate__backOutDown');
    }
    const cancelTransaction = (id)=>{
        axios.post(URLS.cancelTransactions, {'id': id}, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }, 
        })
        .then((response)=>{
            if(response.data.success){
                Notify('success', 'Annulation de transaction', response.data.success);
                setRefresh(refresh+1);
            }else if(response.data.balanceOut){
                Notify('error', 'Annulation de transaction', response.data.balanceOut);
            }else if(response.data.delay){
                Notify('error', 'Annulation de transaction', response.data.delay);
            }else{
                Notify('error', 'Erreur d\'annulation', 'Veuillez contacter le support développeur');
                console.log(response);
            }
            setRefresh(refresh+1);
        })
        .catch((error)=>{
            if (error.response.status===401) {
                    localStorage.clear();
                    navigate('/');
                }
            Notify('error', 'Erreur d\'annulation', 'Veuillez contacter le support développeur');
        })
    }

    //withdrawal
    const [openWithdrawalModal, setOpenWithdrawalModal] = useState(false);
    const [withdrawalModalCloseClass, setWithdrawalModalCloseClass] = useState('opacity-0');
    const closeWithdrawalModalFunction = ()=>{
        setOpenWithdrawalModal(false);
        setWithdrawalModalCloseClass('animate__animated animate__fadeOutRight');
    }
    const openWithdrawalModalFunction = ()=>{
        setOpenWithdrawalModal(true);
    }

    //Deposit
    const [openDepositModal, setOpenDepositModal] = useState(false);
    const [depositModalCloseClass, setDepositModalCloseClass] = useState('opacity-0');
    const closeDepositModalFunction = ()=>{
        setOpenDepositModal(false);
        setDepositModalCloseClass('animate__animated animate__fadeOutRight');
    }
    const openDepositModalFunction = ()=>{
         setOpenDepositModal(true);
    }
    return (
        <>
            <BreadCrumb>
                <div className='flex items-center h-full'>
                    <div className='text-md font-bold bg-violet-500/[.7] text-white p-1 rounded-md px-3'>
                        Gestion de Projet
                    </div>
                </div>
            </BreadCrumb>
            <Toaster position='top-right'/>
            <div className="sticky top-10">
                <div className='h-14 bg-white my-4 ml-auto w-1/2 rounded-xl shadow-lg shadow-violet-500/50 flex items-center justify-between p-4 gap-4'>
                    <div className='flex w-full items-center gap-2'>
                        <span className='text-violet-500'>Début</span>
                        <input type="date" className="w-full border rounded-xl py-1 px-2 focus:outline-none focus:border-indigo-500 cursor-pointer" onChange={(e)=>{filteringByDate(e.target.value, '')}}/>
                    </div>
                    <div className='flex w-full items-center gap-2'>
                        <span className='text-violet-500'>Fin</span>
                        <input type="date" className=" w-full border rounded-xl py-1 px-2 focus:outline-none focus:border-indigo-500 cursor-pointer" onChange={(e)=>filteringByDate('', e.target.value)} />
                    </div>
                </div>
                <div className='my-4 h-14 bg-white shadow-lg shadow-indigo-500/50 rounded-xl p-2'>
                    <div className='mx-3 h-full flex items-center justify-between'>
                        <div>
                            <span>Filtrer</span>
                            <select name="" id="" className='px-20 ml-3 py-2 rounded-xl' onChange={(e)=>search(e)}>
                                <option value="" selected>Option de tri</option>
                                <option value="deposit">Entrées</option>
                                <option value="withdrawal">Dépenses</option>
                                <option value="canceled">Annulées</option>
                            </select>
                        </div>
                        <div className='border border-2 rounded-xl border-violet-200 flex items-center justify-between'>
                            <input type="text" className='bg-transparent h-full w-full focus:outline-none px-2 py-1' placeholder='rechercher ...' onInput={(e)=>search(e)}/>
                            <button className='px-2 bg-violet-500 py-1 rounded-xl pointer-events-none text-white'>
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                        <div className='space-x-10'>
                            <button className='px-2 py-1 bg-green-500/[.1] text-green-500 hover:text-white hover:bg-green-500 duration-300 rounded-xl' onClick={()=>openDepositModalFunction()}>
                                Dépôt
                                <i className="bi bi-piggy-bank pl-2"></i>
                            </button>
                            <button className='px-2 py-1 bg-red-500/[.1] text-red-500 hover:text-white hover:bg-red-500 duration-300 rounded-xl' onClick={()=>openWithdrawalModalFunction()}>
                                Retrait
                                <i className="bi bi-graph-down-arrow pl-2"></i>
                            </button>
                        </div>
                        <div className=' text-white'>
                            <CSVLink {...csvLink} separator={";"}>
                                <button className='px-2 py-2 bg-cyan-500/[.2] text-cyan-500 hover:bg-cyan-500 hover:text-white duration-300 rounded-xl text-sm mr-2'>
                                    <i className="bi bi-cloud-download-fill pr-2"></i>Exporter
                                </button>
                            </CSVLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-white rounded-xl mt-4 p-5 py-7'>
                <div className='table w-full overflow-auto'>
                    <div className='table-header-group   rounded'>
                        <div className="table-row ">
                            <div className="table-cell"></div>
                            <div className="text-violet-700 table-cell text-center py-4 rounded-l-xl bg-violet-100">Références</div>
                            <div className="text-violet-700 table-cell text-center py-4 bg-violet-100">Type</div>
                            <div className="text-violet-700 table-cell text-center py-4 bg-violet-100">Motif</div>
                            <div className="text-violet-700 table-cell text-center py-4 bg-violet-100">Montant</div>
                            <div className="text-violet-700 table-cell text-center py-4 bg-violet-100">État</div>
                            <div className="text-violet-700 table-cell text-center py-4 bg-violet-100">Compte</div>
                            <div className="text-violet-700 table-cell text-center py-4 bg-violet-100">Projet</div>
                            <div className={`text-violet-700 table-cell text-center py-4 bg-violet-100 ${(userRoleLabel !== 'développeur' && userRoleLabel !== 'administrateur') && 'rounded-r-xl'}`}>Date</div>
                            {(userRoleLabel === 'développeur' || userRoleLabel === 'administrateur') && (
                                <div className="text-violet-700 table-cell text-center py-4 bg-violet-100 rounded-r-xl">Actions</div>
                            )}
                        </div>
                    </div>
                    <div className="table-row-group w-full">
                        {
                            paginationData[currentPage] && paginationData[currentPage].map((transaction, index)=>{
                                return (
                                    <div key={index} className={`table-row`}>
                                        <div className="table-cell">
                                            {transaction.label !== 'deposit' ? <i className="bi bi-arrow-up-circle-fill text-red-600"></i> : <i className="bi bi-arrow-down-circle-fill text-green-500"></i>}
                                        </div>
                                        <div className={`${transaction.state!==1 && 'bg-red-100 rounded-l-2xl'} table-cell text-center my-1 py-6`}>{transaction.references}</div>
                                        <div className={`${transaction.state!==1 && 'bg-red-100'} table-cell text-center my-1 py-6 ${transaction.label=== 'deposit' ? 'text-cyan-600' : 'text-red-500'}`}>
                                            {transaction.label=== 'deposit' ? 'Entrée' : 'Dépense'}
                                        </div>
                                        <div className={`${transaction.state!==1 && 'bg-red-100'} table-cell text-center my-1 py-6 truncate max-w-[7em]`}>{transaction.reason}</div>
                                        <div className={`${transaction.state!==1 && 'bg-red-100'} table-cell text-center my-1 py-6`}>{transaction.amount && transaction.amount.toLocaleString('en-US')} Fcfa</div>
                                        <div className={`${transaction.state!==1 && 'bg-red-100'} table-cell text-center my-1 py-6`}>
                                        {
                                            transaction.state=== 1 ? (
                                                <i className="bi bi-bookmark-check-fill text-green-600"></i>
                                            ):(
                                                <i className="bi bi-x-circle-fill text-red-600"></i>
                                            ) 
                                        }
                                        </div>
                                        <div className={`${transaction.state!==1 && 'bg-red-100'} table-cell text-center my-1 py-6`}>{transaction.account && transaction.account.label}</div>
                                        <div className={`${transaction.state!==1 && 'bg-red-100'} table-cell text-center my-1 py-6 font-bold truncate max-w-[7em]`}>{transaction.project && transaction.project.label}</div>
                                        <div className={`${transaction.state!==1 && 'bg-red-100'} table-cell text-center my-1 py-6`}>{moment(transaction.created_at).format('DD MMM YYYY à hh:mm')}</div>
                                        {(userRoleLabel === 'développeur' || userRoleLabel === 'administrateur') && (

                                            <div className={`${transaction.state!==1 && 'bg-red-100 rounded-r-2xl'} table-cell text-center my-1 py-6`}>
                                                {transaction.state=== 1 && (moment(transaction.created_at).diff(moment(), 'days')>=0 && moment(transaction.created_at).diff(moment(), 'days')<=1) &&
                                                    <button className='px-2 py-1 bg-red-600/[.2] rounded-xl text-red-600 mr-4 hover:bg-red-600 hover:text-white duration-300' onClick={()=>{openConfirmModal(transaction.id)}}>Annuler</button>
                                                }
                                            </div>
                                        )}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            
            {paginationData.length>1 &&
                <div className=''>
                    <div className='h-10 bg-white flex mt-4 float-right items-center px-5 rounded-full gap-5 mb-10 font-bold'>
                        {paginationData.map((value, index)=>{
                            return <div className={`px-3 cursor-pointer rounded-xl ${currentPage===index ? 'bg-violet-500 text-white' : 'hover:bg-violet-200'}`} onClick={()=>{setCurrentPage(index)}}>{index+1}</div>
                        })}
                    </div>
                </div>
            }

            <ConfirmModal open={deleteModalOpen} closeClass={confirmCloseClass} onClose={closeConfirmModal} id={actionId} action={cancelTransaction}>
                <span>Cette action
                    est irréversible! Voulez-vous annuler ?
                </span>
            </ConfirmModal>

            <Withdrawal refresh={refresh} setRefresh={setRefresh} openWithdrawalModal={openWithdrawalModal} closeWithdrawalModalFunction={closeWithdrawalModalFunction} withdrawalModalCloseClass={withdrawalModalCloseClass}/>
            
            <Deposit refresh={refresh} setRefresh={setRefresh} openDepositModal={openDepositModal} closeDepositModalFunction={closeDepositModalFunction} depositModalCloseClass={depositModalCloseClass}/>
        </>
    )
}
